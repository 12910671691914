import React, { Component, Fragment } from "react";
import Sidebar from './Sidebar';
import { withRouter } from 'react-router-dom'
import ProductListing from './ProductListing';
import LatestNews from './LatestNews';
import BannerSlider from './Banner-Slider';
import AvailableNow from './AvailableNow';
import TwitterList from "./TwitterList";
import StaffPicks from './StaffPicks';
import RcentlyAdded from './RecentlyAdded';
import PopularList from './PopularList';
import * as COMMONCONST from '../constants/Common';
import $ from 'jquery'
const queryString = require('query-string');

class MainContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: 'by_date',
            brand: [],
            brand_id: '',
            archive: [],
            release: 'view_all',
            isSearch: localStorage.getItem('search') ? true : false,
            searchMobile: '',
            models: [],
            most_views: '',
            most_likes: '',
            month: '',
            year: '',
            isWatchlistDelete: COMMONCONST.getCookie('watchlist') ? 0 : 1,
            message: COMMONCONST.getCookieMessage(),
            width: window.innerWidth,
            height: window.innerHeight
        };
        this.updateSort = this.updateSort.bind(this);
        this.updateArchive = this.updateArchive.bind(this);
        this.updateBrand = this.updateBrand.bind(this);
        this.updateRelease = this.updateRelease.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.updateApplyFilter = this.updateApplyFilter.bind(this)
        this.clearSearch = this.clearSearch.bind(this);
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const user = queryParams.get('user');
        if(user == 'delete-account') {
            if(this.props.isUserLoggedIn) {
                COMMONCONST.setCookieValue('user-account', '');
                this.props.history.push('/user-settings');
            } else {
                COMMONCONST.setCookieValue('user-account', 'delete-account');
                $('.login-link a').trigger('click');
            }
        }
        COMMONCONST.GAPageView();
        let query = queryString.parse(this.props.location.search);
        if (query.search) {
            if (this.state.width >= 900) {
                this.updateMainSearch(query.search)
            } else {
                this.updateSearch(query.search)
            }
            this.props.history.push("/");
        } else if (query.sort) {
            if (query.sort === 'most_liked') {
                this.setState({ sort: 'most_liked', release: 'not_yes_released' })
                this.props.history.push("/");
            }
        } else if (query.by_release) {
            if (query.by_release === 'not_yes_released') {
                this.updateMainSearch('');
                this.setState({ release: 'not_yes_released' })
                this.props.history.push("/");
            }
        }
        else if (query.message) {
            if (query.message !== '') {
                // this.setState({ message: query.message})
                // this.props.history.push("/");
                // window.location.href = '/'
            }
        }

        if (this.props.location.hash === '#/nike') {
            this.setState({ 'brand_id': 2 });
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $('.row.releasedate-blk').offset().top
                }, 1000);
            }, 2000);
        } else if (this.props.location.hash === '#/Jordan') {
            this.setState({ 'brand_id': 1 });
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $('.row.releasedate-blk').offset().top
                }, 1000);
            }, 2000);
        } else if (this.props.location.hash === '#/adidas') {
            this.setState({ 'brand_id': 5 });
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $('.row.releasedate-blk').offset().top
                }, 1000);
            }, 2000);

        } else if (this.props.location.hash === '#/Yeezy') {
            this.setState({ 'brand_id': 35 });
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $('.row.releasedate-blk').offset().top
                }, 1000);
            }, 2000);
        }

        if (this.state.message) {
            setTimeout(() => {
                $('.notification-bar').hide();
            }, 6000);
        }

        if ($('.carousel.banner-carousel').length > 0) {
            $('body').addClass("home");
        }
        else {
            $('body').removeClass("home");
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.category !== '' || nextProps.sub_category !== '') {
            this.setState({ brand: [] });
        }
    }

    updateSort(sort) {
        this.setState({ sort: sort });
    }

    updateApplyFilter(obj) {
        this.setState({
            brand: obj.brand,
            archive: obj.archive,
            release: obj.release,
            models: obj.models,
            brand_id: obj.brand_id,
            most_views: obj.most_views,
            most_likes: obj.most_likes,
            month: obj.month,
            year: obj.year
        });
    }

    updateRelease(release) {
        if (release === 'not_yes_released' || release === 'past_releases') {
            this.setState({ release: release });
        } else {
            this.setState({ release: release });
        }
    }

    clearSearch() {
        this.setState({ searchMobile: '' })
    }

    submitFormnew = e => {
        e.preventDefault()
        return false;
    }

    updateMainSearch(search) {
        this.props.updateMainSearch(search)
    }

    updateSearch(search) {
        this.clearTopMenu();
        this.setState({ searchMobile: search });
    }

    changeSearch = e => {
        this.setState({ searchMobile: e.target.value })
    }

    updateArchive(archive) {
        let archiveOld = this.state.archive;
        let releaseOld = this.state.release;

        if (archive !== 'view_all') {
            if (!archiveOld.includes(archive)) {
                archiveOld.push(archive);
            } else {
                let index = archiveOld.indexOf(archive);
                archiveOld.splice(index, 1);
            }
            if (archiveOld.length !== 0 && releaseOld !== 'view_all') {
                releaseOld = 'view_all';
            }
            this.setState({ archive: archiveOld, release: releaseOld });
        } else {
            this.setState({ archive: [] });
        }
    }

    clearTopMenu() {
        this.props.clearTopMenu();
    }

    updateBrand(brand) {
        this.clearTopMenu();
        if (brand !== 'view_all') {
            let brandOld = this.state.brand;
            if (!brandOld.includes(brand)) {
                brandOld.push(brand);
            } else {
                let index = brandOld.indexOf(brand);
                brandOld.splice(index, 1);
            }
            this.setState({ brand: brandOld });
        } else {
            this.setState({ brand: [] });
        }

    }

    render() {
        if (this.props.category == 'nike') {
            document.title = 'Nike Release Dates | Kicksfinder';
        } else if (this.props.category == 'jordan') {
            document.title = 'Jordan Release Dates | Kicksfinder';
        } else if (this.props.category == 'adidas') {
            document.title = 'Adidas Release Dates | Kicksfinder';
        } else if (this.props.category == 'yeezy') {
            document.title = 'Yeezy Release Dates | Kicksfinder';
        }
        return (
            <Fragment>
                {/* {this.state.isSearch === false ? <BannerSlider /> : ''} */}
                {this.state.message !== '' && this.state.sort === '' ? <h6 className="notification-bar">{this.state.message}</h6> : ''}

                <div>
                    {/* {(this.state.sort !== '' && this.state.sort !== 'by_date' && this.state.sort !== 'Popular' && this.state.sort !== 'Recently Added') || this.state.release !== 'view_all' || this.state.brand.length > 0 || this.state.archive.length > 0 || this.state.searchMobile !== '' ?
                        <div className="responsive-filter d-block d-sm-block d-md-block d-lg-none">
                            <form onSubmit={this.submitFormnew}>
                                {this.state.searchMobile !== '' ?
                                    <>
                                        <input
                                            className='form-control search-input-cntrl'
                                            value={this.state.searchMobile}
                                            onChange={this.changeSearch}
                                            type='search'
                                            aria-label='Search'
                                        />
                                        <button type="button" className='btn btn-close' onClick={this.clearSearch} id="close-btn">
                                        </button>
                                    </>
                                    : ''
                                }
                            </form>
                            {(this.state.sort !== '' && this.state.sort !== 'by_date') || this.state.release !== 'view_all' || this.state.brand.length > 0 || this.state.archive.length > 0 ?
                                <ul className="filter-list">
                                    {this.state.sort !== '' && this.state.sort !== 'by_date' && this.state.sort !== 'Popular' && this.state.sort !== 'Recently Added' ?
                                        <li><span>{COMMONCONST.getSortFilterName(this.state.sort)} <i onClick={() => this.updateSort('by_date')} className="filter-res-close"></i></span></li>
                                        : ''}
                                    {this.state.release !== 'view_all' ?
                                        <li><span>{COMMONCONST.getReleaseFilterName(this.state.release)} <i onClick={() => this.updateRelease('view_all')} className="filter-res-close"></i></span></li>
                                        : ''}

                                    {this.state.brand.map((b) =>
                                        <li key={b}><span>{b} <i onClick={() => this.updateBrand(b)} className="filter-res-close"></i></span></li>
                                    )}
                                    {this.state.archive.map((a) =>
                                        <li key={a}><span>{a} <i onClick={() => this.updateArchive(a)} className="filter-res-close"></i></span></li>
                                    )}
                                </ul>
                                : ''}
                        </div>
                        : ''} */}
                    {/* <div className="responsive-home-tab d-flex d-sm-flex d-md-flex d-lg-none">
                        <ul className="res-tab-ul">
                            <li className={this.state.sort !== 'Popular' && this.state.sort !== 'Recently Added' ? 'active' : ''} onClick={() => this.updateSort('by_date')}>Upcoming</li>
                            <li className={this.state.sort === 'Popular' ? 'active' : ''} onClick={() => this.updateSort('Popular')}>Popular</li>
                            <li className={this.state.sort === 'Recently Added' ? 'active' : ''} onClick={() => this.updateSort('Recently Added')}>Recently Added</li>
                        </ul>
                    </div> */}
                    <div id="main_content" className="main_content wrapp home-wrap">
                        <div className="container">
                            {this.state.isSearch === false ? <>
                                <div className="row product-blk-main">
                                    <div className="product-blk-wrap">
                                        <div className="popular-prod-blk">
                                            <PopularList></PopularList>
                                        </div>
                                        <div className="recent-prod-blk">
                                            <RcentlyAdded />
                                        </div>
                                    </div>

                                </div>
                                <div className="row staffpick-blk">
                                    <div className="pad-50-blk">
                                        <StaffPicks title="Staff Picks" subTitle="Staff picks by Kicksfinder & Sneakernews" />
                                    </div>
                                </div>
                                <div className="row availablenow-blk">
                                    <div className="pad-50-blk">
                                        <AvailableNow title="Available Now" subTitle="Don’t wait! These sneakers are currently available. Check out the retailer lists." />
                                    </div>
                                </div>
                                <div className="row sneakernews-blk">
                                    <div className="pad-50-blk">
                                        <LatestNews title="Sneaker News" subTitle="Latest News in Sneakers." />
                                    </div>
                                </div>
                                <div className="row live-updates-blk">
                                    <div className="pad-50-blk">
                                        <TwitterList title="Live Updates" />
                                    </div>
                                </div>
                            </> : ''}
                            <div className="row releasedate-blk">
                                {/* <div className="col-lg-3">
                                        <Sidebar updateBrand={this.updateBrand} updateSearch={this.updateSearch} release={this.state.release} isSearch={this.state.isSearch} sort={this.state.sort} brand={this.state.brand} archive={this.state.archive} searchMobile={this.state.searchMobile} updateSort={this.updateSort} updateArchive={this.updateArchive} updateRelease={this.updateRelease} />
                                    </div> */}

                                <div className="pad-50-blk">
                                    <ProductListing searchFilter={true} brandfilter={this.props.category == 'nike' || this.props.category == 'jordan' || this.props.category == 'adidas' || this.props.category == 'yeezy' ? false : ''} title={this.props.category == 'nike' ? "Nike Release Dates" : this.props.category == 'jordan' ? 'Jordan Release Dates' : this.props.category == 'adidas' ? 'Adidas Release Dates' : this.props.category == 'yeezy' ? 'Yeezy Release Dates' : 'Release Dates'} subTitle="Upcoming and past release dates and available retailers" isSearch={this.state.isSearch} isWatchlistDelete={this.state.isWatchlistDelete} updateApplyFilter={this.updateApplyFilter} updateSort={this.updateSort} release={this.state.release} sort={this.state.sort} brand={this.state.brand} archive={this.state.archive} search={this.props.search} searchMobile={this.state.searchMobile} sub_category={this.props.sub_category} category={this.props.category} most_views={this.state.most_views} most_likes={this.state.most_likes} year={this.state.year} month={this.state.month} brand_id={this.state.brand_id} models={this.state.models} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(MainContent);