import React, { Component } from "react";
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import './individual-modal.css';
import IndIntroImage from '../assets/images/individual-intro.png';
import PopularList from './PopularList';
import ProductListing from './ProductListing';
import axios from 'axios';
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import searchSmiley from '../assets/images/search-think-icon.svg';

class IndividualModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modelDetail: '',
            isLoading: false,
            isError: false,
            width: window.innerWidth,
            categoryName: '',
            most_views: '',
            most_likes: '',
            month: '',
            year: '',

            sort: 'by_date',
            brand: [],
            brand_id: '',
            archive: [],
            release: 'view_all',
            isSearch: localStorage.getItem('search') ? true : false,
            searchMobile: '',
            models: [],

        };

        this.getIndividualModelDetails = this.getIndividualModelDetails.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateApplyFilter = this.updateApplyFilter.bind(this);

    }
    componentDidMount() {
        // this.getIndividualModelDetails();
        this.setState({ modelDetail: this.props.modelDetail })
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setState({ categoryName: this.props.location.pathname.split('/')[1] })
        COMMONCONST.GAPageView();
    }

    updateApplyFilter(obj) {
        this.setState({
            brand: obj.brand,
            archive: obj.archive,
            release: obj.release,
            models: obj.models,
            brand_id: obj.brand_id,
            most_views: obj.most_views,
            most_likes: obj.most_likes,
            month: obj.month,
            year: obj.year
        });
    }


    componentDidUpdate() {
        if (this.state.width <= 767) {
            if ($('.ind-content-blk').length > 0) {
                const content_length = $('.ind-content-blk').find('p').length;
                if (content_length > 2) {
                    $('.ind-content-blk p:gt(1)').hide();
                    $("<p class='read-more-txt'>Read More</p>").insertAfter(".ind-content-blk p:eq(1)");
                }
                else {
                    //console.log($('.ind-content-blk > p').length);
                }
            }
            if ($('.read-more-txt').length > 0) {
                $('.read-more-txt').click(function () {
                    $('.read-more-txt').remove();
                    $('.ind-content-blk').find('p:gt(1)').toggle();
                });
            }

        }
        else {
            $('.ind-content-blk p:gt(1)').show();
            $('.read-more-txt').remove();
        }

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });

    }
    componentWillReceiveProps(nextProps) {
        // this.setState({
        //     modelDetail: '',
        //     isLoading: false,
        //     isError: false,
        // }, () => {
        //     this.getIndividualModelDetails(nextProps);

        // });

    }
    getIndividualModelDetails(props = false) {
        let slug;
        if (props) {
            slug = props.slug;
        } else {
            slug = this.props.slug;
        }
        this.setState({
            isLoading: true
        })
        axios({
            method: "get",
            url: APICONST.IndividualModal(slug),
            headers: { "content-type": "application/json" },
            // params: {
            //     cookie: COMMONCONST.getCookie('watchlist')
            // }
        })
            .then(response => {
                COMMONCONST.GAPageView();
                this.setState({
                    modelDetail: response.data.result.modelDetails,
                    isLoading: false,
                });

            })
            .catch(error => this.setState({ isError: true, isLoading: false }));


    }

    render() {
        let modelDetail = this.state.modelDetail;
        if (modelDetail) {
            document.title = modelDetail.page_title + ' Release Dates | Kicks Finder';
        }
        return (
            <div className='main-content'>
                <div className="individual-modal-main">
                    <div className="container">
                        {!this.state.isError ?
                            <div>
                                <div className={this.state.isLoading ? "ind-intro-main is-loading" : "ind-intro-main"}>
                                    <div className="ind-intro-inner">
                                        <div className="ind-intro-image">
                                            <img src={modelDetail.image} alt="Intro Image" />
                                        </div>
                                        <div className="ind-intro-content">
                                            <h1>{modelDetail.page_title}</h1>
                                            <div className="ind-content-blk" dangerouslySetInnerHTML={{ __html: modelDetail.description }} />

                                            {/* <p>{modelDetail.description}</p> */}
                                            {/* <p>Throughout the years, the Nike Air Force 1 has produced a number of distinct iterations — some leaning towards technical upgrades while others more artistic interpretations. While the SF-AF1, Lunar Air Force 1, and Acronym's Downtown AF1 have aimed to apply a more utilitarian filter, partnerships with the aforementioned Big Bang leader, Supreme, KITH, and Off-White have grounded the model in an unmatched versatility.</p>
                                    <p>From hidden artwork to subtle streetwear branding, the Air Force 1 has seen it all. Since its inception, it’s become a totem of the entire Nike umbrella, bearing Jordan Brand influences, ACG references, and powerful messaging all within the span of a calendar year. In recent memory, pairs have taken a kneel alongside Colin Kaepernick, have supported a greener environment in celebration of Earth Day, and have dressed in bold colors as a show of support for Pride Month.</p>
                                    <p>And while notable for such variety, both the Triple White and Triple Black colorways have remained a mainstay within the Air Force 1’s library of colorways. While the latter is controversial thanks to its unique associations, the former is an oft-worn icon that has become the perfect canvas for sneaker customizers. Alongside, fans of all ages have given it a permanent spot in their personal wardrobe.</p>
                                    <p>Retail prices for the Air Force 1 vary by style. Most will be available at $90 USD while more limited editions can sell for upwards of $250 USD.</p>
                                    <p>price / $90-$250</p> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="product-blk-main">
                                    <div className="product-blk-wrap">
                                        <div className="popular-prod-blk">
                                            {modelDetail.permalink ? <PopularList model_slug={modelDetail.permalink} type="all_time"></PopularList> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="releasedate-blk">
                                    {/* <ProductListing title={modelDetail.name != "undefined" ? modelDetail.name + ' Release Dates' : ''} sub_category={modelDetail.name} release={this.state.release} sort={this.state.sort} category={this.state.categoryName} /> */}
                                    <ProductListing brandfilter={false} release={this.state.release} searchFilter={true} most_views={this.state.most_views} most_likes={this.state.most_likes} year={this.state.year} month={this.state.month} updateApplyFilter={this.updateApplyFilter} title={modelDetail.name != "undefined" ? modelDetail.name + ' Release Dates' : ''} model_slug={modelDetail.permalink} most_views={this.state.most_views} most_likes={this.state.most_likes} />
                                </div>
                            </div> : <div>
                                <div className="no-search-found search-txt"> <img src={searchSmiley} /><h5>No result available for “{this.props.slug}”</h5> </div>
                            </div>}
                    </div>
                </div>
            </div>
        )

    }
}

export default withRouter(IndividualModal)