import React, { Component } from "react";
import axios from 'axios';
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';

class BannerSlider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            items: [],
            isLoading: false
        };
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        })
        axios({
            method: "get",
            url: APICONST.SLIDER,
            headers: { "content-type": "application/json" },
        })
            .then(response => {
                this.setState({
                    items: response.data.result,
                    isLoading: false
                });
            })
            .catch(error => this.setState({ error: error.message, isLoading: false }));
        COMMONCONST.loadImage();
    }

    componentDidUpdate() {
        COMMONCONST.loadImage();
    }

    render() {
        const items = this.state.items.map((item, key) =>
            <li key={key} data-target="#carouselExampleCaptions" data-slide-to={key} className={key === 0 ? 'active' : ''}></li>
        );

        const slides = this.state.items.map((item, key) =>
            <div key={key} className={key === 0 ? 'carousel-item active' : 'carousel-item'}>
                <a href={item.link} target={item.link.startsWith('https://kicksfinder.com') ? '' : '_blank'} rel="noopener noreferrer">
                    <img src={item.image} className="d-block w-100" alt="..." />
                    <div className="carousel-caption">
                        <h5>{item.head_line_1}</h5>
                        <p>{item.head_line_2}</p>
                        <span>{item.subhead}</span>
                    </div>
                </a>
            </div>
        );

        return (
            <div id="carouselExampleCaptions" className={this.state.isLoading ? "carousel slide banner-carousel banner-placeholder" : "carousel slide banner-carousel"} data-ride="carousel" data-interval="4000" data-slide-items={items.length}>
                <ol className="carousel-indicators">
                    {items}
                </ol>
                <div className="carousel-inner">
                    {slides}
                </div>
            </div>
        )
    }
}

export default BannerSlider