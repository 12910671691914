import React from 'react'
// import { BrowserRouter as Router, Route } from 'react-router-dom'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
// import VerifyEmail from './components/VerifyEmail'
// import ResetPassword from './components/ResetPassword'
// import UserSettings from './components/UserSettings'

// const routing = (
//   <Router>
//     <div>
//       <Route exact path='/' component={App} />
//       <Route exact path='/verify-email/:token' component={VerifyEmail} />
//       <Route exact path='/verify-updated-email/:token' component={VerifyEmail} />
//       <Route exact path='/reset-password/:resettoken' component={App} />
//       <Route exact path='/user-settings/' component={UserSettings} />
//     </div>
//   </Router>
// )

// ReactDOM.render(routing, document.getElementById('root'))
ReactDOM.render(<App />, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
