import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import Logo from '../assets/images/new-logo.svg'
import "../assets/css/font-awesome.min.css";
import "../assets/fonts/fonts.css";
import "./Custom.css";
import * as APICONST from '../constants/Api';

class ForgotPwd extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      rememberMe: false,
      error: null,
      success: false,
      isLoading: false,
      errors: {
        email: "",
      },
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  handleFormSubmit = (e) => {

    e.preventDefault();
    // this.validateEmail();
    if (this.state.email === "" || this.state.password === "") {
      this.setState({ error: true });
    }
    let email = this.state.email;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if ("" === email) {
      this.setState({
        errors: {
          email: "Please enter an email address",
        },
      });
      return false;
    } else if (email && validEmailRegex.test(email) === false) {
      this.setState({
        errors: {
          email: "Email is not valid",
        },
      });
      return false;
    } else {
      this.setState({
        errors: {
          email: "",
        },
      });
    }

    if (this.state.email !== "") {

      this.setState({ success: false, isLoading: true });
      axios({
        method: "post",
        url: APICONST.FORGOTPASSWORD,
        headers: { "content-type": "application/json" },
        data: this.state,
      })
        .then((response) => {
          if (response.data.result.message !== " ") {
            this.setState({ success: true, isLoading: false });
          }
        })
        .catch((error) => {
          if (
            typeof error.response !== 'undefined' &&
            typeof error.response.data.result.message !== 'undefined'
          ) {
            this.setState({
              errors: {
                email: error.response.data.result.message, isLoading: false
              }
            })
          }
          this.setState({ error: error.message, isLoading: false })
        });
    }
  };

  validateEmail = () => {
    if (this.state.email === "" || this.state.password === "") {
      this.setState({ error: true });
    }
    let email = this.state.email;
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if ("" === email) {
      this.setState({
        errors: {
          email: "Please enter an email address",
        },
      });
      return false;
    } else if (email && validEmailRegex.test(email) === false) {
      this.setState({
        errors: {
          email: "Email is not valid",
        },
      });
      return false;
    } else {
      this.setState({
        errors: {
          email: "",
        },
      });
    }
  }

  revertFields = () => {
    let tagErrors = this.state.errors;
    tagErrors.email = '';
    this.setState({
      email: '',
      tagErrors,
      tagError: true
    })
  }

  render() {
    $(document).ready(function () {
      $('#forgotPwdModal').on('show.bs.modal', function () {
        $("#signinModal").modal('hide');
        $("body").addClass("modal-popup-open");
      });
    });

    return (
      <Fragment>
        <div
          className="modal signModal fade"
          id="forgotPwdModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="container">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.revertFields}
                  >

                  </button>
                  <div className="sign-block">
                    <div className="form-info">
                      <Link to="/" className="navbar-brand">
                        <img src={Logo} className="logo" alt="logo" />
                      </Link>
                      <p>
                        Enter your email address to reset your password. You may
                        need to check your junk folder.{" "}
                      </p>
                      <h3>Forgot Password</h3>
                    </div>
                    <form
                      action=""
                      onSubmit={this.handleFormSubmit}
                      className="modal-box no-error"
                    >
                      <div className="form-row">
                        {this.state.errors.email ? (
                          <span className='errorMsg'>
                            <i className='alert-icon fa fa-exclamation'></i>
                            {this.state.errors.email}
                          </span>
                        ) : (
                          ""
                        )}
                        <div className={`form-field-wrp kf-focus kf-clear ${this.state.email.length <= 0 || this.state.errors.email ? 'kf-negative' : 'kf-positive'}`}>
                          <input
                            type="text"
                            name="email"
                            id="email_signin"
                            placeholder="Email"
                            value={this.state.email}
                            className={this.state.errors.email ? "error-tag form-field" : "form-field"}
                            onChange={(e) =>
                              this.setState({
                                email: e.target.value
                              }, () => this.validateEmail())
                            }
                          />
                          {this.state.email &&
                            <span
                              className="kf-clear-span"
                              onClick={e => {
                                this.setState({ email: '' })
                              }}
                            >
                            </span>
                          }
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="right">
                          <button className={this.state.isLoading ? "signin-btn is-loading" : "signin-btn"}>Submit
                          <i className="fa fa-spinner loadicon"></i>
                          </button>
                        </div>
                      </div>
                      {this.state.success ? (
                        <div className="form-row userMsg userForgotPwdMsg">

                          <p className="notice">
                            An e-mail has been sent with further instructions
                              to reset your password.{" "}
                          </p>
                          <p className="notice">
                            Didn't received email from kicksfinder? It may take
                            a few minutes. Make sure to check your junk folder.
                            Click this  <Link to='#' onClick={this.handleFormSubmit}>
                              link
                            </Link>{' '} to resend email.
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ForgotPwd;
