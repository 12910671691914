import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import ProductListing from './ProductListing';
// import LatestNews from './LatestNews';
import $ from 'jquery'
import * as COMMONCONST from '../constants/Common';

class WatchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: 'by_date',
            brand: [],
            archive: [],
            release: 'view_all',
            isSearch: false,
            searchMobile: '',
            lastUpdated: '',
            isWatchList: this.props.location.pathname === '/watchlist' ? 1 : 0,
            isWatchlistDelete: COMMONCONST.getCookie('watchlist') ? 0 : 1,
            models: [],
            most_views: '',
            most_likes: '',
            month: '',
            year: '',
            brand_id: ''
        };
        this.updateSort = this.updateSort.bind(this);
        this.updateArchive = this.updateArchive.bind(this);
        this.updateBrand = this.updateBrand.bind(this);
        this.updateRelease = this.updateRelease.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.lastUpdated = this.lastUpdated.bind(this);
        this.updateApplyFilter = this.updateApplyFilter.bind(this);
    }

    componentDidMount() {
        COMMONCONST.GAPageView();
        $('.last-updated').hide();
    }

    updateSort(sort) {
        this.setState({ sort: sort });
    }

    updateRelease(release) {
        if (release === 'not_yes_released' || release === 'past_releases') {
            this.setState({ release: release });
        } else {
            this.setState({ release: release });
        }
    }

    updateApplyFilter(obj) {
        this.setState({
            brand: obj.brand,
            archive: obj.archive,
            release: obj.release,
            models: obj.models,
            brand_id: obj.brand_id,
            most_views: obj.most_views,
            most_likes: obj.most_likes,
            month: obj.month,
            year: obj.year
        });
    }

    lastUpdated(date) {
        this.setState({ lastUpdated: date });
    }

    updateSearch(search) {
        this.setState({ searchMobile: search });
    }

    updateArchive(archive) {
        let archiveOld = this.state.archive;
        let releaseOld = this.state.release;

        if (!archiveOld.includes(archive)) {
            archiveOld.push(archive);
        } else {
            let index = archiveOld.indexOf(archive);
            archiveOld.splice(index, 1);
        }
        if (archiveOld.length !== 0 && releaseOld !== 'view_all') {
            releaseOld = 'view_all';
        }
        this.setState({ archive: archiveOld, release: releaseOld });
    }

    updateBrand(brand) {
        let brandOld = this.state.brand;
        if (brand !== 'view_all') {
            if (!brandOld.includes(brand)) {
                brandOld.push(brand);
            } else {
                let index = brandOld.indexOf(brand);
                brandOld.splice(index, 1);
            }

            this.setState({ brand: brandOld });
        } else {
            this.setState({ brand: [] });
        }

    }

    render() {

        return (
            <Fragment>
                <div>
                    <div id="main_content" className="main_content wrapp">
                        <div className="watchlist-wrap">
                            <div className="container">
                                <div className="releasedate-blk">
                                    <ProductListing hideResSearch={true} searchForm={true} searchFilter={true} title="Watchlist" lastUpdated={this.lastUpdated} isWatchlistDelete={this.state.isWatchlistDelete} isWatchList={this.state.isWatchList} updateSort={this.updateSort} release={this.state.release} sort={this.state.sort} brand={this.state.brand} archive={this.state.archive} search={this.props.search} searchMobile={this.state.searchMobile} updateApplyFilter={this.updateApplyFilter} most_views={this.state.most_views} most_likes={this.state.most_likes} year={this.state.year} month={this.state.month} brand_id={this.state.brand_id} models={this.state.models} />
                                </div>
                                {/* {this.state.sort === '' || this.state.isWatchList === 1 ? 
                                    <div className="row">      
                                        <div className="col-lg-12">
                                            <LatestNews title="Latest News" />
                                        </div>                  
                                    </div>
                                : '' } */}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(WatchList)