import React, { Component } from "react";
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import Breadcrumb from "./Breadcrumb";
import ProductListing from './ProductListing';
import './store-locator.css';
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import axios from 'axios';
import NotFound404 from './NotFound404';
import $ from 'jquery'

class StoreLocatorDetail extends Component {
	constructor(props) {
        super(props)

        this.state = {
            storedetail: {},
            isLoading: false,
            isError: false,
        };
    }

	getStoreDetails() {
        
        this.setState({
            isLoading: true
        })

        axios({
            method: "get",
            url: APICONST.StoreDetail(this.props.slug),
            headers: { "content-type": "application/json" },
            params: {
            }
        })
            .then(response => {
				COMMONCONST.GAPageView();
                this.setState({
                    isLoading: false,
                    storedetail: response.data.result.store,
                });
				console.log(response.data.result);

            })
            .catch(error => this.setState({ isError: true, isLoading: false }));

    }

	componentDidMount() {
		this.getStoreDetails();
	}

	render() {

		const {
            slug,
			url
        } = this.props;

		const breadcrumb = [
			{ name: 'KICKSFINDER', url: '/' },
			{ name: 'STORE LOCATOR', url: '/store-locator' },
			{ name: 'STORE DIRECTORY', url: '/store-locator/store-directory' },
			{ name: this.state.storedetail?.name, url: '' },
		];

		const getCurrentWeekdayName = () => {
			const date = new Date();
			const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			const currentDay = date.getDay();
			let currentDayName = days[currentDay];
			const weekdayTextArray = this.state?.storedetail?.weekday_text || '[]';
			let weekDayArray = JSON.parse(weekdayTextArray);
			console.log((weekDayArray));
			if( typeof (weekDayArray) === 'object' && weekDayArray.length > 0) {
				weekDayArray.forEach(element => {
					console.log(element, 'element');
				});
				const currentDayText = weekDayArray.find(text => text.includes(currentDayName));
				return currentDayText.replace(currentDayName, 'Open today');
			} else {
				return '';
			}

		  };
		const currentYear = new Date().getFullYear();
		if (!this.state.isError) {
			let storedetail = this.state.storedetail;
			if (storedetail?.name) {
				let storename = storedetail?.name;
				let storecity = storedetail?.city;
				console.log(storename, storecity);
				let title = '';
				if (storename.includes(storecity)) {
					title = storedetail?.name + ' | KicksFinder';
				} else {
					title = storedetail?.name + ' - ' + storecity + ' | KicksFinder';
				}
                document.title = title
                $('#og-title').attr('content', title);
            }
            if (storedetail?.address) {
				let address = storedetail?.address + ', ' + storedetail?.phone;
                $('#meta-description').attr('content', address);
                $('#og-description').attr('content', address);
            }
			return (
				<div className='App'>
					<Breadcrumb breadcrumb={breadcrumb} />
					<div className={this.state.isLoading ? "store-locator store-locator-detail sticky-loader-active" : "store-locator store-locator-detail"}>
					{this.state.isLoading ? (<div className="sticky-loader-wrap"><span className="sticky-loader-span"></span></div>) : ''}
						<div className="container padd-large">
							<div className="store-locator-detail__banner">
								<div className="store-locator-detail__banner-info">
									<h1>{this.state.storedetail?.name}</h1>
									<p className="description">{this.state.storedetail?.overview}</p>
									<div className="location-info">
										<p className="address">{this.state.storedetail?.address}</p>
										<p className="tel">{this.state.storedetail?.phone}</p>
										<p className="time">{getCurrentWeekdayName()}</p>
										{this.state.storedetail?.store_detault_release_url && (<p className="link"><a href={this.state.storedetail?.store_detault_release_url} target="_blank">Shop Now</a></p>)}
									</div>
									<div className="btn-wrap">
										{this.state.storedetail?.map_url && (<a href={this.state.storedetail?.map_url} target="_blank" className="btn get-directions">GET DIRECTIONS</a>)}
										{this.state.storedetail?.phone && (<a href={`tel:${this.state.storedetail?.phone}`} className="btn call-store">CALL STORE</a>)}
									</div>
								</div>
								<div className="store-locator-detail__banner-img">
									<img width={800} height={541} src={this.state.storedetail?.image} alt="" />
								</div>
							</div>
							{this.state.storedetail?.store_detault_release_url && this.state.storedetail?.store_location_promotion_association?.length > 0 && (
							<div className="coupons-promo-section">
								<h2>Coupons & Promo Codes</h2>
								<div className="coupons-promo-grid">
									{this.state.storedetail?.store_location_promotion_association?.map((promotion, index) => (
									<div className={promotion.promotion.status === 'Available' ? 'coupons-promo-grid__item' : "coupons-promo-grid__item coupons-promo-grid__item--expired"}>
										{promotion.promotion.is_kicksfinder_exclusive == 1 && (<div className="coupons-promo-tag coupons-promo-tag--exclusive">KICKSFINDER EXCLUSIVE</div>)}
										<h3>{promotion.promotion.promotion_title}</h3>
										{promotion.promotion.description && (<p>{promotion.promotion.description}</p>)}
										<div className="btn-wrap">
											<a href={this.state.storedetail?.store_detault_release_url} className="btn">{promotion.promotion.status === 'Available' ? 'GET DEAL' : promotion.promotion.status === 'Not Started' ? "Offer Not Started Yet" : "Expired"}</a>
										</div>
									</div>))}
								</div>
							</div>)}
							{this.state.storedetail?.stockist_store?.id && (<ProductListing store_id={this.state.storedetail?.stockist_store?.id} searchForm={false} isSearch={false} searchFilter={false} brandfilter={false} year={currentYear} title="Available Sneakers" />)}
							
						</div>
					</div>
				</div>
			)
		} else {
			return (<NotFound404></NotFound404>)
		}
	}
}

export default StoreLocatorDetail