import React, { Component, Fragment } from "react";
import $ from 'jquery'
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import LatestNews from './LatestNews';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import postdisclaimerIcon from '../assets/images/postdisclamier-icon.svg'
import externalLinkIcon from '../assets/images/externallink.svg'
import ReleaseSoon from './ReleasingSoon'
import YouMayAlsoLike from './YouMayAlsoLike'
import axios from 'axios';
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import NotFound404 from './NotFound404';
import individual from './individualModal';
import ProductDetail from './ProductDetail';
import moment from 'moment-timezone';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import IndividualModal from "./individualModal";
let sizerun;
class Details extends Component {
    constructor(props) {
        super(props)
        this.state = {
            product: '',
            productDetail: '',
            isError: false
        }
    }

    componentDidMount() {
        this.getProductDetails();
        this.getTimeZoneList();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            productDetail: {
                simillar: '',
                you_may_like: '',
                images: [],
                sizes: [],
                images_captions: [],
                zone_wises: {},
                releases: []
            },
            showtimer: '',
            timeZone: [],
            isLoading: false,
            isError: false,
            userTimeZone: ''
        }, () => {
            this.getProductDetails(nextProps);
            // this.getTimeZoneList();

        });
    }


    getTimeZoneList() {
        axios({
            method: "get",
            url: APICONST.TIMEZONELIST,
            headers: { "content-type": "application/json" }
        })
            .then(response => {

                this.setState({
                    timeZone: response.data.result.timeZoneList
                });

            })
            .catch(error => { });
    }


    checkReleaseDate(date, timezone) {
        var then = moment.tz(date, timezone);
        // (then.format("YYYY-MM-DD HH:mm:ss"));
        // .format('z')
        var current = moment().tz(timezone);
        if (then > current) {
            return true;
        } else {
            return false;
        }
    }

    getProductDetails(props = false) {
        let slug;
        if (props) {
            slug = props.slug;
        } else {
            slug = this.props.slug;
        }
        this.setState({
            isLoading: true,
            productDetail: '',
            product: ''
        })
        axios({
            method: "get",
            url: APICONST.PRODUCTDETAILS(slug),
            headers: { "content-type": "application/json" },
            params: {
                cookie: COMMONCONST.getCookie('watchlist')
            }
        })
            .then(response => {
                // COMMONCONST.GAPageView();
                // this.setState({
                //     isLoading: false,
                //     productDetail: response.data.result.product
                // }, () => {
                //     this.setState({ userTimeZone: this.state.productDetail.current_time_zone })

                // });
                if (response.data.result.product) {
                    this.setState({ product: response.data.result.product,isLoading:false })
                } else {
                    this.setState({ productDetail: response.data.result.modelDetails,isLoading:false })
                }

            })
            .catch(error => this.setState({ isError: true, isLoading: false }));

    }


    render() {
        // if (this.state.product) {
        //     return (
        //         <ProductDetail product={this.state.product}></ProductDetail>
        //     )
        // } else if (this.state.productDetail) {
        //     return (
        //         <individualModal modelDetail={this.state.productDetail}></individualModal>
        //     )
        // }
        // if (this.state.isError) {
        //     return (
        //         <NotFound404></NotFound404>
        //     )
        // }
        return (
            <div className="individual-product-main-content">
                <div className= {this.state.isLoading ? "sticky-loader-active individual-product-main" : ''}>
                    {this.state.product ? <ProductDetail product={this.state.product}></ProductDetail> : ''}
                    {this.state.productDetail ? <IndividualModal modelDetail={this.state.productDetail}></IndividualModal> : ''}
                    {this.state.isError ? <NotFound404></NotFound404> : ''}
                    {this.state.isLoading ?  <div className="sticky-loader-wrap"><span className="sticky-loader-span"></span></div>  : ''}
                </div>
            </div>
        )
    }
}

export default Details
