import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login'
import AppleLogin from 'react-apple-login'
import $ from 'jquery'
import axios from 'axios'
import '../App.css'
import Logo from '../assets/images/new-logo.svg'
import '../assets/css/font-awesome.min.css'
import '../assets/fonts/fonts.css'
import './Custom.css'
import { DOMAIN, APPLE_CLIENT_ID, APPLE_REDIRECT_URL } from '../constants/Api'
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';

class SignIn extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      error: null,
      errors: {
        email: '',
        password: '',
        error_message: ''
      },
      tagError: false,
      isLoading: false,
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }
  componentDidMount() {
    const rememberMe = localStorage.getItem('rememberMe') === 'true'
    const email = rememberMe ? localStorage.getItem('email') : ''
    this.setState({ email, rememberMe })
  }
  handleFormSubmit = e => {
    e.preventDefault()

    this.validateEmail();
    this.validatePassword();

    if (
      this.state.email !== '' &&
      this.state.password !== '' &&
      this.state.tagError !== true
    ) {
      this.setState({
        isLoading: true
      });
      axios({
        method: 'post',
        url: APICONST.LOGIN,
        headers: { 'content-type': 'application/json' },
        data: this.state
      })
        .then(response => {
          if (response.data.result.message !== ' ') {
            $('.userSignInMsg').html(`<p>${response.data.result.message}</p>`)
            this.setState({
              errors: {
                error_message: response.data.result.message
              },
              isLoading: false
            })
            localStorage.setItem('rememberMe', true)
            localStorage.setItem('email', response.data.result.data.email)
            localStorage.setItem('token', response.data.result.data.token)
            if(COMMONCONST.getCookie('user-account') == 'delete-account') {
              COMMONCONST.setCookieValue('user-account', '');
              window.location = '/user-settings'
            } else {
              window.location = '/'
             }
          } else {
            this.setState({ error: false, isLoading: false })
            $('#signinModal').hide()
            $('.modal-backdrop').hide()
          }
        })
        .catch(error => {
          this.setState({ error: error.response.data.result.message, isLoading: false })
        })
    }
  }

  validateEmail = () => {
    let email = this.state.email
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    )
    let tagErrors = this.state.errors
    if ('' === email) {
      tagErrors.email = 'Please enter an email address'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (email && validEmailRegex.test(email) === false) {
      tagErrors.email = 'Email is not valid'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.email = ''
      this.setState({
        tagErrors,
        tagError: false
      })
    }
  }

  validatePassword = () => {
    let password = this.state.password
    let tagErrors = this.state.errors
    if ('' === password) {
      tagErrors.password = 'Please enter a password'
      this.setState({
        tagErrors,
      })
    } else {
      tagErrors.password = ''
      this.setState({
        tagErrors,
        tagError: false,
        error: null
      })
    }
  }

  modalClick = () => {
    $('#signinModal .close').trigger('click');
    this.revertFields();
  }

  revertFields = () => {
    let tagErrors = this.state.errors;
    tagErrors.email = '';
    tagErrors.password = '';
    this.setState({
      email: '',
      password: '',
      tagErrors,
      tagError: true
    })
  }

  render() {
    return (
      <Fragment>
        <div
          className='modal signModal fade'
          id='signinModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='container'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-body'>
                  <button
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={this.revertFields}
                  >
                  </button>
                  {/* <div className='img-block'>
                    <img
                      src={require('../assets/images/popup-img.png')}
                      alt='popup-img'
                    />
                  </div> */}
                  <div className='sign-block'>
                    <div className='form-info'>
                      <Link to='/' className='navbar-brand'>
                        <img src={Logo} className='logo' alt='logo' />
                      </Link>
                      <h3>Log in<span className="account-link">or <strong>
                        <Link to='/' data-toggle="modal" data-target="#signupModal" onClick={this.modalClick}>create an account</Link></strong></span>
                      </h3>
                    </div>

                    <form
                      action=''
                      onSubmit={this.handleFormSubmit}
                      className={
                        this.state.error ? 'modal-box' : 'modal-box no-error'
                      }
                    >
                      <div className='form-row'>
                        {this.state.errors.email ? (
                          <span className='errorMsg'>
                            <i className='alert-icon fa fa-exclamation'></i>
                            {this.state.errors.email}
                          </span>
                        ) : (
                          ''
                        )}
                        <div className={`form-field-wrp kf-focus kf-clear ${this.state.email.length <= 0 || this.state.errors.email ? 'kf-negative' : 'kf-positive'}`}>
                          <input
                            type='text'
                            name='email'
                            id='email_signin'
                            placeholder='Email'
                            value={this.state.email}
                            onChange={e =>
                              this.setState({
                                email: e.target.value
                              }, () => this.validateEmail())
                            }
                            className={this.state.errors.email ? 'error-tag form-field' : 'form-field'}
                          />
                          {this.state.email &&
                            <span
                              className="kf-clear-span"
                              onClick={e => {
                                this.setState({ email: '' })
                              }}
                            >
                            </span>
                          }
                        </div>
                      </div>
                      <div className='form-row'>
                        {this.state.errors.password ? (
                          <span className='errorMsg'>
                            <i className='alert-icon fa fa-exclamation'></i>
                            {this.state.errors.password}
                          </span>
                        ) : (
                          ''
                        )}
                        {!this.state.errors.password && this.state.error ? (
                          <span className='errorMsg'>
                            <i className='alert-icon fa fa-exclamation'></i>
                            {this.state.error}
                          </span>
                        ) : (
                          ''
                        )}
                        <div className={`form-field-wrp kf-focus kf-clear kf-toggle-pass ${this.state.password.length <= 0 || this.state.errors.password ? 'kf-negative' : 'kf-positive'}`}>
                          <input
                            type='password'
                            name='password'
                            id='pwd_signin'
                            placeholder='Password'
                            value={this.state.password}
                            onChange={e =>
                              this.setState({
                                password: e.target.value
                              }, () => this.validatePassword())
                            }
                            className={this.state.errors.password ? 'error-tag form-field password-field' : 'form-field password-field'}
                          />
                          <span className="kf-toggle-pass-span"></span>
                          {this.state.password &&
                            <span
                              className="kf-clear-span"
                              onClick={e => {
                                this.setState({ password: '' })
                              }}
                            >
                            </span>
                          }
                        </div>
                      </div>

                      <div className='form-row'>
                        <Link
                          to=''
                          className='forgot-pwd'
                          data-toggle='modal'
                          data-target='#forgotPwdModal'
                        >
                          Forgot your password?
                        </Link>
                      </div>
                      <div className='form-row'>
                        {/* <div className='left'>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            id='check_remember'
                            checked={this.state.rememberMe}
                            onChange={() =>
                              this.setState({
                                rememberMe: !this.state.rememberMe
                              })
                            }
                          />
                          <label
                            className='form-check-label'
                            htmlFor='check_remember'
                          >
                            Remember me
                          </label>
                        </div> */}
                        <button className={this.state.isLoading ? 'signin-btn is-loading' : 'signin-btn'}>Log in
                          <i className="fa fa-spinner loadicon"></i>
                        </button>
                        {/* <div className=''>
                         
                        </div> */}
                      </div>

                    </form>
                    <span className='or'>or</span>
                    <div className="kf-login-buttons">
                      <GoogleLogin
                        className='google-signup'
                        clientId={this.props.gapi}
                        buttonText='Sign in with Google'
                        onSuccess={this.props.responseGoogle}
                        onFailure={this.props.responseGoogle}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                        redirectUri={DOMAIN}
                      />
                      <button className="apple-signup">
                        <span>Sign in with Apple</span>
                        <AppleLogin
                          clientId={APPLE_CLIENT_ID}
                          redirectURI={APPLE_REDIRECT_URL}
                          scope={"name email"}
                          responseType={"code"}
                          responseMode={"form_post"}
                          usePopup={false}
                          designProp={
                            {
                              height: 37,
                              width: 140,
                              color: "white",
                              border: false,
                              type: "sign-in",
                              border_radius: 15,
                              scale: 1,
                              locale: "en_US",
                            }
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default SignIn
