import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import Breadcrumb from "./Breadcrumb";
import './store-locator.css';
import * as APICONST from '../constants/Api';
import axios from 'axios';
import $ from 'jquery'
import * as COMMONCONST from '../constants/Common';

import StoreLocatorInfoBox from './StoreLocatorInfoBox';

class StoreLocatorState extends Component {
	constructor(props) {
        super(props)

        this.state = {
            storeList: [],
            isLoading: false,
            isError: false,
			total: 0,
			pages: 0,
			perpage: 12,
			page: 0
        };
		this.loadMore = this.loadMore.bind(this);
    }	

	getStoreList(isLoadMore = false) {
        this.setState({
            isLoading: true
        })
		let page = this.state.page + 1;
        axios({
            method: "get",
            url: APICONST.StoreList,
            headers: { "content-type": "application/json" },
            params: {
				perpage: 12,
				state: this.props.slug,
				page: page
            }
        })
            .then(response => {
				let storeList = this.state.storeList;
				if(isLoadMore) {
					storeList.data = storeList.data.concat(response.data.result.data.data);
				} else {
					COMMONCONST.GAPageView();
					storeList = response.data.result.data;
				}
                this.setState({
                    isLoading: false,
                    storeList: storeList,
					total: response.data.result.data.meta.total,
					pages: response.data.result.data.meta.pages,
					page: response.data.result.data.meta.page
                });
				console.log(response.data.result.data.meta);

            })
            .catch(error => this.setState({ isError: true, isLoading: false }));

    }

	loadMore = () => {
		this.getStoreList(true);
	}

	componentDidMount() {
		this.getStoreList();
	}
	render() {

		const {
            slug,
			url
        } = this.props;

		const breadcrumb = [
			{ name: 'KICKSFINDER', url: '/' },
			{ name: 'STORE LOCATOR', url: '/store-locator' },
			{ name: 'STORE DIRECTORY', url: '/store-locator/store-directory' },
			{ name: slug, url: '' },
		];
		let title = 'Kicks Finder - Sneaker Store Locator';
		document.title = title
		$('#og-title').attr('content', title);
		let description = "Kickfinder's Sneaker Store Locator is a comprehensive database of sneaker retailers across the United States. Easily search for stores by name or location and view a list of sneakers available at each store.";
		$('#meta-description').attr('content', description);
		$('#og-description').attr('content', description);
		return (
			<div className='App'>
				<Breadcrumb breadcrumb={breadcrumb} />
				<div className={this.state.isLoading && this.state.page === 0 ? "store-locator store-locator-state sticky-loader-active" : "store-locator store-locator-state"}>
					{this.state.isLoading && this.state.page === 0 ? (<div className="sticky-loader-wrap"><span className="sticky-loader-span"></span></div>) : ''}
					<div className="container padd-large">
						<div className="store-locator-banner">
							<h1 className="main-heading">Store Locator</h1>
							<p className="sub-heading"><Link to="/store-locator" className="back-link">BACK TO SEARCH BY LOCATION</Link></p>
						</div>
						<div className="store-locator-listing">
							<div className="store-locator-listing__head">
								<h2>{slug} ({this.state.total} Stores)</h2>
							</div>
							<div className="store-locator-listing__row">
								{this.state.storeList && this.state.storeList.data && this.state.storeList.data.map((store, key) =>{
									return <StoreLocatorInfoBox key={key} store={store} />
								})}
							</div>
							{this.state.page < this.state.pages && (<div className="loadmore-btn"><button className={this.state.isLoading ? "load-more is-loading" : "load-more"} onClick={this.loadMore}>Load More <i className="fa fa-spinner loadicon"></i></button></div>)}
							{this.state.storeList && this.state.storeList.data && this.state.storeList.data.length === 0 && <div className="text-center my-3 font-weight-bold">No stores found</div>}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(StoreLocatorState)