import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
// import { GoogleLogin } from 'react-google-login'
import axios from 'axios'
// import Logo from '../assets/images/logo.png'
import '../assets/css/font-awesome.min.css'
import '../assets/fonts/fonts.css'
import './Custom.css'
import NotFound404 from './NotFound404';
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';

class VerifyEmail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      token: this.props.token,
      error: false
    }
    if(this.props.path === "/verify-updated-email/:token") {
      axios({
          method: 'get',
          url:
            APICONST.VERIFYUPDATEDEMAIL +
            this.state.token,
          headers: { 'content-type': 'application/json' },
          data: { token: this.state.token }
        })
        .then(response => {
            COMMONCONST.setCookieMessage(response.data.result.message);
            this.props.history.push("/");
          })
          .catch(error => {
            this.setState({error: true})
          })
    } else {
      if (this.state.token !== '') {
        axios({
          method: 'get',
          url:
            APICONST.VERIFYEMAIL +
            this.state.token,
          headers: { 'content-type': 'application/json' },
          data: { token: this.state.token }
        })
          .then(response => {
            if (
              typeof response.data.status !== 'undefined' &&
              response.data.status === 'OK'
            ) {
              if (
                typeof response.data.result.data !== 'undefined' &&
                response.data.result.data.email !== ''
              ) {
                localStorage.setItem('rememberMe', true)
                localStorage.setItem('email', response.data.result.data.email)
                localStorage.setItem('token', response.data.result.data.token)
                COMMONCONST.setCookieMessage(response.data.result.message);
                this.props.history.push("/");
              }
            }
          })
          .catch(error => {
            // this.setState({ error: error.message })
            // window.location = '/'
            // this.props.history.push("/");
            this.setState({ error: true })
          })
      }
    }
  }

  componentDidMount() {
    COMMONCONST.GAPageView();
  }

  render () {
    return (
      <Fragment>
        {/* <div className='verifyEmail'></div> */}
        {this.state.error ?
          <NotFound404 />
          : ''}
      </Fragment>
    )
  }
}

export default withRouter(VerifyEmail)
