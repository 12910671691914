import React, { Component } from "react";
import $ from "jquery";
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: ''
        }
        this.updateSearch = this.updateSearch.bind(this)
        this.changeSearch = this.changeSearch.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.updateSearchformMobile = this.updateSearchformMobile.bind(this)
    }
    updateSearch = e => {
        e.preventDefault()
        if (this.state.search) {
            this.props.updateSearch(this.state.search)
        }
        $('.submit-serach-enter').trigger('click');
        return false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.searchMobile !== this.state.search) {
            this.updateSearchformMobile(nextProps.searchMobile)

        }
    }

    updateSearchformMobile(search) {
        this.setState({ search: search })
    }

    changeSearch = e => {
        this.setState({ search: e.target.value }, () => {
            this.props.updateSearch(this.state.search)
        })
    }

    clearSearch() {
        this.setState({ search: '' }, () => {
            this.props.updateSearch(this.state.search)
        })
    }


    updateSort(sort) {
        this.props.updateSort(sort);
    }

    updateArchive(archive) {
        this.props.updateArchive(archive);
    }

    updateBrand(brand) {
        this.props.updateBrand(brand);
    }

    updateRelease(release) {
        this.props.updateRelease(release);
    }

    render() {
        $(document).ready(function () {
            $(".searchbox-mobile").focus(function () {
                $(".sub-hide").each(function (index) {
                    if ($(this).parents('.sidebox-active').length == 1) {
                        $(this).trigger('click')
                    }
                });
            });
        });
        return (
            <div id="sidebar" className="sidebar">
                {/* <div className="time-zones sidebox">
                    <h3 className="sidebar-label">TIME ZONE</h3>
                    <select name="" id="timezones">
                        <option value="">(GMT-5:00) Eastern Time</option>
                    </select>
                </div> */}
                <div className="serach-responsive d-block d-sm-block d-md-block d-lg-none">
                    <form className='form-inline' onSubmit={this.updateSearch}>
                        <input
                            className='form-control search-input-cntrl searchbox-mobile'
                            value={this.state.search}
                            onChange={this.changeSearch}
                            type='search'
                            placeholder='Search'
                            aria-label='Search'
                        />
                        {/* <button className='btn btn-close d-none' id="close-btn">
                        <i className='fa fa-close'></i>
                        </button> */}
                        {this.state.search !== '' ?
                            <button type="button" className='btn btn-close' onClick={this.clearSearch} id="close-btn">
                            </button>
                            : ''
                        }
                    </form>
                </div>
                <div className="sorting sidebox sidebox-active">
                    <h3 className="sidebar-label sub-hide">SORT</h3>
                    <ul className="ul-active">
                        <li className={this.props.sort == 'by_date' || this.props.sort == '' ? 'active' : ''}> <span onClick={() => this.updateSort('by_date')}> By Date </span></li>
                        <li className={this.props.sort == 'most_viewed' ? 'active' : ''}> <span onClick={() => this.updateSort('most_viewed')}> Most Viewed </span></li>
                        <li className={this.props.sort == 'most_liked' ? 'active' : ''}> <span onClick={() => this.updateSort('most_liked')}> Most Liked </span></li>
                        {/* <li className={this.props.sort == 'most_watchlist' ? 'active' : ''}> <Link to="#" onClick={() => this.updateSort('most_watchlist')}> Most Added to Watchlist </Link></li> */}
                        <li className={this.props.sort == 'recently_added' ? 'active' : ''}> <span onClick={() => this.updateSort('recently_added')}> Recently Added </span></li>
                    </ul>
                </div>
                <div className="by-brand sidebox sidebox-active">
                    <h3 className="sidebar-label sub-hide">BY Brand</h3>
                    <ul className="ul-active">
                        {/* <li className="has-sub-child"> <Link to="#"> Nike </Link>  */}
                        {/* <i className="fa fa-caret-up showmenu"></i> */}
                        {/* <ul className="sub-child">
                                <li><Link to="#">Basketball <i className="fa fa-caret-down showmenu"></i></Link></li>
                                <li><Link to="#">Lifestyle</Link></li>
                                <li><Link to="#">Air Max</Link></li>
                                <li><Link to="#">SB</Link></li>
                                <li><Link to="#">More <i className="fa fa-caret-down showmenu"></i></Link></li>
                            </ul> */}
                        {/* </li> */}
                        {this.props.brand.length > 0 ? <li> <span onClick={() => this.updateBrand("view_all")}> View All </span></li> : ''}
                        <li className={this.props.brand.indexOf('Nike') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("Nike")}> Nike </span></li>
                        <li className={this.props.brand.indexOf('Air Jordan') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("Air Jordan")}> Air Jordan </span></li>
                        <li className={this.props.brand.indexOf('adidas') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("adidas")}> adidas </span></li>
                        <li className={this.props.brand.indexOf('Reebok') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("Reebok")}> Reebok </span></li>
                        <li className={this.props.brand.indexOf('Asics') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("Asics")}> Asics </span></li>
                        <li className={this.props.brand.indexOf('Vans') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("Vans")}> Vans </span></li>
                        <li className={this.props.brand.indexOf('New Balance') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("New Balance")}> New Balance </span></li>
                        <li className={this.props.brand.indexOf('Saucony') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("Saucony")}> Saucony </span></li>
                        <li className={this.props.brand.indexOf('Converse') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("Converse")}> Converse </span></li>
                        <li className={this.props.brand.indexOf('Puma') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("Puma")}> Puma </span></li>
                        <li className={this.props.brand.indexOf('Under Armour') > -1 ? 'active' : ''}> <span onClick={() => this.updateBrand("Under Armour")}> Under Armour  </span></li>
                        {/* <li> <Link to="#"> View More </Link></li> */}
                    </ul>
                </div>
                {this.props.sort != '' ?
                    <div className={this.props.archive.length > 0 ? "by-release sidebox   disabled" : "by-release sidebox"}>
                        <h3 className="sidebar-label sub-hide">By Release</h3>
                        <ul>
                            <li className={this.props.release == 'view_all' ? 'active' : ''}> <span onClick={() => this.updateRelease("view_all")}> View All </span></li>
                            <li className={this.props.release == 'not_yes_released' ? 'active' : ''}> <span onClick={() => this.updateRelease("not_yes_released")} > Upcoming </span></li>
                            <li className={this.props.release == 'past_releases' ? 'active' : ''}> <span onClick={() => this.updateRelease("past_releases")}> Past Releases </span></li>
                        </ul>
                    </div>
                    : ''}
                {this.props.isWatchList !== 1 ?
                    <div className={this.props.release === 'not_yes_released' || this.props.release == 'past_releases' ? "archive sidebox disabled" : "archive sidebox"}>
                        <h3 className="sidebar-label sub-hide">archive<i className="fa fa-caret-down d-lg-inline-block d-xl-inline-block d-md-none d-sm-none d-xs-none d-none"></i></h3>
                        <ul>
                            {this.props.archive.length > 0 ? <li> <span onClick={() => this.updateArchive("view_all")}> View All </span></li> : ''}
                            <li className={this.props.archive.indexOf(2020) > -1 ? 'active' : ''}> <span onClick={() => this.updateArchive(2020)}> 2020 </span></li>
                            <li className={this.props.archive.indexOf(2019) > -1 ? 'active' : ''}> <span onClick={() => this.updateArchive(2019)}> 2019 </span></li>
                            <li className={this.props.archive.indexOf(2018) > -1 ? 'active' : ''}> <span onClick={() => this.updateArchive(2018)}> 2018 </span></li>
                            <li className={this.props.archive.indexOf(2017) > -1 ? 'active' : ''}> <span onClick={() => this.updateArchive(2017)}> 2017 </span></li>
                            <li className={this.props.archive.indexOf(2016) > -1 ? 'active' : ''}> <span onClick={() => this.updateArchive(2016)}> 2016 </span></li>
                        </ul>
                    </div>
                    : ''}
                <div className="filter-btn-div d-block d-md-block d-sm-block d-lg-none">
                    <button className="filter-btn submit-serach-enter">Apply filter</button>
                </div>

            </div>
        )
    }
}

export default Sidebar