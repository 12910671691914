
import React, { Component, Fragment } from "react";
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import { Link } from 'react-router-dom';
import he from 'he';
import $ from 'jquery';
import moment from 'moment-timezone';

class TwitterList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      totalLeft: 0,
      next: '',
      // click: 0,
      isLoading: false,
      width: window.innerWidth,
      height: window.innerHeight,
      autoplay: false,
      slidesToScroll: 1
    };
    this.getProducts = this.getProducts.bind(this);
    this.loadMoreNews = this.loadMoreNews.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.equalHeightCol = this.equalHeightCol.bind(this);
  }
  slidePrevious = () => {
    if (this.state.autoplay === false) {
      this.setState({
        autoplay: true,
        slidesToScroll: -1
      }, () => this.slider.slickPlay());
    }
  }
  slideNext = () => {
    if (this.state.autoplay === false) {
      this.setState({
        autoplay: true,
        slidesToScroll: 1
      }, () => this.slider.slickPlay());
    }
  }
  slidePause = () => {
    if (this.state.autoplay === true) {
      this.setState({
        autoplay: false,
        slidesToScroll: 1
      }, () => this.slider.slickPause())
    }
  }
  componentDidMount() {
    this.updateWindowDimensions();
    this.getProducts();
    window.addEventListener('resize', this.updateWindowDimensions);
    COMMONCONST.loadImage();
    this.handleSlides();
    this.equalHeightCol();
  }

  componentDidUpdate() {
    COMMONCONST.loadImage();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight }, () => {
      // this.getProducts();
    });
  }

  // getUpdateNews() {
  //   this.setState(prevState => ({
  //     click: prevState.click + 1
  //   }), () => {
  //     if (this.state.click % 25 === 0) {
  //       this.getProducts();
  //     }
  //   })
  // }

  loadMoreNews() {
    this.getProducts();
  }

  getProducts() {
    let page = this.state.page + 1;
    let per_page;
    let related_data;
    if (typeof (this.props.related_data !== undefined)) {
      related_data = this.props.related_data;
    }

    if (this.state.width >= 768) {
      per_page = 24;
    } else {
      per_page = 8;
    }

    this.setState({
      isLoading: true
    })
    axios({
      method: "get",
      url: APICONST.TwitterList,
      headers: { "content-type": "application/json" },
      params: {
        page: page,
        per_page: per_page,
        next: this.state.next
      }
    })
      .then(response => {
        if ((response.data.result.data.twiteLists) !== undefined) {
          let data;
          data = this.state.data;
          data = data.concat(response.data.result.data.twiteLists);
          let totalLeft = 0;
          // let meta = response.data.result.data.meta;
          // let totalLeft = meta.total - (meta.page * meta.perpage);
          // totalLeft = (totalLeft < 0) ? 0 : totalLeft;
          if (response.data.result.data.next) {
            totalLeft = 1;
          }
          this.setState({
            data: data,
            page: page,
            isLoading: false,
            next: response.data.result.data.next,
            totalLeft: totalLeft,
          });
          this.equalHeightCol();
        } else {
          this.setState({
            data: []
          });

        }

      })
      .catch(error => this.setState({ error: error.message, isLoading: false }));
  }

  showDate(date) {
    return moment(date).fromNow();
  }
  replaceLink(text, urls) {
    urls.forEach(element => {
      let link = `<a href='${element.expanded_url}' target='_blank'> ${element.display_url}</a>`;
      text = text.replace(element.url, link);
    });
    // console.log(text);
    return text.split('https://t.co')[0];
  }

  handleSlides() {
    let count = 0;
    $(document).on("mouseover", ".live-updates-blk .kf-slider-wrapper .slick-current", function () {
      count = 1;
      // $(".live-updates-blk .kf-slider-wrapper .previous-btn").trigger("click");
    });

    $(document).on("mouseover", ".live-updates-blk .kf-slider-wrapper .slick-current + .slick-active", function () {
      if (count === 1) {
        $(".live-updates-blk .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });

    $(document).on("mouseover", ".live-updates-blk .kf-slider-wrapper .slick-current + .slick-active + .slick-active", function () {
      if (count === 1) {
        $(".live-updates-blk .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });

    $(document).on("mouseover", ".live-updates-blk .kf-slider-wrapper .slick-current + .slick-active + .slick-active + .slick-active", function () {
      count = 1;
      // $(".live-updates-blk .kf-slider-wrapper .next-btn").trigger("click");
    });

    $(document).on("mouseleave", ".live-updates-blk .kf-slider-wrapper", function () {
      if (count === 1) {
        count = 0;
        $(".live-updates-blk .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });
  }

  equalHeightCol() {
    if ($(".live-updates-blk .latestnews-blk-cnt .product-item").length > 0) {
      var maxHeight = 0;
      var prodImageHeight = 0;
      $(".live-updates-blk .latestnews-blk-cnt .product-item").each(function () {
        if ($(this).height() > maxHeight) {
          maxHeight = $(this).height();
        }
        if ($(this).find(".prod-images").height() > prodImageHeight) {
          prodImageHeight = $(this).find(".prod-images").height();
        }
      });
      $(".live-updates-blk .latestnews-blk-cnt .product-item").css('min-height', maxHeight);
      $(".live-updates-blk .latestnews-blk-cnt .product-item .prod-details").css('min-height', maxHeight - prodImageHeight);
    } else {
      setTimeout(this.equalHeightCol, 2000);
    }
  }

  render() {

    if (this.state.data.length > 0) {
      const slides = this.state.data.map((item, key) =>
        <div key={key} className="product-item">
          {/* <Link target="_blank" to={'/' + item.slug} className="product-img"><img src={item.product_thumbnail} alt="img" /></Link> */}
          <a href={COMMONCONST.TWITTER_REDIRECT_URL + item.id} target="_blank">
            <div className={`prod-images has-${item.media.length}-image`}>

              {item.media.map((imageURL, key) =>
                <div key={key} className={`prod-image-${key}`}>
                  <img src={imageURL} alt={`prod-thumb-${key}`} />
                </div>
              )}
            </div>
          </a>
          <div className="prod-details">
            <h4 className="prod-name">
              {/* {he.decode(item.text)} */}
              <a href={COMMONCONST.TWITTER_REDIRECT_URL + item.id} target="_blank">
                <pre dangerouslySetInnerHTML={{ __html: he.decode(this.replaceLink(item.text, item.urls)) }}>
                </pre>
              </a>
            </h4>
            {/* <h2 className="prod-price">{item.price}</h2> */}
            <span className="post-time">{this.showDate(item.created_at)}</span>
          </div>
        </div>
      );
      if (this.state.width >= 768) {
        var settings = {
          nav: true,
          dots: false,
          infinite: this.state.data.length > 4 ? true : false,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 4,
          autoplay: this.state.autoplay,
          autoplaySpeed: 1500,
          arrows: true,
          adaptiveHeight: false,
          pauseOnHover: false,
          cssEase: this.state.autoplay ? 'linear' : 'ease-in-out',
          afterChange: (slideCount) => {
            if (slideCount % 20 === 0) {
              this.getProducts();
            }
          },
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              }
            }
          ]
        };
        return (
          <Fragment>
            <div className="latestnews-wrapper">
              <div className="product-list-header">
                <div className="prod-list-title-wrap">
                  <h4 className="large-main-title">{this.props.title}</h4>
                  <span className="sub-title">Make sure to follow <a href={COMMONCONST.TWITTER} target="_blank" rel="noopener noreferrer">@kicksfinder</a> for live tweets during the release Date</span>
                </div>
              </div>
              {/* <CarouselSlider slideItems = {data} buttonSetting = {buttonSetting}  sliderBoxStyle= {sliderBoxStyle} accEle = {accEle} itemsStyle = {itemsStyle}/>  */}
              <div className="kf-slider-wrapper">
                <button className="button-slide next-btn" onClick={this.slideNext}>Next</button>
                <button className="button-slide pause-btn" onClick={this.slidePause}>pause</button>
                <button className="button-slide previous-btn" onClick={this.slidePrevious}>previous</button>
                <Slider {...settings} ref={c => (this.slider = c)} autoplay={this.state.autoplay}>{slides}</Slider>
              </div>
            </div>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <div className="latestnews-wrapper">
              <div className="prod-list-title-wrap">
                <h4 className="large-main-title">{this.props.title}</h4>
              </div>
              <div className="latestnews-blk-cnt">
                {slides}
                {this.state.totalLeft !== 0 ?
                  <div className="product-item view-more-btn-item">
                    <button id="loadAvailableNow" className={this.state.isLoading ? "fullbtn view-more is-loading" : "fullbtn view-more"} onClick={this.loadMoreNews}>
                      VIEW MORE {/* <span className="count">({this.state.totalLeft})</span> */} <i className="fa fa-spinner loadicon"></i>
                    </button>
                  </div> : ''}
              </div>
            </div>
          </Fragment>
        )
      }
    } else {
      return (
        <Fragment>
          {this.state.isLoading == true ?
            <div className={this.state.isLoading ? "latestnews-wrapper slider-placeholder" : "latestnews-wrapper"}>
              <div className="product-list-header">
                <div className="prod-list-title-wrap">
                  <h4 className="large-main-title"></h4>
                </div>
              </div>
              <div className="product-item-wrap">
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
              </div>
            </div>
            : ''}
        </Fragment>
      )
    }
  }
}

export default TwitterList