import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import * as APICONST from '../constants/Api';
import axios from 'axios'


class RecentlyAdded extends Component {
    constructor() {
        super()
        this.state = {
            data: [],
            isLoading: false,
        }
    }

    componentDidMount() {

        this.setState({
            isLoading: true
        });
        axios({
            method: 'get',
            url: APICONST.RECENTLY_ADDED,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
            .then(response => {
                this.setState({ isLoading: true })
                console.log(response.data.result.data);
                if (response.data.result.data) {
                    this.setState({ data: response.data.result.data });
                    this.setState({ isLoading: false });
                }
            })
            .catch(error => {
                this.setState({ isLoading: false });
            })
    }

    render() {

        const productBlock = (item, key) => {
            return <div key={key} className='prod-list-new'>
                <div className="item-list-time">
                    <div className="item-uploaded">{item.uploaded}</div>
                </div>
                <div className="item-image">
                    <Link target="_blank" to={'/' + item.slug}>
                        <img src={item.product_thumbnail} alt="Thumb" />
                    </Link>
                </div>
                <div className="item-name">
                    <Link target="_blank" to={'/' + item.slug}>
                        {item.product_name} {item.product_nickname != '' ? <span className="item-nickname"> "{item.product_nickname}" </span> : ''}
                    </Link>
                </div>
            </div>
        };
        return (
            <div className="popular-product-inner-blk">
                <h3 className="prod--main-title">Recently Added</h3>
                { this.state.isLoading == true ?
                    <div className={this.state.isLoading == true ? "popular-product-inner-lisitng most-pop-placeholder is-loading" : "popular-product-inner-lisitng most-pop-placeholder"}>
                        <div className="prod-list-new">
                            <div className="item-list-time"></div>
                            <div className="item-image"></div>
                            <div className="item-name"></div>
                        </div>
                        <div className="prod-list-new">
                            <div className="item-list-time"></div>
                            <div className="item-image"></div>
                            <div className="item-name"></div>
                        </div>
                        <div className="prod-list-new">
                            <div className="item-list-time"></div>
                            <div className="item-image"></div>
                            <div className="item-name"></div>
                        </div>
                        <div className="prod-list-new">
                            <div className="item-list-time"></div>
                            <div className="item-image"></div>
                            <div className="item-name"></div>
                        </div>
                        <div className="prod-list-new">
                            <div className="item-list-time"></div>
                            <div className="item-image"></div>
                            <div className="item-name"></div>
                        </div>
                    </div>
                    :
                    <div className={this.state.isLoading == true ? "popular-product-inner-lisitng  is-loading" : "popular-product-inner-lisitng"}>
                        {this.state.data.map((item, key) =>
                            productBlock(item, key)
                        )}
                    </div>

                }

            </div>
        )
    }
}

export default RecentlyAdded