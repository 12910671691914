import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import Breadcrumb from "./Breadcrumb";
import './store-locator.css';
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import axios from 'axios';
import $ from 'jquery'

class StoreLocatorDirectory extends Component {
	constructor(props) {
        super(props)

        this.state = {
            storeDirectory: [],
            isLoading: false,
            isError: false,

        };

    }

	getStoreDirectory(isLoadMore = false) {
        
        this.setState({
            isLoading: true
        })
		console.log(APICONST.StoreDirectory);
        axios({
            method: "get",
            url: APICONST.StoreDirectory,
            headers: { "content-type": "application/json" },
            params: {
				perpage: 100
            }
        })
            .then(response => {
                COMMONCONST.GAPageView();
                this.setState({
                    isLoading: false,
                    storeDirectory: response.data.result.data
                });
				console.log(response.data.result.data);

            })
            .catch(error => this.setState({ isError: true, isLoading: false }));

    }

	componentDidMount() {
		this.getStoreDirectory();
	}
	render() {

		const breadcrumb = [
			{ name: 'KICKSFINDER', url: '/' },
			{ name: 'STORE LOCATOR', url: '/store-locator' },
			{ name: 'STORE DIRECTORY', url: '' },
		];
		let title = 'Kicks Finder - Sneaker Store Locator';
		document.title = title
		$('#og-title').attr('content', title);
		let description = "Kickfinder's Sneaker Store Locator is a comprehensive database of sneaker retailers across the United States. Easily search for stores by name or location and view a list of sneakers available at each store.";
		$('#meta-description').attr('content', description);
		$('#og-description').attr('content', description);
		return (
			<div className='App'>
				<Breadcrumb breadcrumb={breadcrumb} />
				{this.state.isLoading}
				<div className={this.state.isLoading ? "store-locator store-locator-directory sticky-loader-active" : "store-locator store-locator-directory"}>
				{this.state.isLoading ? (<div className="sticky-loader-wrap"><span className="sticky-loader-span"></span></div>) : ''}
					<div className="container padd-large">
						<div className="store-locator-banner">
							<h1 className="main-heading">Store Locator</h1>
							<p className="sub-heading"><Link to="/store-locator" className="back-link">BACK TO SEARCH BY LOCATION</Link></p>
						</div>	
						<div className="store-locator-directorys-listing">
							<ul className="store-locator-directory__row">
								{this.state.storeDirectory.data && this.state.storeDirectory.data.map((store) =>
									<li className="store-locator-directory__item" key={store.id}>
										<Link to={'/store-locator/store-directory/' + store.state_province}>{store.state_province} <span className="count">({store.count})</span></Link>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(StoreLocatorDirectory)