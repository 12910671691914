import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';

class StoreLocatorInfoBox extends Component {
	constructor(props) {
        super(props)
		// console.log('StoreLocatorInfoBox', props.store?.name);
	}
	
	render() {
		const getCurrentWeekdayName = () => {
			const date = new Date();
			const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			const currentDay = date.getDay();
			let currentDayName = days[currentDay];
			const weekdayTextArray = this.props?.store?.weekday_text || '[]';
			let weekDayArray = JSON.parse(weekdayTextArray);
			// console.log((weekDayArray));
			if( typeof (weekDayArray) === 'object' && weekDayArray.length > 0) {
				// weekDayArray.forEach(element => {
				// 	console.log(element, 'element');
				// });
				const currentDayText = weekDayArray.find(text => text.includes(currentDayName));
				return currentDayText.replace(currentDayName, 'Open today');
			} else {
				return '';
			}

		  };
		  
		return (
			<div className="store-locator-listing__item">
				<Link to={'/store-locator/store-directory/store/' + this.props?.store?.slug} className="hidden-link">STORE INFO</Link>
				<h4>{this.props?.store?.name}</h4>
				<p className="address">{this.props?.store?.address}</p>
				<p className="tel">{this.props?.store?.phone}</p>
				<p className="time">{getCurrentWeekdayName()}</p>
				<div className="btn-wrap">
					{/* <a href={'/store-locator/store-directory/' + this.props?.store?.state_province + '/' + this.props?.store?.id} className="btn">STORE INFO</a> */}
					<Link to={'/store-locator/store-directory/store/' + this.props?.store?.slug} className="btn">STORE INFO</Link>
				</div>
			</div>
		)
	}
}

export default StoreLocatorInfoBox