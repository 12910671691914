import React, { Component } from "react";
import $ from 'jquery'
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import axios from 'axios';
import * as APICONST from '../constants/Api';
// import * as COMMONCONST from '../constants/Common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Fragment } from "react";

class AppleLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.token === 'error') {
            toast.error('Somthing went wront Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        } else {
            this.getData();
        }
    }

    getData() {
        axios({
            method: "post",
            url: APICONST.LOGIN,
            headers: { "content-type": "application/json" },
            data: { token: this.props.token }
        })
            .then(response => {
                if (response.data.result.message !== ' ') {
                    $('.userSignInMsg').html(`<p>${response.data.result.message}</p>`)
                    this.setState({
                        errors: {
                            error_message: response.data.result.message
                        },
                        isLoading: false
                    })
                    localStorage.setItem('rememberMe', true)
                    localStorage.setItem('email', response.data.result.data.email)
                    localStorage.setItem('token', response.data.result.data.token)
                    window.location = '/'
                } else {
                    this.setState({ error: false, isLoading: false })
                }
            })
            .catch(error => {
                toast.error('Somthing went wront Please try again', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            });
    }

    render() {
        return (
            <div id="main_content" className="main_content wrapp apple-login-wrap loader-active">
                <ToastContainer />
            </div>
        )
    }
}

export default AppleLogin