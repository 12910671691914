import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import ReleaseSoon from './ReleasingSoon'
import * as COMMONCONST from '../constants/Common';


class NotFound404 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: ''
        }

        this.submitSearch = this.submitSearch.bind(this)
        this.changeSearch = this.changeSearch.bind(this)

    }

    submitSearch = e => {
        e.preventDefault()
        this.props.history.push("/?search=" + this.state.search);
    }

    changeSearch = e => {
        this.setState({ search: e.target.value })
    }

    componentDidMount() {
        COMMONCONST.GAPageView();
    }

    render() {
        return (
            <div className='App'>
                <div className="page-404">
                    <div className="container">
                        <div className="wrapper-404">
                            <div className="cnt-wrapper-404">
                                <div className="top-wrap-404">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <span className="label-404">404</span>
                                        </div>
                                        <div className="col-lg-8">
                                            <h2 className="title-404">We couldn’t find the page you’re looking for,<br />or the link you have followed might be expired</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="search-wrap-404">
                                    <div className="row">
                                        <div className="col-lg-6 offset-lg-3 offset-xl-3">
                                            <div className="search-404-control">
                                                <form onSubmit={this.submitSearch}>
                                                    <input className="form-control" value={this.state.search}
                                                        onChange={this.changeSearch} type="search" placeholder="Search Kicksfinder" aria-label="Search Kicksfinder" />
                                                    <button className="btn btn-search" type="submit"><i className="fa fa-search"></i></button>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="search-below-cnt">
                                        <div className="row">
                                            <h4 className="search-title">Trending Sneakers</h4>
                                        </div>

                                        <div>
                                            <ReleaseSoon type='not-found' />
                                            {/* <ul className="allproducts">
                                                        {this.state.products.map((item, key) =>
                                                            productBlock(item, key)
                                                        )}
                                                    </ul>
                                                    <div className="search-more-btn">
                                                        <button className="fullbtn view-more">VIEW MORE<i className="fa fa-spinner loadicon"></i></button>
                                                    </div> */}
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(NotFound404);