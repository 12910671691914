import React, { Component } from "react";
import $ from 'jquery';
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import * as COMMONCONST from '../constants/Common';


class TermsAndConditions extends Component {

    componentDidMount() {
        COMMONCONST.GAPageView();
    }
    render() {
        $(document).ready(function (d, s, id) { var js, tjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = "https://app.termly.io/embed-policy.min.js"; tjs.parentNode.insertBefore(js, tjs); }(document, 'script', 'termly-jssdk'));
        return (
            <div className='App'>
                <div className="cms-page terms-privacy-main">
                    <div className="container">
                        <div name="termly-embed" data-id="fcae639c-ae42-49f0-a946-f3b26ea02e65" data-type="iframe"></div>
                    </div>
                    {/* <ProductDetail></ProductDetail> */}
                </div>

            </div>
        )
    }
}

export default TermsAndConditions