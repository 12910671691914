import React, { Fragment } from 'react'
// import logo from './logo.svg';
import { BrowserRouter as Router } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
// import Header from '../components/Header'
// import Footer from '../components/Footer'
import { GoogleLogout } from 'react-google-login'
import $ from 'jquery'
import axios from 'axios'
import '../App.css'
import '../assets/fonts/fonts.css'
import '../assets/css/font-awesome.min.css'
import './Custom.css'
import * as COMMONCONST from '../constants/Common';
import * as APICONST from '../constants/Api';

class UserSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // gapi:
      //   '775972925012-36psac8ci0nqc8ilulr3ah569vtjb3e0.apps.googleusercontent.com',
      // userDetails: {},
      // isUserLoggedIn: localStorage.getItem('token') ? true : false,
      // isGoogleSignin: localStorage.getItem("isGoogleSignin") != null ? true : false,
      isLoading: false,
      isGeneralEdit: false,
      // search: '',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      id: '',
      first_name: '',
      last_name: '',
      update_first_name: '',
      first_name_bkp: '',
      update_last_name: '',
      last_name_bkp: '',
      email: '',
      is_password: '',
      new_email: '',
      confirm_email: '',
      password: '',
      confirm_password: '',
      current_password: '',
      news_letter: false,
      time_zone: '',
      timeZoneList: [],
      success_message: '',
      resendEmailMessage: '',
      isEmailUpdated: false,
      errors: {
        first_name: '',
        last_name: '',
        email: '',
        new_email: '',
        confirm_email: '',
        password: '',
        current_password: '',
        confirm_password: '',
        time_zone: '',
        news_letter: ''
      },
      tagError: false
    }

    // this.updateSearch = this.updateSearch.bind(this)
    this.updateData = this.updateData.bind(this)
    this.resendEMail = this.resendEMail.bind(this)
    this.updateEmailState = this.updateEmailState.bind(this)
    this.redirectLink = this.redirectLink.bind(this)
  }

  // updateSearch (search) {
  //   this.setState({ search: search })
  // }

  componentDidMount() {
    COMMONCONST.GAPageView();
    // const rememberMe = localStorage.getItem('rememberMe')
    // const email = rememberMe ? localStorage.getItem('email') : ''
    const token = this.state.token

    if (token !== '') {
      axios({
        method: 'get',
        url: APICONST.TIMEZONELIST,
        headers: { 'content-type': 'application/json' }
      })
        .then(response => {
          if (
            typeof response.data.status !== 'undefined' &&
            response.data.status === 'OK'
          ) {
            if (typeof response.data.result.timeZoneList !== 'undefined') {
              this.setState({
                timeZoneList: response.data.result.timeZoneList
              })
            }
          }
        })
        .catch(error => {
          this.setState({ error: error.message })
        })

      axios({
        method: 'get',
        url:
          APICONST.GETPROFILEDETAILS + '?token=' + token,
        headers: { 'content-type': 'application/json' }
      })
        .then(response => {
          if (
            typeof response.data.status !== 'undefined' &&
            response.data.status === 'OK'
          ) {
            if (typeof response.data.result.data !== 'undefined') {
              this.setState({
                id: response.data.result.data.id,
                email: response.data.result.data.email,
                first_name: response.data.result.data.first_name,
                last_name: response.data.result.data.last_name,
                update_first_name: response.data.result.data.first_name,
                update_last_name: response.data.result.data.last_name,
                news_letter: response.data.result.data.news_letter,
                time_zone: response.data.result.data.time_zone,
                is_password: response.data.result.data.is_password
              })
            }
          }
        })
        .catch(error => {
          this.setState({ error: error.message })
        })
    } else {
      window.location = '/'
    }
  }

  updateEmailState() {
    if (this.state.isEmailUpdated) {
      this.setState({ isEmailUpdated: false })
    }
    this.revertEmail();
  }
  resendEMail() {
    axios({
      method: 'get',
      url: APICONST.RESENDUPDATEDEMAIL,
      headers: { 'content-type': 'application/json' }
    })
      .then(response => {
        this.setState({ resendEmailMessage: response.data.result.message });
      })
      .catch(error => {
        this.setState({ error: error.message })
      })
  }

  updateData() {
    const token = this.state.token
    axios({
      method: 'get',
      url:
        APICONST.GETPROFILEDETAILS + '?token=' + token,
      headers: { 'content-type': 'application/json' }
    })
      .then(response => {
        if (
          typeof response.data.status !== 'undefined' &&
          response.data.status === 'OK'
        ) {
          if (typeof response.data.result.data !== 'undefined') {
            this.setState({
              id: response.data.result.data.id,
              email: response.data.result.data.email,
              first_name: response.data.result.data.first_name,
              last_name: response.data.result.data.last_name,
              update_first_name: response.data.result.data.first_name,
              update_last_name: response.data.result.data.last_name,
              news_letter: response.data.result.data.news_letter,
              time_zone: response.data.result.data.time_zone,
              is_password: response.data.result.data.is_password
            })
          }
        }
      })
      .catch(error => {
        this.setState({ error: error.message })
      })
  }

  deleteAccount = () => {
    this.setState({
      isLoading: true
    });
    axios({
      method: 'post',
      url:
        APICONST.DELETEACCOUNT + '?token=' +
        this.state.token,
      headers: { 'content-type': 'application/json' }
    })
      .then(response => {
        if (
          typeof response.data.status !== 'undefined' &&
          response.data.status === 'OK'
        ) {
			$('.modal .close').trigger('click')
			this.setState({
         	 success_message: 'Account Deleted Successfully'
       		});
			setTimeout(() => {
				{this.props.isGoogleSignin ? $('.logout-head').trigger('click') : this.props.logout()}
			}, 2000);
        }
      })
      .catch(error => {
        this.setState({ error: error.message })
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };


  updateName = () => {
    let first_name = this.state.update_first_name
    let last_name = this.state.update_last_name

    this.setState({
      success_message: ''
    })

    this.validateName();
    if (
      this.state.update_first_name !== '' &&
      this.state.update_last_name !== '' &&
      this.state.tagError !== true
    ) {
      this.updateProfile({ first_name: first_name, last_name: last_name })
    }



  }

  validateName = () => {
    let first_name = this.state.update_first_name
    let last_name = this.state.update_last_name
    let tagErrors = this.state.errors
    let isError = false;

    const ValidName = RegExp(
      /^[a-zA-Z0-9 ]*$/i
    )

    if ('' === first_name) {
      tagErrors.first_name = 'Please enter your first name';
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (first_name && ValidName.test(first_name) === false) {
      tagErrors.first_name = 'First name not allow special characters'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (first_name.length < 2) {
      tagErrors.first_name = 'First name should contains minimum 2 characters'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.first_name = ''
      this.setState({
        tagErrors,
        tagError: false
      })
    }

    if ('' === last_name) {
      tagErrors.last_name = 'Please enter your last name'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (last_name && ValidName.test(last_name) === false) {
      tagErrors.last_name = 'Last name not allow special characters'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (last_name.length < 2) {
      tagErrors.last_name = 'Last name should contains minimum 2 characters'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.last_name = ''
      this.setState({
        tagErrors,
        tagError: false
      })
    }

    if (isError === true) {
      return false;
    }
  }

  updateEmail = () => {
    this.validateKicksPass();
    this.validateNewEmail();
    this.validateConfirmEmail();
    this.setState({
      success_message: ''
    })
    console.log(this.state.tagError);
    if ((
      this.state.new_email !== '' &&
      this.state.confirm_email !== '' &&
      this.state.current_password !== '') || (this.state.is_password === false && this.state.new_email !== '' &&
        this.state.confirm_email !== '')
    ) {
      this.updateProfile({
        email: this.state.new_email,
        confirm_email: this.state.confirm_email,
        current_password: this.state.current_password,
        isemail: true
      })
    }
    if (this.state.tagError === true) {
      return false
    }
  }

  validateKicksPass = () => {
    let current_password = this.state.current_password
    let tagErrors = this.state.errors

    if (this.state.is_password) {
      if ('' === current_password) {
        tagErrors.current_password = 'Please enter a password'
        this.setState({
          tagErrors,
          tagError: true
        })
      } else if (current_password && current_password.length < 6) {
        tagErrors.current_password = 'Password too short'
        this.setState({
          tagErrors,
          tagError: true
        })
      } else {
        tagErrors.current_password = ''
        this.setState({
          tagErrors,
          tagError: true
        })
      }
    }
  }

  validateNewEmail = () => {
    let new_email = this.state.new_email

    let tagErrors = this.state.errors
    let emailcheck = new_email.split("@");
    let emailFirst = emailcheck[0];
    let emailSecond = '';

    if (emailcheck[1]) {
      emailSecond = emailcheck[1].split(".")[0];
    }

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    )

    if ('' === new_email) {
      tagErrors.new_email = 'Please enter an email address'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (new_email && validEmailRegex.test(new_email) === false) {
      tagErrors.new_email = 'Email is not valid'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (!emailFirst || emailFirst.length <= 1 || !emailSecond || emailSecond.length <= 1) {
      tagErrors.new_email = 'Email should be minimum 2 characters before and after @ sign'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.new_email = ''
      this.setState({
        tagErrors,
        tagError: true
      })
    }
  }

  validateConfirmEmail = () => {

    let confirm_email = this.state.confirm_email
    let new_email = this.state.new_email
    let tagErrors = this.state.errors

    if ('' === confirm_email) {
      tagErrors.confirm_email = 'Please enter confirm email address'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (confirm_email !== new_email) {
      tagErrors.confirm_email = 'Email does not match'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.confirm_email = ''
      this.setState({
        tagErrors,
        tagError: true,
        // email: confirm_email
      })
    }
  }

  updatePassword = () => {

    this.setState({
      success_message: ''
    })

    this.validateCurrentPass();
    this.validateNewPass();
    this.validateConfirmPass();

    if ((
      this.state.confirm_password !== '' &&
      this.state.password !== '' &&
      this.state.current_password !== '') || (this.state.confirm_password !== '' &&
        this.state.password !== '' &&
        this.state.is_password === false)
    ) {
      this.updateProfile({
        current_password: this.state.current_password,
        password: this.state.password,
        confirm_password: this.state.confirm_password
      })
    }

    if (this.state.tagError === true) {
      return false
    }



  }

  validateCurrentPass = () => {
    let current_password = this.state.current_password

    let tagErrors = this.state.errors
    if (this.state.is_password) {
      if ('' === current_password) {
        tagErrors.current_password = 'Please enter a current password'
        this.setState({
          tagErrors,
          tagError: true
        })
      } else {
        tagErrors.current_password = ''
        this.setState({
          tagErrors,
          tagError: true
        })
      }
    }

  }

  validateNewPass = () => {
    let password = this.state.password

    const validPasswordRegexNumber = RegExp(
      /\d+/i
    )
    const validPasswordRegexAlphabet = RegExp(
      /[a-zA-Z]/i
    )

    let tagErrors = this.state.errors

    if ('' === password) {
      tagErrors.password = 'Please enter a password'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (password && password.length <= 7) {
      tagErrors.password = 'Password should be alphanumeric and Minimum 8 characters long'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (password && (validPasswordRegexNumber.test(password) === false || validPasswordRegexAlphabet.test(password) === false)) {
      tagErrors.password = 'Password should be alphanumeric and Minimum 8 characters long'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.password = ''
      this.setState({
        tagErrors,
        tagError: true
      })
    }

  }

  validateConfirmPass = () => {
    let password = this.state.password
    let confirm_password = this.state.confirm_password
    let tagErrors = this.state.errors

    if ('' === confirm_password) {
      tagErrors.confirm_password = 'Please enter a confirm password'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (confirm_password !== password) {
      tagErrors.confirm_password = 'Password does not match'
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.confirm_password = ''
      this.setState({
        tagErrors,
        tagError: true
      })
    }
  }

  updateRest = () => {
    let Timezone = this.state.time_zone
    let newsletter = this.state.news_letter
    this.setState({
      success_message: ''
    })
    if (
      this.state.time_zone !== '' &&
      this.state.news_letter !== ''
    ) {
      this.updateProfile({ time_zone: Timezone, news_letter: newsletter })
    }

  }

  updateProfile = data => {

    if (data.time_zone !== undefined) {
      this.setState({
        isLoading: true,
        isGeneralEdit: true
      });
    } else {
      this.setState({
        isLoading: true
      });
    }

    axios({
      method: 'post',
      url:
        APICONST.UPDATEPROFILEDETAILS + '?token=' +
        this.state.token,
      headers: { 'content-type': 'application/json' },
      data: data
    })
      .then(response => {
        if (
          typeof response.data.status !== 'undefined' &&
          response.data.status === 'OK'
        ) {
          this.updateData();
          if (!data.isemail) {
            this.setState({
              success_message: response.data.result.message,
              current_password: ''
            })
            $('.modal .close').trigger('click')
          } else {
            this.setState({ isEmailUpdated: true })
          }
        }
        this.setState({
          isLoading: false,
          isGeneralEdit: false
        });
      })
      .catch(error => {
        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data.result.error.email !== 'undefined'
        ) {
          this.setState({
            errors: {
              email: error.response.data.result.error.email,
              new_email: error.response.data.result.error.email,
            }
          })
        }

        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data.result.error.current_password !==
          'undefined'
        ) {
          this.setState({
            errors: {
              current_password:
                error.response.data.result.error.current_password
            }
          })
        }

        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data.result.error.confirm_email !== 'undefined'
        ) {
          this.setState({
            errors: {
              confirm_email: error.response.data.result.error.confirm_email
            }
          })
        }

        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data.result.error.password !== 'undefined'
        ) {
          this.setState({
            errors: {
              password: error.response.data.result.error.password
            }
          })
        }

        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data.result.error.confirm_password !==
          'undefined'
        ) {
          this.setState({
            errors: {
              confirm_password:
                error.response.data.result.error.confirm_password
            }
          })
        }

        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data.result.error.news_letter !== 'undefined'
        ) {
          this.setState({
            errors: {
              news_letter: error.response.data.result.error.news_letter
            }
          })
        }

        if (
          typeof error.response !== 'undefined' &&
          typeof error.response.data.result.error.time_zone !== 'undefined'
        ) {
          this.setState({
            errors: {
              time_zone: error.response.data.result.error.time_zone
            }
          })
        }

        this.setState({
          isLoading: false,
          isGeneralEdit: false
        });
      });
  }

  redirectLink(e) {
    e.preventDefault()
    const path = e.target.href
    if (path) {
      window.location = path
    } else {
      window.location = '/'
    }
  }

  revertName = () => {
    let tagErrors = this.state.errors;
    tagErrors.first_name = '';
    tagErrors.last_name = '';
    this.setState({
      update_first_name: this.state.first_name_bkp,
      update_last_name: this.state.last_name_bkp,
      tagErrors: '',
      tagError: true
    })
  }

  revertEmail = () => {
    let tagErrors = this.state.errors;
    tagErrors.current_password = '';
    tagErrors.new_email = '';
    tagErrors.confirm_email = '';
    this.setState({
      current_password: '',
      new_email: '',
      confirm_email: '',
      tagErrors,
      tagError: true
    })
  }

  revertPassword = () => {
    let tagErrors = this.state.errors;
    tagErrors.current_password = '';
    tagErrors.password = '';
    tagErrors.confirm_password = '';
    this.setState({
      current_password: '',
      password: '',
      confirm_password: '',
      tagErrors,
      tagError: true
    })
  }

  render() {
    let closeIcon = (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 2.60308L21.3969 0L12 9.39692L2.60308 0L0 2.60308L9.39692 12L0 21.3969L2.60308 24L12 14.6031L21.3969 24L24 21.3969L14.6031 12L24 2.60308Z" fill="black" /></svg>);

    // const responseGoogle = response => {
    //   if (response.profileObj) {
    //     this.setState({
    //       userDetails: response.profileObj,
    //       isUserLoggedIn: true,
    //       isGoogleSignin: true
    //     })
    //     if (this.state.isUserLoggedIn) {
    //       $('.modal, .modal-backdrop').hide()
    //       $('body')
    //         .removeClass('modal-open')
    //         .removeAttr('style')
    //     }
    //   }
    // }

    // const logout = () => {
    //   this.setState({ isUserLoggedIn: false })
    //   this.setState({ isGoogleSignin: false })
    //   localStorage.setItem('rememberMe', false)
    //   localStorage.removeItem("isGoogleSignin");
    //   localStorage.removeItem('email')
    //   localStorage.removeItem('token')
    //   $('#signupModal').hide()
    //   window.location = '/'
    // }
    return (
      <Router>
        <div className='App'>
          {/* <Header
            responseGoogle={responseGoogle}
            logout={logout}
            {...this.state}
            updateSearch={this.updateSearch}
          /> */}
          <div className='usersetting-wrapper'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 offset-lg-3'>
                  <h4 className='user-title'>User Settings <Link to="/" className='user-close-icon' onClick={this.redirectLink}>{closeIcon}</Link></h4>
                  <div className='user-setting-blk'>
                    <div className='user-setting-row username-blk'>
                      <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-4 col-12 user-col-left'>
                          <label className='user-set-lbl'>User Name</label>
                        </div>
                        <div className='col-lg-8 col-md-8 col-sm-8 col-12 text-right user-col-right'>
                          <span className='user-set-span'>
                            {this.state.first_name ? this.state.first_name : ''}{' '}
                            {this.state.last_name ? this.state.last_name : ''}
                          </span>
                          <span
                            className='edittxt'
                            data-toggle='modal'
                            data-target='#useredit'
                            onClick={e => {
                              this.setState({
                                first_name_bkp: this.state.update_first_name,
                                last_name_bkp: this.state.update_last_name
                              })
                            }}
                          >
                            Edit
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='user-setting-row useremail-blk'>
                      <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-4 col-12 user-col-left'>
                          <label className='user-set-lbl'>Email</label>
                        </div>
                        <div className='col-lg-8  col-md-8 col-sm-8 col-12 text-right user-col-right'>
                          <span className='user-set-span'>
                            {this.state.email ? this.state.email : ''}
                          </span>
                          <span
                            className='edittxt'
                            data-toggle='modal'
                            data-target='#emailedit'
                            onClick={this.updateEmailState}
                          >
                            Edit
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='user-setting-row userpassword-blk'>
                      <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-4 col-12 user-col-left'>
                          <label className='user-set-lbl'>Password</label>
                        </div>
                        <div className='col-lg-8 col-md-8 col-sm-8 col-12 text-right user-col-right'>
                          <span className='user-set-span'>******</span>
                          <span
                            className='edittxt'
                            data-target='#passwordedit'
                            data-toggle='modal'
                            onClick={this.revertPassword}
                          >
                            Edit
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='user-setting-row default-timezone'>
                      <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-4 col-12 user-col-left'>
                          <label className='user-set-lbl'>
                            Default Timezone
                          </label>
                        </div>
                        <div className='col-lg-8 col-md-8 col-sm-8 col-12 text-right user-col-right'>
                          <div className="user-setting-select">
                            <select
                              name=''
                              id='timezones'
                              className='cst-select'
                              value={this.state.time_zone}
                              onChange={e =>
                                this.setState({ time_zone: e.target.value })
                              }
                            >
                              {this.state.timeZoneList &&
                                Object.keys(this.state.timeZoneList).map(key => (
                                  <option key={key}
                                    value={key}
                                  // selected={this.state.time_zone == key}
                                  >
                                    {this.state.timeZoneList[key]}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='user-setting-row'>
                      <div className='row'>
                        <div className='col-lg-10 col-md-10 col-sm-8 col-8'>
                          <label className='user-set-lbl'>Newsletter</label>
                          <span className='span-newsletter'>
                            {' '}
                            Weekly reminder on the most popular sneakers
                          </span>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-4 col-4 text-right'>
                          <div className="toggle-switch">
                            <input
                              className=''
                              id='cb1'
                              type='checkbox'
                              checked={this.state.news_letter}
                              onChange={e =>
                                this.setState({
                                  news_letter: !this.state.news_letter
                                })
                              }
                            />
                            <label htmlFor="cb1"> </label>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className='user-setting-row'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='button-row text-lg-right text-md-right text-sm-center text-center'>
                            {this.props.isGoogleSignin ?
                              <Link className='nav-link google' to='#'>
                                <GoogleLogout
                                  className='logout-head'
                                  clientId={this.props.gapi}
                                  buttonText='Log Out'
                                  onLogoutSuccess={this.props.logout}
                                  onFailure={this.props.logout}
                                  isSignedIn={false}
                                ></GoogleLogout>
                              </Link>
                              :
                              <button className='btn-logout' onClick={this.props.logout}>
                                <i className="fa fa-spinner loadicon"></i>
                              Log Out
                            </button>}
                            <button
                              className={this.state.isLoading && this.state.isGeneralEdit ? 'btn-setting is-loading' : 'btn-setting'}
                              onClick={this.updateRest}
                            >
                              <i className="fa fa-spinner loadicon"></i>
                              Save Settings
                            </button>
                          </div>
                        </div>
                        {this.state.success_message ? (
                          <div className='col-lg-12'>
                            <div className='text-left profileUpdatemsg userMsg'>
                              <p><i className="success-icon fa fa-check-circle" ></i>{this.state.success_message}</p>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className='user-setting-row'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='button-row text-lg-left text-md-left text-sm-center text-center'>
							<i className='fa fa-trash delete-trash'></i>
                            <span
                              className='deletetxt'
                              data-toggle='modal'
                              data-target='#deleteaccount'
                            >
                              Delete Account
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* For Delete Account */}
              <div
                className='modal fade user-set-popup'
                id='deleteaccount'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
              >
                <div className='container'>
                  <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                      <div className='modal-title'>
                        <h5>Account Deletion</h5>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                          onClick={this.revertName}
                        >
                          <span aria-hidden='true'>×</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='user-set-popup-block delete-popup'>
                          <div className='form-row'>
							<p>
								Are you sure you want to delete your account?
							</p>
							<span>
								*Once you delete a your account, there is no going back. Please be certain.
							</span>
                          </div>
                          <div className='col-lg-12 text-right button-row'>
                            <button
                              className={this.state.isLoading ? 'save-btn btn is-loading' : 'save-btn btn'}
                              onClick={this.deleteAccount}
                            >
                              <i className="fa fa-spinner loadicon"></i>
                              Delete
                            </button>
                            <button
                              className='cancel-btn btn'
                              data-dismiss='modal'
                              aria-label='Close'
                              onClick={this.revertName}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* For Username edit */}
              <div
                className='modal fade user-set-popup'
                id='useredit'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
              >
                <div className='container'>
                  <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                      <div className='modal-title'>
                        <h5>Edit User Name</h5>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                          onClick={this.revertName}
                        >
                          <span aria-hidden='true'>×</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='user-set-popup-block'>
                          <div className='form-row'>
                            <label>
                              First Name
                            </label>
                            <div className='form-field-wrp kf-focus'>
                              <input
                                type='text'
                                name='first_name'
                                className='txt-cntrl form-field'
                                placeholder='First Name'
                                value={
                                  this.state.update_first_name ? this.state.update_first_name : ''
                                }
                                onChange={e =>
                                  this.setState({
                                    update_first_name: e.target.value
                                  }, () => this.validateName())
                                }
                              />
                              {/* {this.state.update_first_name && 
                                  <span 
                                      className="kf-clear-span" 
                                      onClick={e => {
                                          this.setState({ update_first_name: '' });
                                      }}
                                  >
                                  </span>
                              } */}
                            </div>
                            {this.state.errors.first_name ? (
                              <span className='errorMsg'>
                                <i className='alert-icon fa fa-exclamation'></i>
                                {this.state.errors.first_name}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='form-row'>
                            <label>
                              Last Name
                            </label>
                            <div className='form-field-wrp kf-focus'>
                              <input
                                type='text'
                                name='last_name'
                                className='txt-cntrl form-field'
                                placeholder='Last Name'
                                value={
                                  this.state.update_last_name ? this.state.update_last_name : ''
                                }
                                onChange={e =>
                                  this.setState({
                                    update_last_name: e.target.value
                                  }, () => this.validateName())
                                }
                              />
                              {/* {this.state.update_last_name && 
                                  <span 
                                      className="kf-clear-span" 
                                      onClick={e => {
                                          this.setState({ update_last_name: '' })
                                      }}
                                  >
                                  </span>
                              } */}
                            </div>
                            {this.state.errors.last_name ? (
                              <span className='errorMsg'>
                                <i className='alert-icon fa fa-exclamation'></i>
                                {this.state.errors.last_name}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='col-lg-12 text-right button-row'>
                            <button
                              className={this.state.isLoading ? 'save-btn btn is-loading' : 'save-btn btn'}
                              onClick={this.updateName}
                            >
                              <i className="fa fa-spinner loadicon"></i>
                              Save
                            </button>
                            <button
                              className='cancel-btn btn'
                              data-dismiss='modal'
                              aria-label='Close'
                              onClick={this.revertName}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* For edit email */}
              <div
                className='modal fade user-set-popup'
                id='emailedit'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
              >
                <div className='container'>
                  <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                      <div className='modal-title'>
                        <h5>Edit Email</h5>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                          onClick={this.revertEmail}
                        >
                          <span aria-hidden='true'>×</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        {!this.state.isEmailUpdated ?
                          <div className='user-set-popup-block'>
                            {this.state.is_password ?
                              <div className='form-row'>
                                <label>
                                  Kicksfinder Password{' '}

                                </label>
                                <div className={`form-field-wrp kf-focus kf-toggle-pass ${this.state.current_password.length <= 0 || this.state.errors.current_password ? 'kf-negative' : 'kf-positive'}`}>
                                  <input
                                    type='password'
                                    name='kickpass'
                                    className='txt-cntrl form-field password-field'
                                    value={this.state.current_password}
                                    onChange={e =>
                                      this.setState({
                                        current_password: e.target.value
                                      }, () => this.validateKicksPass())
                                    }
                                  />
                                  <span className="kf-toggle-pass-span"></span>
                                  {/* {this.state.current_password && 
                                      <span 
                                          className="kf-clear-span" 
                                          onClick={e => {
                                              this.setState({ current_password: '' })
                                          }}
                                      >
                                      </span>
                                  } */}
                                </div>
                                {this.state.errors.current_password ? (
                                  <span className='errorMsg'>
                                    <i className='alert-icon fa fa-exclamation'></i>
                                    {this.state.errors.current_password}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              : ''}
                            <div className='form-row'>
                              <label>
                                New Email
                              </label>
                              <div className={`form-field-wrp kf-focus ${this.state.new_email.length <= 0 || this.state.errors.new_email ? 'kf-negative' : 'kf-positive'}`}>
                                <input
                                  type='text'
                                  name='newemail'
                                  className='txt-cntrl form-field'
                                  value={this.state.new_email}
                                  onChange={e =>
                                    this.setState({
                                      new_email: e.target.value
                                    }, () => this.validateNewEmail())
                                  }
                                />
                                {/* {this.state.new_email && 
                                    <span 
                                        className="kf-clear-span" 
                                        onClick={e => {
                                            this.setState({ new_email: '' })
                                        }}
                                    >
                                    </span>
                                } */}
                              </div>
                              {this.state.errors.new_email ? (
                                <span className='errorMsg'>
                                  <i className='alert-icon fa fa-exclamation'></i>
                                  {this.state.errors.new_email}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='form-row'>
                              <label>
                                Confirm New Email{' '}
                              </label>
                              <div className={`form-field-wrp kf-focus ${this.state.confirm_email.length <= 0 || this.state.errors.confirm_email ? 'kf-negative' : 'kf-positive'}`}>
                                <input
                                  type='text'
                                  name='confemail'
                                  className='txt-cntrl form-field'
                                  value={this.state.confirm_email}
                                  onChange={e => {
                                    this.setState({
                                      confirm_email: e.target.value
                                    }, () => this.validateConfirmEmail())
                                  }}
                                />
                                {/* {this.state.confirm_email && 
                                    <span 
                                        className="kf-clear-span" 
                                        onClick={e => {
                                            this.setState({ confirm_email: '' })
                                        }}
                                    >
                                    </span>
                                } */}
                              </div>
                              {this.state.errors.confirm_email ? (
                                <span className='errorMsg'>
                                  <i className='alert-icon fa fa-exclamation'></i>
                                  {this.state.errors.confirm_email}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='col-lg-12 text-right button-row'>
                              <button
                                className={this.state.isLoading ? 'save-btn btn is-loading' : 'save-btn btn'}
                                onClick={this.updateEmail}
                              >
                                <i className="fa fa-spinner loadicon"></i>
                                Save
                              </button>
                              <button
                                className='cancel-btn btn'
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={this.revertEmail}>
                                Cancel
                              </button>
                            </div>
                          </div>
                          :
                          <div className='user-set-popup-block'>
                            <h2>Check you Email!</h2>
                            <p>We have sent you a confirmation email. Click through the link in your email to finalize updating your email. Didn't receive anything?
                            Make sure to check your junk folder, or click this <span onClick={this.resendEMail}>link</span> to resend confirmation email.</p>
                            {this.state.resendEmailMessage != '' ?
                              <div className='text-left profileUpdatemsg userMsg'>
                                <p><i className="success-icon fa fa-check-circle" ></i>{this.state.resendEmailMessage}</p>
                              </div>
                              : ''}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* For edit password */}
              <div
                className='modal fade user-set-popup'
                id='passwordedit'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
              >
                <div className='container'>
                  <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                      <div className='modal-title'>
                        <h5>Edit Password</h5>
                        <button
                          type='button'
                          className='close'
                          data-dismiss='modal'
                          aria-label='Close'
                          onClick={this.revertPassword}
                        >
                          <span aria-hidden='true'>×</span>
                        </button>
                      </div>
                      <div className='modal-body'>
                        <div className='user-set-popup-block'>
                          {this.state.is_password ?
                            <div className='form-row'>
                              <label>
                                Current Password{' '}
                              </label>
                              <div className={`form-field-wrp kf-focus kf-toggle-pass ${this.state.current_password.length <= 0 || this.state.errors.current_password ? 'kf-negative' : 'kf-positive'}`}>
                                <input
                                  type='password'
                                  name='currpassword'
                                  className='txt-cntrl form-field password-field'
                                  value={this.state.current_password}
                                  onChange={e =>
                                    this.setState({
                                      current_password: e.target.value
                                    }, () => this.validateCurrentPass())
                                  }
                                />
                                <span className="kf-toggle-pass-span"></span>
                                {/* {this.state.current_password && 
                                    <span 
                                        className="kf-clear-span" 
                                        onClick={e => {
                                            this.setState({ current_password: '' })
                                        }}
                                    >
                                    </span>
                                } */}
                              </div>
                              {this.state.errors.current_password ? (
                                <span className='errorMsg'>
                                  <i className='alert-icon fa fa-exclamation'></i>
                                  {this.state.errors.current_password}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            : ''}
                          <div className='form-row'>
                            <label>
                              New Password{' '}
                            </label>
                            <div className={`form-field-wrp kf-focus kf-toggle-pass ${this.state.password.length <= 0 || this.state.errors.password ? 'kf-negative' : 'kf-positive'}`}>
                              <input
                                type='password'
                                name='newpassword'
                                className='txt-cntrl form-field password-field'
                                value={this.state.password}
                                onChange={e =>
                                  this.setState({
                                    password: e.target.value
                                  }, () => this.validateNewPass())
                                }
                              />
                              <span className="kf-toggle-pass-span"></span>
                              {/* {this.state.password && 
                                  <span 
                                      className="kf-clear-span" 
                                      onClick={e => {
                                          this.setState({ password: '' })
                                      }}
                                  >
                                  </span>
                              } */}
                            </div>
                            {this.state.errors.password ? (
                              <span className='errorMsg'>
                                <i className='alert-icon fa fa-exclamation'></i>
                                {this.state.errors.password}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='form-row'>
                            <label>
                              Confirm New Password{' '}
                            </label>
                            <div className={`form-field-wrp kf-focus kf-toggle-pass ${this.state.confirm_password.length <= 0 || this.state.errors.confirm_password ? 'kf-negative' : 'kf-positive'}`}>
                              <input
                                type='password'
                                name='confpassword'
                                className='txt-cntrl form-field password-field'
                                value={this.state.confirm_password}
                                onChange={e =>
                                  this.setState({
                                    confirm_password: e.target.value
                                  }, () => this.validateConfirmPass())
                                }
                              />
                              <span className="kf-toggle-pass-span"></span>
                              {/* {this.state.confirm_password && 
                                  <span 
                                      className="kf-clear-span" 
                                      onClick={e => {
                                          this.setState({ confirm_password: '' })
                                      }}
                                  >
                                  </span>
                              } */}
                            </div>
                            {this.state.errors.confirm_password ? (
                              <span className='errorMsg'>
                                <i className='alert-icon fa fa-exclamation'></i>
                                {this.state.errors.confirm_password}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='col-lg-12 text-right button-row'>
                            <button
                              className={this.state.isLoading ? 'save-btn btn is-loading' : 'save-btn btn'}
                              onClick={this.updatePassword}
                            >
                              <i className="fa fa-spinner loadicon"></i>
                              Save
                            </button>
                            <button
                              className='cancel-btn btn'
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={this.revertPassword}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer {...this.state} logout={logout} /> */}
        </div>
      </Router>
    )
  }
}

export default withRouter(UserSettings)
