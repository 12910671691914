import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';

class Breadcrumb extends Component {
    render() {

        const {
            breadcrumb
        } = this.props;

        return (
            breadcrumb && (
                <div className="site-breadcrumb">
                    <div className="container padd-large">
                        <ul>
                            {breadcrumb.map((item, index) => (
                                <li key={index}>
                                    {item.url ? (
                                    <Link to={item.url}>{item.name}</Link>
                                    ) : (
                                    <span>{item.name}</span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )
        )
    }
}

export default withRouter(Breadcrumb)