import $ from "jquery";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    // gtmId: 'GTM-N2F3T7M',
    gtmId: process.env.REACT_APP_GTM_CODE
}


export const SELF_DOMAIN = process.env.REACT_APP_DOMAIN;

export const GOOGLE_LOGIN_TOKEN = process.env.REACT_APP_GOOGLE_LOGIN_TOKEN;

export const getToken = () => {
    return localStorage.getItem('token') ? localStorage.getItem('token') : '';
}

export const getAuthorizationToken = () => {
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    if (token)
        return 'Bearer ' + token;
    else
        return '';
}

export const getCookie = (name) => {
    var allCookies = document.cookie.split(';'),
        cookieCounter = 0,
        currentCookie = '';
    for (cookieCounter = 0; cookieCounter < allCookies.length; cookieCounter++) {
        currentCookie = allCookies[cookieCounter];
        while (currentCookie.charAt(0) === ' ') {
            currentCookie = currentCookie.substring(1, currentCookie.length);
        }
        if (currentCookie.indexOf(name + '=') === 0) {
            return currentCookie.substring(name.length + 1, currentCookie.length);
        }
    }
    return false;
}

export const setCookie = (name, value) => {
    var date = new Date(),
        expires = 'expires=';
    // date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
    var current = new Date();
    date = new Date(new Date(current).setMonth(current.getMonth() + 6));
    var key;
    var existing = getCookie(name);
    if (existing) {
        key = existing;
    } else {
        key = (date.getTime() + Math.floor(100000 + Math.random() * 900000));
    }
    expires += date.toGMTString();
    document.cookie = name + '=' + key + '; ' + expires + '; path=/';
    // document.cookie = name + '=' + value + '; path=/';
}

export const setCookieValue = (name, value) => {
    var date = new Date(),
        expires = 'expires=';
    var current = new Date();
    date = new Date(new Date(current).setMonth(current.getMonth() + 6));
    expires += date.toGMTString();
    document.cookie = name + '=' + value + '; ' + expires + '; path=/';
}

export const setCookieMessage = (value) => {
    // var date = new Date(),
    //     expires = 'expires=';
    // // date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
    // var current = new Date();
    // date = new Date(new Date(current).setMonth(current.getMonth() + 6));
    // var key;
    // var existing = getCookie(name);
    // if (existing) {
    //     key = existing;
    // } else {
    //     key = (date.getTime() + Math.floor(100000 + Math.random() * 900000));
    // }
    // expires += date.toGMTString();
    // document.cookie = name + '=' + key + '; ' + expires + '; path=/';
    document.cookie = 'message=' + value + '; path=/';
}

export const getCookieMessage = () => {
    let name = 'message';
    var allCookies = document.cookie.split(';'),
        cookieCounter = 0,
        currentCookie = '';
    for (cookieCounter = 0; cookieCounter < allCookies.length; cookieCounter++) {
        currentCookie = allCookies[cookieCounter];
        while (currentCookie.charAt(0) === ' ') {
            currentCookie = currentCookie.substring(1, currentCookie.length);
        }
        if (currentCookie.indexOf(name + '=') === 0) {
            let mess = currentCookie.substring(name.length + 1, currentCookie.length);
            document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            return mess;
        }
    }
    return '';
}

export const loadImage = () => {
    var imagesLoaded = require('imagesloaded');
    imagesLoaded.makeJQueryPlugin($);

    let lazyLoadSections = ['.allproducts', '.banner-carousel', '.ltst-news-blk-image', '.slider-img-cnt-blk'];
    setTimeout(function () {
        $.each(lazyLoadSections, function (k, v) {
            if (0 < $(v).length) {
                $(v).imagesLoaded().progress(function (instance, image) {
                    var result = image.isLoaded ? 'loaded' : 'broken';
                    var $item = $(image.img);
                    if ('loaded' === result) {

                        // un-hide item
                        $item.addClass('loaded');
                    } else {
                        $item.addClass('not-loaded');
                    }
                });
            }
        });
    }, 1000);
}

export const getSortFilterName = (name) => {
    let newName = '';
    if (name === 'most_viewed') {
        newName = 'Most Viewed';
    } else if (name === 'most_liked') {
        newName = 'Most Liked';
    } else if (name === 'recently_added') {
        newName = 'Recently Added';
    }

    return newName;
}

export const getReleaseFilterName = (name) => {
    let newName = 'view_all';
    if (name === 'not_yes_released') {
        newName = 'Upcoming';
    } else if (name === 'past_releases') {
        newName = 'Past Releases';
    }

    return newName;
}

export const FACEBOOK = process.env.REACT_APP_FACEBOOK;

export const TWITTER = process.env.REACT_APP_TWITTER;

export const INSTAGRAM = process.env.REACT_APP_INSTAGRAM;

export const SNSTORE = process.env.REACT_APP_SNSTORE;

export const TWITTER_REDIRECT_URL = process.env.REACT_APP_TWITTER_REDIRECT_URL;

export const GAInit = () => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
}

export const GAPageView = () => {
    GAInit();

    ReactGA.pageview(window.location.pathname + window.location.search);

    TagManager.initialize(tagManagerArgs)
    window.dataLayer.push({
        event: 'pageview',
        page: {
            url: window.location.pathname + window.location.search
        }
    });

}

export const GAEvent = (event) => {
    // event = {
    //     category: 'Promotion',
    //     action: 'Displayed Promotional Widget',
    //     label: 'Homepage Thing',
    //     nonInteraction: true
    // }
    GAInit();
    ReactGA.event(event);
    TagManager.initialize(tagManagerArgs)
    window.dataLayer.push({
        event: 'retailarclickevent',
        category: event.category,
        action: event.action,
        label: event.label,
    });
}

export const intlFormat = (num) => {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
}
export const makeFriendly = (num) => {
    num = parseInt(num);
    if (num >= 1000000)
        return intlFormat(num / 1000000) + 'M';
    if (num >= 1000)
        return intlFormat(num / 1000) + 'k';
    return intlFormat(num);
}