
import React, { Component, Fragment } from "react";
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment-timezone';

class YouMayAlsoLike extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      totalLeft: 0,
      // click: 0,
      isLoading: false,
      width: window.innerWidth,
      height: window.innerHeight,
      autoplay: false,
      slidesToScroll: 1
    };
    this.getProducts = this.getProducts.bind(this);
    // this.getUpdateNews = this.getUpdateNews.bind(this);
    this.loadMoreNews = this.loadMoreNews.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.like = this.like.bind(this);
    this.watchlist = this.watchlist.bind(this);
  }
  slidePrevious = () => {
    if (this.state.autoplay === false) {
      this.setState({
        autoplay: true,
        slidesToScroll: -1
      }, () => this.slider.slickPlay());
    }
  }
  slideNext = () => {
    if (this.state.autoplay === false) {
      this.setState({
        autoplay: true,
        slidesToScroll: 1
      }, () => this.slider.slickPlay());
    }
  }
  slidePause = () => {
    if (this.state.autoplay === true) {
      this.setState({
        autoplay: false,
        slidesToScroll: 1
      }, () => this.slider.slickPause())
    }
  }
  componentDidMount() {
    this.updateWindowDimensions();
    this.getProducts();
    window.addEventListener('resize', this.updateWindowDimensions);
    COMMONCONST.loadImage();
    this.handleSlides();
  }
  like(id) {
    axios({
      method: "get",
      url: APICONST.LIKES(id),
      headers: { "content-type": "application/json" }
    })
      .then(response => {
        if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
          let likeCount = parseInt($('#' + id + '-like-hide').text()) + 1;
          let likeCountShow = COMMONCONST.makeFriendly(likeCount);
          $('#' + id + '-like-hide').text(likeCount);
          $('#' + id + '-like').text(likeCountShow);
          $('#' + id + '-like').parent('li').addClass('active');
        } else {
          let likeCount = parseInt($('#' + id + '-like-hide').text()) - 1;
          let likeCountShow = COMMONCONST.makeFriendly(likeCount);
          $('#' + id + '-like-hide').text(likeCount);
          $('#' + id + '-like').text(likeCountShow);
          $('#' + id + '-like').parent('li').removeClass('active');
        }
      })
      .catch(error => {
      });
  }

  watchlist(id) {
    COMMONCONST.setCookie('watchlist', true);
    axios({
      method: "get",
      url: APICONST.WATCHLIST(id),
      headers: { "content-type": "application/json" },
      params: { 'watchlist-cookie': COMMONCONST.getCookie('watchlist') }
    })
      .then(response => {
        if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
          let watchCount = COMMONCONST.makeFriendly(parseInt($('#' + id + '-watchlist-hide').text()) + response.data.result.add);
          $('#' + id + '-watchlist').text(watchCount);
          $('#' + id + '-watchlist').parent('li').addClass('active');
        } else {
          // $('#' + id + '-watchlist').text(parseInt($('#' + id + '-watchlist').text()) - 1);
          $('#' + id + '-watchlist').parent('li').removeClass('active');
        }
      })
      .catch(error => {
      });
  }
  componentDidUpdate() {
    COMMONCONST.loadImage();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight }, () => {
      // this.getProducts();
    });
  }

  // getUpdateNews() {
  //   this.setState(prevState => ({
  //     click: prevState.click + 1
  //   }), () => {
  //     if (this.state.click % 25 === 0) {
  //       this.getProducts();
  //     }
  //   })
  // }

  loadMoreNews() {
    this.getProducts();
  }

  getProducts() {
    let page = this.state.page + 1;
    let per_page;
    let related_data;
    if (typeof (this.props.related_data !== undefined)) {
      related_data = this.props.related_data;
    }

    if (this.state.width >= 768) {
      per_page = 24;
    } else {
      per_page = 8;
    }

    this.setState({
      isLoading: true
    })
    axios({
      method: "get",
      url: APICONST.PRODUCTLISTING,
      headers: { "content-type": "application/json" },
      params: {
        page: page,
        perpage: per_page,
        you_may_like: this.props.releate_product,
        product_id: this.props.product_id,
        cookie: COMMONCONST.getCookie('watchlist')
      }
    })
      .then(response => {
        if ((response.data.result.data.data) !== undefined) {
          let data;
          data = this.state.data;
          data = data.concat(response.data.result.data.data);

          let meta = response.data.result.data.meta;
          let totalLeft = meta.total - (meta.page * meta.perpage);
          totalLeft = (totalLeft < 0) ? 0 : totalLeft;

          this.setState({
            data: data,
            page: page,
            isLoading: false,
            totalLeft: totalLeft
          });
        } else {
          this.setState({
            data: []
          });

        }

      })
      .catch(error => this.setState({ error: error.message, isLoading: false }));
  }

  handleSlides() {
    let count = 0;
    $(document).on("mouseover", ".you-may-slider .kf-slider-wrapper .slick-current", function () {
      count = 1;
      // $(".you-may-slider .kf-slider-wrapper .previous-btn").trigger("click");
    });

    $(document).on("mouseover", ".you-may-slider .kf-slider-wrapper .slick-current + .slick-active", function () {
      if (count === 1) {
        $(".you-may-slider .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });

    $(document).on("mouseover", ".you-may-slider .kf-slider-wrapper .slick-current + .slick-active + .slick-active", function () {
      if (count === 1) {
        $(".you-may-slider .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });

    $(document).on("mouseover", ".you-may-slider .kf-slider-wrapper .slick-current + .slick-active + .slick-active + .slick-active", function () {
      count = 1;
      // $(".you-may-slider .kf-slider-wrapper .next-btn").trigger("click");
    });

    $(document).on("mouseleave", ".you-may-slider .kf-slider-wrapper", function () {
      if (count === 1) {
        count = 0;
        $(".you-may-slider .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });
  }

  render() {

    const formatDateIndividual = (day, month, yea) => {
      if (typeof yea != 'string' || typeof month != 'string' || typeof day != 'string') {
        return '';
      }
      let year;
      if (yea && month && day && day !== '00') {
        year = yea + '-' + month + '-' + day;
      } else if (yea && month && month !== '00') {
        year = yea + '-' + month;
      } else {
        year = yea;
      }

      if (year.length <= 4) {
        return year;
      } else {
        let mydate = new Date(year);
        let currentD = moment.tz(year + ' 00:00:00', 'America/New_York');
        if (year.length == 7) {
          // return mydate.toLocaleString('en-us', { month: 'long' }) + ', ' + mydate.getFullYear();
          currentD = moment.tz(year + '-01 00:00:00', 'America/New_York');
          return moment(currentD).format('MMMM') + ', ' + moment(currentD).format('YYYY');
        } else {
          // return mydate.toLocaleString('en-us', { month: 'long' }) + ' ' + mydate.getDate() + ', ' + mydate.getFullYear();
          return moment(currentD).format('MMMM') + ' ' + moment(currentD).format('DD') + ', ' + moment(currentD).format('YYYY');
        }
      }
    }

    let fireIcon = (
      <svg version="1.1" id="Layer_1" x="0px" y="0px" width="16.145px" height="21.492px" viewBox="0 0 16.145 21.492" enableBackground="new 0 0 16.145 21.492">
        <g>
          <defs>
            <rect id="SVGID_1_" y="0" width="16.145" height="21.492" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" overflow="visible" />
          </clipPath>
          <path fill="none" stroke="#737373" strokeWidth="2" strokeMiterlimit="10" d="M15.139,11.8
                  c0.163,4.954-3.336,8.691-7.18,8.691C4.116,20.492,1,17.376,1,13.532C1,8.778,6.11,4.801,8.187,2.359
                  c0.19,1.43,0.229,5.68,1.517,7.691C10.775,11.726,13.326,12.243,15.139,11.8z"/>
        </g>
      </svg>
    )

    let viewIcon = (
      <svg width="16" height="10" viewBox="0 0 16 10" fill="none">
        <path d="M8.19327 9.8278C4.34185 9.8278 1.34774 5.71358 1.21785 5.54215L0.977539 5.1993L1.22434 4.85645C1.34774 4.6789 4.34185 0.570801 8.19977 0.570801C12.0512 0.570801 15.0453 4.68502 15.1752 4.85645L15.422 5.1993L15.1752 5.54215C15.0453 5.7197 12.0512 9.8278 8.19327 9.8278ZM2.56877 5.1993C3.32866 6.12378 5.60834 8.60333 8.19327 8.60333C10.7782 8.60333 13.0579 6.12378 13.8178 5.1993C13.0579 4.27483 10.7782 1.79527 8.19327 1.79527C5.60834 1.79527 3.32866 4.27483 2.56877 5.1993Z" fill="#707070" />
        <path d="M8.19321 8.19923C6.4396 8.19923 5.01074 6.85232 5.01074 5.19929C5.01074 3.54626 6.4396 2.19934 8.19321 2.19934C9.94681 2.19934 11.3757 3.54626 11.3757 5.19929C11.3757 6.85232 9.9533 8.19923 8.19321 8.19923ZM8.19321 3.42381C7.15403 3.42381 6.30971 4.21971 6.30971 5.19929C6.30971 6.17886 7.15403 6.97477 8.19321 6.97477C9.23238 6.97477 10.0767 6.17886 10.0767 5.19929C10.0767 4.21971 9.23238 3.42381 8.19321 3.42381Z" fill="#707070" />
      </svg>
    )

    if (this.state.data.length > 0) {
      const slides = this.state.data.map((item, key) =>
        <div key={key} className="product-item">
          <Link target="_blank" to={'/' + item.slug} className="product-img"><img src={item.product_thumbnail} alt="img" /></Link>
          {item.just_added != '' ? <span className="just-added">{item.just_added}</span> : ''}
          <div className="prod-list-new-cnt">
            <div className="prod-details">
              <h4 className="prod-name">
                <Link target="_blank" to={'/' + item.slug}>{item.full_name}</Link>
                {item.product_nickname && <span className="prod-color"> “{item.product_nickname}”</span>}
              </h4>
              <h4 className="prod-date">{formatDateIndividual(item.releaseDay, item.releaseMonth, item.releaseYear)}</h4>
              <h2 className="prod-price">{item.price}</h2>
            </div>
            <div className="infos">
              {/* <span className={item.is_release == "0" ? "datetime red" : "datetime"}>{item.show_date}</span> */}
              <ul className="info-count">
                <li data-toggle="tooltip" data-placement="top">
                  <div className="tooltip"><div className="tooltip-inner"><span className="arrow"></span>Views</div></div>
                  <i className="eye-icon">{viewIcon}</i> {COMMONCONST.makeFriendly(item.views_count)} </li>
                <li title="Likes" className={item.is_liked === 1 ? 'active' : ''}>
                  <div className="tooltip"><div className="tooltip-inner"><span className="arrow"></span>Likes</div></div>
                  <i onClick={() => this.like(item.id)} className="fire">{fireIcon}</i> <span id={item.id + "-like"}> {COMMONCONST.makeFriendly(item.likes_counts)} </span>
                  <span id={item.id + "-like-hide"} className="hide"> {item.likes_counts} </span>
                </li>
                <li title="Watchlist" className={item.is_watchlisted === 1 ? 'active' : ''}>
                  <div className="tooltip"><div className="tooltip-inner"><span className="arrow"></span>Watchlist</div></div>
                  <i onClick={() => this.watchlist(item.id)} className="fa fa-bookmark-o"></i> <span id={item.id + "-watchlist"}> {COMMONCONST.makeFriendly(item.watch_list_count)} </span>
                  <span id={item.id + "-watchlist-hide"} className="hide"> {item.watch_list_count} </span>
                </li>
              </ul>
            </div>
            <div className="prod-detail-cnt">
              <p>{item.show_genders}</p>
              <p>{item.sku}</p>
              <p>{item.show_regions}</p>
            </div>
          </div>
        </div>
      );
      if (this.state.width >= 768) {
        var settings = {
          nav: true,
          dots: false,
          infinite: this.state.data.length > 4 ? true : false,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 4,
          autoplay: this.state.autoplay,
          autoplaySpeed: 1500,
          arrows: true,
          pauseOnHover: false,
          cssEase: this.state.autoplay ? 'linear' : 'ease-in-out',
          afterChange: (slideCount) => {
            if (slideCount % 20 === 0) {
              this.getProducts();
            }
          }
        };
        return (
          <Fragment>
            <div className="latestnews-wrapper">
              <h4 className="main-title">{this.props.title}</h4>
              {/* <CarouselSlider slideItems = {data} buttonSetting = {buttonSetting}  sliderBoxStyle= {sliderBoxStyle} accEle = {accEle} itemsStyle = {itemsStyle}/>  */}
              <div className="kf-slider-wrapper">
                <button className="button-slide next-btn" onClick={this.slideNext}>Next</button>
                <button className="button-slide pause-btn" onClick={this.slidePause}>pause</button>
                <button className="button-slide previous-btn" onClick={this.slidePrevious}>previous</button>
                <Slider {...settings} ref={c => (this.slider = c)} autoplay={this.state.autoplay}>{slides}</Slider>
              </div>
            </div>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <div className="latestnews-wrapper">
              <h4 className="main-title">{this.props.title}</h4>
              <div className="latestnews-blk-cnt">
                {slides}
                {this.state.totalLeft !== 0 ?
                  <div className="product-item view-more-btn-item">
                    <button id="loadYouMayLike" className={this.state.isLoading ? "fullbtn view-more is-loading" : "fullbtn view-more"} onClick={this.loadMoreNews}>
                      VIEW MORE {/* <span className="count">({this.state.totalLeft})</span> */} <i className="fa fa-spinner loadicon"></i>
                    </button>
                  </div> : ''}
              </div>
            </div>
          </Fragment>
        )
      }
    } else {
      return (
        <Fragment></Fragment>
      )
    }
  }
}

export default YouMayAlsoLike