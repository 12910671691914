import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import * as APICONST from '../constants/Api';
import axios from 'axios';
import moment from 'moment-timezone';


class PopularList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            filterby: props.type ? props.type : 'weekly',
            model_slug: props.model_slug ? props.model_slug : '',
            isLoading: false,
            width: window.innerWidth,
            height: window.innerHeight
        }
        this.loadData = this.loadData.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.loadData();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.model_slug !== this.state.model_slug) {
            this.setState({ model_slug: nextProps.model_slug }, () => this.loadData())
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight }, () => {
        });
    }

    changeFilter(filer) {
        if (this.state.filterby != filer) {
            this.setState({ 'filterby': filer }, () => this.loadData())
        }
    }
    loadData() {
        let params;
        if (this.state.width <= 767) {
            params = { 'search': this.state.filterby, perpage: 10 }
        } else {
            params = { 'search': this.state.filterby, perpage: 10 }
        }
        if (this.state.model_slug) {
            params.model_slug = this.state.model_slug;
        }
        this.setState({
            isLoading: true
        });
        axios({
            method: 'get',
            url: APICONST.POPULAR_LIST,
            headers: { 'content-type': 'application/json' },
            params: params
        })
            .then(response => {
                this.setState({ isLoading: false })
                if (response.data.result.data.data) {
                    this.setState({ data: response.data.result.data.data });
                }
            })
            .catch(error => {
                this.setState({ isLoading: false });
            })
    }

    showDate(item) {
        if (typeof item.releaseDay != 'string' || typeof item.releaseMonth != 'string' || typeof item.releaseYear != 'string') {
            return '<p> Release Date N/A </p>';
        }
        if (item.releaseYear && item.releaseMonth && item.releaseDay && item.releaseDay !== '00') {
            let currentD = moment.tz(item.releaseYear + '-' + item.releaseMonth + '-' + item.releaseDay + ' 00:00:00', 'America/New_York');
            let curr = moment().tz("America/New_York");
            // console.log(currentD);
            // console.log(moment().tz("America/New_York").format());
            // console.log(curr.isSameOrAfter(currentD))
            if (curr.isSameOrAfter(currentD)) {
                return '<p style="color:#707070">Released on ' + item.releaseMonth + '/' + item.releaseDay + '/' + item.releaseYear + '</p>';
            } else {
                return '<p>Available ' + item.releaseMonth + '/' + item.releaseDay + '/' + item.releaseYear + '</p>';
            }
        } else if (item.releaseYear && item.releaseMonth && item.releaseMonth !== '00') {
            return '<p>Expected ' + moment(item.releaseMonth, 'MM').format('MMMM') + ' ' + item.releaseYear + '</p>';
        } else {
            return '<p>Expected ' + item.releaseYear + '</p>';
        }
    }

    render() {

        const productBlock = (item, key) => {
            return <div key={Math.random()} className='prod-list-new'>
                <div className="item-list-counter">
                    <span className="item-number">{key + 1}</span>
                    {item.current_rank < parseInt(item.previous_rank) ?
                        <>{(parseInt(item.previous_rank) - item.current_rank) <= 999 ?
                            <span className="item-rank rank-positive"> {/* add class .rank-positive and .rank-negative */}
                                <i className="fa fa-caret-up"></i> {parseInt(item.previous_rank) - item.current_rank}
                            </span>
                            : <span className="item-rank">
                                <span className="rank-item-new">new</span>
                            </span>}
                        </>
                        : ''}
                    {item.current_rank > parseInt(item.previous_rank) ?
                        <>{(item.current_rank - parseInt(item.previous_rank)) <= 999 ?
                            <span className="item-rank rank-negative"> {/* add class .rank-positive and .rank-negative */}
                                <i className="fa fa-caret-down"></i> {item.current_rank - parseInt(item.previous_rank)}
                            </span>
                            : <span className="item-rank">
                                <span className="rank-item-new">new</span>
                            </span>}
                        </>
                        : ''}

                    {item.current_rank == parseInt(item.previous_rank) ?
                        <span className="item-rank">
                            -
                        </span>
                        : ''}

                    {item.current_rank && !item.previous_rank ?
                        <span className="item-rank">
                            <span className="rank-item-new">new</span>
                        </span>
                        : ''}
                    {/* <span className="item-rank rank-positive"> add class .rank-positive and .rank-negative */}
                    {/* <i className="fa fa-caret-up"></i> 2 */}
                    {/* for new labeled 
                        <span className="rank-item-new">new</span>
                        */}
                    {/* </span> */}
                </div>
                <div className="item-image">
                    <Link target="_blank" to={'/' + item.slug}>
                        <img src={item.product_thumbnail} alt="Thumb" />
                    </Link>
                </div>
                <div className="item-name">
                    <Link target="_blank" to={'/' + item.slug}>
                        {item.product_name} {item.product_nickname != '' ? <span className="item-nickname"> "{item.product_nickname}"</span> : ''}
                        {/* <p>{this.showDate(item)}</p> */}
                        <span className="item-time" dangerouslySetInnerHTML={{ __html: this.showDate(item) }} ></span>
                    </Link>
                </div>
            </div>
        };
        return (
            <div className="popular-product-inner-blk">
                <div className="popular-product-lisitng-main">
                    <h3 className="prod--main-title"><Link to="/most-popular" >Most Popular</Link></h3>
                    <ul className="popular-product-lisitng-filter">
                        <li className={this.state.filterby === 'weekly' ? 'active' : ''} onClick={() => this.changeFilter('weekly')}>1w</li>
                        <li className={this.state.filterby === 'monthly' ? 'active' : ''} onClick={() => this.changeFilter('monthly')}>1m</li>
                        <li className={this.state.filterby === 'six_month' ? 'active' : ''} onClick={() => this.changeFilter('six_month')}>6m</li>
                        <li className={this.state.filterby === 'all_time' ? 'active' : ''} onClick={() => this.changeFilter('all_time')}>All Time</li>
                    </ul>
                    {this.state.isLoading == false && this.state.data.length < 10 && this.state.width >= 768 ? <p className="placeholder-warning">Not enough data for selected date range</p> : ''}
                    {this.state.isLoading == false && this.state.data.length < 5 && this.state.width < 767 ? <p className="placeholder-warning">Not enough data for selected date range</p> : ''}
                </div>
                {/* <div className={this.state.isLoading == true ? "popular-product-inner-lisitng most-pop-placeholder" : "popular-product-inner-lisitng"}>
                    {this.state.data.map((item, key) =>
                        productBlock(item, key)
                    )}
                </div> */}
                {/* {this.state.isloader == false && this.state.data.length > 10} */}
                {this.state.isLoading == true || (this.state.isLoading == false && this.state.data.length < 10 && this.state.width >= 768) || (this.state.isLoading == false && this.state.data.length < 2 && this.state.width < 767) ?
                    <div className={this.state.isLoading == true ? "popular-product-inner-lisitng most-pop-placeholder is-loading" : "popular-product-inner-lisitng most-pop-placeholder"}>
                        <div className="prod-list-new">
                            <div className="item-list-counter"><span className="item-number">1</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 6</span></div>
                            <div className="item-image product-placeholder"></div>
                            <div className="item-name product-placeholder"></div>
                        </div>
                        <div className="prod-list-new">
                            <div className="item-list-counter"><span className="item-number">2</span><span className="item-rank"><span className="rank-item-new">new</span></span></div>
                            <div className="item-image product-placeholder"></div>
                            <div className="item-name product-placeholder"></div>
                        </div>
                        <div className="prod-list-new">
                            <div className="item-list-counter"><span className="item-number">3</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 5</span></div>
                            <div className="item-image product-placeholder"></div>
                            <div className="item-name product-placeholder"></div>
                        </div>
                        <div className="prod-list-new">
                            <div className="item-list-counter"><span className="item-number">4</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 5</span></div>
                            <div className="item-image product-placeholder"></div>
                            <div className="item-name product-placeholder"></div>
                        </div>
                        <div className="prod-list-new">
                            <div className="item-list-counter"><span className="item-number">5</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 5</span></div>
                            <div className="item-image product-placeholder"></div>
                            <div className="item-name product-placeholder"></div>
                        </div>
                        {this.state.width >= 767 ?
                            <Fragment>
                                <div className="prod-list-new">
                                    <div className="item-list-counter"><span className="item-number">6</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 5</span></div>
                                    <div className="item-image product-placeholder"></div>
                                    <div className="item-name product-placeholder"></div>
                                </div>
                                <div className="prod-list-new">
                                    <div className="item-list-counter"><span className="item-number">7</span><span className="item-rank rank-negative"> <i className="fa fa-caret-down"></i> 6</span></div>
                                    <div className="item-image product-placeholder"></div>
                                    <div className="item-name product-placeholder"></div>
                                </div>
                                <div className="prod-list-new">
                                    <div className="item-list-counter"><span className="item-number">8</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 4</span></div>
                                    <div className="item-image product-placeholder"></div>
                                    <div className="item-name product-placeholder"></div>
                                </div>
                                <div className="prod-list-new">
                                    <div className="item-list-counter"><span className="item-number">9</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 4</span></div>
                                    <div className="item-image product-placeholder"></div>
                                    <div className="item-name product-placeholder"></div>
                                </div>
                                <div className="prod-list-new">
                                    <div className="item-list-counter"><span className="item-number">10</span><span className="item-rank rank-positive"> <i className="fa fa-caret-up"></i> 4</span></div>
                                    <div className="item-image product-placeholder"></div>
                                    <div className="item-name product-placeholder"></div>
                                </div>
                            </Fragment> : ''
                        }
                    </div> :
                    <div className="popular-product-inner-lisitng">
                        {this.state.data.map((item, key) =>
                            productBlock(item, key)
                        )}
                    </div>}
                {this.state.isLoading != true && this.state.width >= 768 ?
                    <div className={this.state.isLoading == false && this.state.data.length < 10 ? "loadmore-btn loadbtn-disabled" : "loadmore-btn"}>
                        <Link to="/most-popular" onClick={() => localStorage.setItem('search_most_popular', this.state.filterby)}>Load More</Link>
                    </div>
                    : ''}
                {this.state.isLoading != true && this.state.width < 768 ?
                    <div className={this.state.isLoading == false && this.state.data.length < 5 ? "loadmore-btn loadbtn-disabled" : "loadmore-btn"}>
                        <Link to="/most-popular" onClick={() => localStorage.setItem('search_most_popular', this.state.filterby)}>View More</Link>
                    </div>
                    : ''}
            </div>
        )
    }
}

export default PopularList