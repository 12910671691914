import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
// import { BrowserRouter as Router, Route, Switch, useParams } from "react-router-dom";
// import { GoogleLogin } from 'react-google-login'
import axios from 'axios'
import $ from 'jquery'
import Logo from '../assets/images/new-logo.svg'
import '../assets/css/font-awesome.min.css'
import '../assets/fonts/fonts.css'
import './Custom.css'
import * as APICONST from '../constants/Api';

class ResetPassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      token: this.props.resettoken,
      password: '',
      confirm_password: '',
      error: false,
      isLoading: false,
      errors: {
        password: '',
        confirm_password: '',
        token: ''
      },
      error_message: '',
      sucess_message: ''
    }
  }

  componentDidMount(){
    $('#resetModal').modal('show')
  }

  redirectLink(e){
    e.preventDefault()
    const path = e.target.href
    if(path){ 
      window.location = path
    }else{
      window.location = '/'
    }
  }

  handleFormSubmit = e => {
    e.preventDefault()

    if (this.state.token !== '') {
    
      this.validatePassword();

      

      if (this.state.password !== '' && this.state.confrim_password !== '') {
        this.setState({
          isLoading: true
        });
        axios({
          method: 'post',
          url: APICONST.RESETPASSWORD,
          headers: { 'content-type': 'application/json' },
          data: this.state
        })
          .then(response => {
            if (
              response.data.result.message ===
              'Password has been successfully changed.'
            ) {
              this.setState({ error: false, isLoading: false })
              this.setState({
                password: '',
                confirm_password: '',
                sucess_message: response.data.result.message
              })
            
            }
            
          })
          .catch(error => {
            
            if (
              typeof error.response !== 'undefined' &&
              typeof error.response.data.result.message !== 'undefined'
            ) {
              this.setState({
                error_message: error.response.data.result.message, isLoading: false
              })
            }
            this.setState({
              isLoading: false
            });
           
          })
      }
    } else {
      this.setState({
        errors: {
          token: 'Invalid Token'
        }
      })
    }
  }

  validatePassword = () => {
    let password = this.state.password
    let confirm_password = this.state.confirm_password
    const validPasswordRegexNumber = RegExp(
      /\d+/i
    )
    const validPasswordRegexAlphabet = RegExp(
      /[a-zA-Z]/i
    )
    if ('' === password) {
      this.setState({
        errors: {
          password: 'Please enter a password'
        }
      })
      return false
    } else if (password && password.length <= 7) {
      this.setState({
        errors: {
          password: 'Password should be alphanumeric and Minimum 8 characters long'
        }
      })
      return false
    }  else if (password && (validPasswordRegexNumber.test(password) === false || validPasswordRegexAlphabet.test(password) === false)) {
      this.setState({
        errors: {
          password: 'Password should be alphanumeric and Minimum 8 characters long'
        }
      })
      return false
    } else {
      this.setState({
        errors: {
          password: ''
        }
      })
    }

    if ('' === confirm_password) {
      this.setState({
        errors: {
          confirm_password: 'Please enter a confirm password'
        }
      })
      return false
    } else if (confirm_password !== password) {
      this.setState({
        errors: {
          confirm_password: 'Password does not match'
        }
      })
      return false
    } else {
      this.setState({
        errors: {
          confirm_password: ''
        }
      })
    }
  }

  render () {
    $(document).ready(function(){
      $('#signinModal').on('show.bs.modal', function () {
          $("#resetModal").modal('hide');
          $("body").addClass("modal-popup-open");
      });
    });
    return (
      <Fragment>
        <div
          className='modal signModal fade show'
          id='resetModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='container'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-body'>
                  <button
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                  >
                    
                  </button>
                  <div className='sign-block'>
                    <div className='form-info'>
                      <Link to='/' className='navbar-brand'>
                        <img src={Logo} className='logo' alt='logo' />
                      </Link>
                      <p>Enter a new password you would like to use for your account. </p>
                      <h3>Reset Password</h3>
                    </div>

                    <form
                      action=''
                      onSubmit={this.handleFormSubmit}
                      className={
                        this.state.error ? 'modal-box' : 'modal-box no-error'
                      }
                    >
                      <div className='form-row'>
                      {this.state.errors.password ? (
                          <span className='errorMsg'>
                          <i className='alert-icon fa fa-exclamation'></i>
                          {this.state.errors.password}
                        </span>
                          
                        ) : (
                          ''
                        )}
                        <div className={`form-field-wrp kf-focus kf-clear kf-toggle-pass ${this.state.password.length <= 0 || this.state.errors.password ? 'kf-negative' : 'kf-positive'}`}>
                          <input
                            type='password'
                            name='password'
                            id='password'
                            placeholder='Password'
                            value={this.state.password}
                            onChange={e =>
                              this.setState({
                                password: e.target.value
                              }, () => this.validatePassword())
                            }
                            className={`form-field password-field ${this.state.errors.password ? 'error-tag' : ''}`}
                          />
                          <span className="kf-toggle-pass-span"></span>
                          {this.state.password && 
                              <span 
                                  className="kf-clear-span" 
                                  onClick={e => {
                                      this.setState({ password: '' })
                                  }}
                              >
                              </span>
                          }
                        </div>
                      </div>
                      <div className='form-row'>
                      {this.state.errors.confirm_password ? (
                           <span className='errorMsg'>
                           <i className='alert-icon fa fa-exclamation'></i>
                           {this.state.errors.confirm_password}
                         </span>
                         
                        ) : (
                          ''
                        )}
                        <div className={`form-field-wrp kf-focus kf-clear kf-toggle-pass ${this.state.confirm_password.length <= 0 || this.state.errors.confirm_password ? 'kf-negative' : 'kf-positive'}`}>
                          <input
                            type='password'
                            name='confirm_password'
                            id='pwd_signin'
                            placeholder='Confirm Password'
                            value={this.state.confirm_password}
                            onChange={e =>
                              this.setState({
                                confirm_password: e.target.value
                              }, () => this.validatePassword())
                            }
                            className={`form-field password-field ${this.state.errors.confirm_password ? 'error-tag' : ''}`}
                          />
                          <span className="kf-toggle-pass-span"></span>
                          {this.state.confirm_password && 
                              <span 
                                  className="kf-clear-span" 
                                  onClick={e => {
                                      this.setState({ confirm_password: '' })
                                  }}
                              >
                              </span>
                          }
                        </div>
                      </div>
                      {this.state.error ? (
                        <span className='errorMsg'>
                        <i className='alert-icon fa fa-exclamation'></i>
                        {this.state.error}
                      </span>
                      ) : (
                        ''
                      )}
                      <div className='form-row'>
                        <div className='right'>
                          <button className={this.state.isLoading ? 'signin-btn is-loading' : 'signin-btn' }>Submit
                          <i className="fa fa-spinner loadicon"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    { this.state.error_message ? (
                       <span className='errorMsg'>
                       <i className='alert-icon fa fa-exclamation'></i>
                       {this.state.error_message}
                     </span>
                     
                    ) : '' }
                   
                    { this.state.sucess_message ? (
                      <div className="userMsg userResetMsg">
                        <p><i className="success-icon fa fa-check-circle"></i>{this.state.sucess_message} 
                          {' '}Click <Link to=''
                          className='forgot-pwd'
                          data-toggle='modal'
                          data-target='#signinModal'>here</Link>  to login. </p>
                      </div>
                    ) : '' }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ResetPassword
