import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import * as APICONST from '../constants/Api';
import axios from 'axios'
import $ from 'jquery'

class StoreFeedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'store_name': '',
            'address': '',
            'city': '',
            'state': '',
            'zip_code': '',
            'website_url': '',
            'instagram_url': '',
            'image': null,
            'isLoading': false,
            'isError': false,
            'errors': {
                'store_name': '',
                'address': '',
                'city': '',
                'state': '',
                'zip_code': '',
                'instagram_url': '',
                'website_url': '',
            },
        }
    }

    resetForm = () => {
        $('.store-feedback-message').css('display', 'none');
        this.setState({
            'store_name': '',
            'address': '',
            'city': '',
            'state': '',
            'zip_code': '',
            'website_url': '',
            'instagram_url': '',
            'image': null,
            'errors': {
                'store_name': '',
                'address': '',
                'city': '',
                'state': '',
                'zip_code': '',
            },
        })
    }

    validateURL(url) {
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!urlPattern.test(url);
      }

    submitFeedback = (e) => {
        const { store_name, address, city, state, zip_code, image, website_url, instagram_url } = this.state;
        const formData = new FormData();
        formData.append('image', image);
        formData.append('store_name', store_name);
        formData.append('address', address);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('zip_code', zip_code);
        formData.append('website_url', website_url);
        formData.append('instagram_url', instagram_url);
        let errors = {};

        if (!store_name) {
            errors.store_name = 'Store name is required';
        }
        if (!address) {
            errors.address = 'Address is required';
        }
        if (!city) {
            errors.city = 'City is required';
        }
        if (!state) {
            errors.state = 'State is required';
        }
        if (!zip_code) {
            errors.zip_code = 'Zip code is required';
        }
        if (website_url && !this.validateURL(website_url)) {
            errors.website_url = 'Invalid website URL';
        }
        console.log('instagram_url', this.validateURL(instagram_url));
        if (instagram_url && !this.validateURL(instagram_url)) {
            errors.instagram_url = 'Invalid Instagram URL';
        }

        if (Object.keys(errors).length > 0) {
            console.log('errors', errors);
            this.setState({ errors });
            return;
        }
        errors = {
            'store_name': '',
            'address': '',
            'city': '',
            'state': '',
            'zip_code': '',
            'instagram_url': '',
            'website_url': '',
        };
        this.setState({ isLoading: true, errors });
        axios.post(APICONST.StoreFeedback, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            this.setState({ isLoading: false });
            this.resetForm();
            $('.store-feedback-message').css('display', 'block');
        }).catch(error => {
            this.setState({ isLoading: false });
            if(error?.response?.data?.result?.errors) {
                this.setState({ errors: error.response.data.result.errors });
            }
            console.log('Something went wrong');
        });
    }

    render() {

        return (
            <div className="container store-locator-feedback-wrap">
                <div className="text-center pt-1 pb-5">
                    <span
                        className='store-locator-feedback-btn'
                        data-toggle='modal'
                        data-target='#storelocatorfeedbackmodel'
                        onClick={this.resetForm}
                        >
                        See something missing?
                    </span>
                </div>
                {/* For Store Location Feedback */}
                    <div
                    className='modal fade user-set-popup store-feedback-modal'
                    id='storelocatorfeedbackmodel'
                    tabIndex='-1'
                    role='dialog'
                    aria-labelledby='exampleModalLabel'
                    aria-hidden='true'
                    >
                    <div className='container'>
                        <div className='modal-dialog' role='document'>
                            <div className='modal-content'>
                                <div className='modal-title'>
                                <h5>Submit A Missing Store</h5>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                    >
                                <span aria-hidden='true'>×</span>
                                </button>
                                </div>
                                <div className='modal-body'>
                                <div className='user-set-popup-block delete-popup'>
                                    <div className='form-row full-width store-feedback-message'>
                                        <p><i className="success-icon fa fa-check-circle"></i> Feedback Submitted successfully.</p>
                                    </div>  
                                    <div className='form-row full-width'>
                                        <label>
                                        Store Name<span className="asterisk">*</span>
                                        </label>
                                        <div className='form-field-wrp kf-focus'>
                                        <input
                                            type='text'
                                            name='store_name'
                                            className='txt-cntrl form-field'
                                            placeholder='Store Name'
                                            value={this.state.store_name}
                                            onChange={e =>
                                                this.setState({
                                                    store_name: e.target.value
                                                })
                                                }
                                        />
                                        {this.state.errors.store_name ? (
                                            <span className='errorMsg'>
                                                <i className='alert-icon fa fa-exclamation'></i>
                                                {this.state.errors.store_name}
                                            </span>
                                            ) : (
                                            ''
                                            )}
                                        </div>
                                    </div>
                                    <div className='form-row full-width'>
                                        <label>
                                        Address<span className="asterisk">*</span>
                                        </label>
                                        <div className='form-field-wrp kf-focus'>
                                        <textarea
                                            name='address'
                                            className='txt-cntrl form-field'
                                            placeholder='Address'
                                            value={this.state.address}
                                            onChange={e =>
                                                this.setState({
                                                    address: e.target.value
                                                })
                                                }
                                        />
                                        {this.state.errors.address ? (
                                            <span className='errorMsg'>
                                                <i className='alert-icon fa fa-exclamation'></i>
                                                {this.state.errors.address}
                                            </span>
                                            ) : (
                                            ''
                                            )}
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <label>
                                        City<span className="asterisk">*</span>
                                        </label>
                                        <div className='form-field-wrp kf-focus'>
                                        <input
                                            type='text'
                                            name='city'
                                            className='txt-cntrl form-field'
                                            placeholder='City'
                                            value={this.state.city}
                                            onChange={e =>
                                                this.setState({
                                                    city: e.target.value
                                                })
                                                }
                                        />
                                        {this.state.errors.city ? (
                                            <span className='errorMsg'>
                                                <i className='alert-icon fa fa-exclamation'></i>
                                                {this.state.errors.city}
                                            </span>
                                            ) : (
                                            ''
                                            )}
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <label>
                                        State<span className="asterisk">*</span>
                                        </label>
                                        <div className='form-field-wrp kf-focus'>
                                        <input
                                            type='text'
                                            name='state'
                                            className='txt-cntrl form-field'
                                            placeholder='State'
                                            value={this.state.state}
                                            onChange={e =>
                                                this.setState({
                                                    state: e.target.value
                                                })
                                                }
                                        />
                                        {this.state.errors.state ? (
                                            <span className='errorMsg'>
                                                <i className='alert-icon fa fa-exclamation'></i>
                                                {this.state.errors.state}
                                            </span>
                                            ) : (
                                            ''
                                            )}
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <label>
                                        Zip Code<span className="asterisk">*</span>
                                        </label>
                                        <div className='form-field-wrp kf-focus'>
                                        <input
                                            type='text'
                                            name='zip_code'
                                            className='txt-cntrl form-field'
                                            placeholder='Zip Code'
                                            value={this.state.zip_code}
                                            onChange={e =>
                                                this.setState({
                                                    zip_code: e.target.value
                                                })
                                                }
                                        />
                                        {this.state.errors.zip_code ? (
                                            <span className='errorMsg'>
                                                <i className='alert-icon fa fa-exclamation'></i>
                                                {this.state.errors.zip_code}
                                            </span>
                                            ) : (
                                            ''
                                            )}
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <label>
                                        Image
                                        </label>
                                        <div className='form-field-wrp kf-focus'>
                                        <input
                                            type='file'
                                            name='image'
                                            className='txt-cntrl form-field'
                                            placeholder='Image'
                                            accept='image/*'
                                            // value={this.state.image}
                                            onChange={e =>
                                                this.setState({
                                                    image: e.target.files[0]
                                                })
                                                }
                                        />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <label>
                                        Website URL
                                        </label>
                                        <div className='form-field-wrp kf-focus'>
                                        <input
                                            type='text'
                                            name='website_url'
                                            className='txt-cntrl form-field'
                                            placeholder='Website URL'
                                            value={this.state.website_url}
                                            onChange={e =>
                                                this.setState({
                                                    website_url: e.target.value
                                                })
                                                }
                                        />
                                        {this.state.errors.website_url ? (
                                            <span className='errorMsg'>
                                                <i className='alert-icon fa fa-exclamation'></i>
                                                {this.state.errors.website_url}
                                            </span>
                                            ) : (
                                            ''
                                            )}
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <label>
                                        Instagram URL
                                        </label>
                                        <div className='form-field-wrp kf-focus'>
                                        <input
                                            type='text'
                                            name='instagram_url'
                                            className='txt-cntrl form-field'
                                            placeholder='Instagram URL'
                                            value={this.state.instagram_url}
                                            onChange={e =>
                                                this.setState({
                                                    instagram_url: e.target.value
                                                })
                                                }
                                        />
                                        {this.state.errors.instagram_url ? (
                                            <span className='errorMsg'>
                                                <i className='alert-icon fa fa-exclamation'></i>
                                                {this.state.errors.instagram_url}
                                            </span>
                                            ) : (
                                            ''
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-12 text-right button-row'>
                                        <button
                                            className={this.state.isLoading ? 'save-btn btn is-loading' : 'save-btn btn'}
                                            onClick={this.submitFeedback}
                                        >
                                        <i className="fa fa-spinner loadicon"></i>
                                        Send
                                        </button>
                                        <button
                                            className='cancel-btn btn'
                                            data-dismiss='modal'
                                            aria-label='Close'
                                            onClick={this.resetForm}
                                            >
                                        Cancel
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        )
    }
}

export default withRouter(StoreFeedback)