import React, { Component } from "react";
import $ from 'jquery'
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import axios from 'axios';
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';

class CmsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: ''
        };
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        COMMONCONST.GAPageView();
        this.getData();
    }

    getData(id) {
        axios({
            method: "get",
            url: APICONST.GETCMS(this.props.page),
            headers: { "content-type": "application/json" }
        })
            .then(response => {
                $('.cms-page-title').text(response.data.result.data.page_title);
                $('.cms-cnt-txt').html(response.data.result.data.description);
                if (response.data.result.data.image) {
                    // $('#image').attr('src', response.data.result.data.image);
                    this.setState({
                        image: response.data.result.data.image
                    })
                }
                
            })
            .catch(error => {
            });
    }    

    render() {
        return (
            <div className='App'>
                <div className={this.state.image !== '' ? 'cms-page cms-column-layout about-page' : 'cms-page' }>
                    {/* {this.props.page === 'about' ? */}
                        <div className="cms-img-conatiner">
                            <div className="container">
                            <img src={this.state.image} alt='' id="image" className="d-block w-100" />
                            </div>
                        </div>
                    {/* : ''} */}
                    <div className="container">
                        <div className="cms-cnt-inner">
                            <div className="cms-cnt-wrapper">
                                <div className="cms-row">
                                    <div className="cms-col">
                                        <h4 className="cms-page-title"> </h4>
                                    </div>
                                    <div className="cms-col">
                                        <div className="cms-cnt-txt">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default CmsPage