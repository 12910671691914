import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login'
import AppleLogin from 'react-apple-login'
import axios from 'axios'
import Logo from '../assets/images/new-logo.svg'
import '../assets/css/font-awesome.min.css'
import '../assets/fonts/fonts.css'
import './Custom.css'
import { DOMAIN, APPLE_CLIENT_ID, APPLE_REDIRECT_URL } from '../constants/Api'
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import $ from 'jquery'

class SignUp extends Component {
  constructor() {
    super()
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      isChecked: false,
      news_letter: false,
      error: false,
      errors: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        agree: ''
      },
      isResend: false,
      resendEmail: '',
      success_message: '',
      tagError: false,
      isLoading: false,
      isWatchlist: COMMONCONST.getCookie('watchlist')
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  handleFormSubmit = e => {
    e.preventDefault()

    this.validateFirstName();
    this.validateLastName();
    this.validateEmail();
    this.validatePassword();
    this.validateCheckbox();

    if (
      this.state.first_name !== '' &&
      this.state.last_name !== '' &&
      this.state.email !== '' &&
      this.state.password !== '' &&
      this.state.isChecked === true
    ) {
      this.setState({
        isLoading: true
      });
      axios({
        method: 'post',
        url: APICONST.SIGNUP,
        headers: { 'content-type': 'application/json' },
        data: this.state
      })
        .then(response => {
          if (
            response.data.result.message ===
            'Registered successfully. Please verify your email to active account'
          ) {
            this.setState({ error: false, isLoading: false })
            this.setState({
              resendEmail: this.state.email
            })
            this.setState({
              first_name: '',
              last_name: '',
              email: '',
              password: '',
              isChecked: false
            })

            this.setState({
              isResend: true
            })
          }
          if (response.data.result.error.email !== ' ') {
            this.setState({
              errors: {
                error_message: response.data.result.error.email
              }
            })
          }
        })
        .catch(error => {
          this.setState({ isLoading: false });
          if (
            typeof error.response !== 'undefined' &&
            typeof error.response.data.result.error.email !== 'undefined'
          ) {
            this.setState({
              errors: {
                email: error.response.data.result.error.email
              }
            })
          }

          if (
            typeof error.response !== 'undefined' &&
            typeof error.response.data.result.error.first_name !== 'undefined'
          ) {
            this.setState({
              errors: {
                first_name: error.response.data.result.error.first_name
              }
            })
          }

          if (
            typeof error.response !== 'undefined' &&
            typeof error.response.data.result.error.last_name !== 'undefined'
          ) {
            this.setState({
              errors: {
                last_name: error.response.data.result.error.last_name
              }
            })
          }

          if (
            typeof error.response !== 'undefined' &&
            typeof error.response.data.result.error.password !== 'undefined'
          ) {
            this.setState({
              errors: {
                password: error.response.data.result.error.password
              }
            })
          }
        })
    }

  }

  validateFirstName = () => {
    let first_name = this.state.first_name
    let isError = false;

    const ValidName = RegExp(
      /^[a-zA-Z0-9 ]*$/i
    )

    let tagErrors = this.state.errors

    if ('' === first_name) {
      tagErrors.first_name = 'Please enter your first name'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (first_name && ValidName.test(first_name) === false) {
      tagErrors.first_name = 'First name not allow special characters'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (first_name.length < 2) {
      tagErrors.first_name = 'First name should contains minimum 2 characters'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.first_name = ''
      this.setState({
        tagErrors,
        tagError: false
      })
    }

    if (isError === true) {
      return false;
    }

  }

  validateLastName = () => {
    let last_name = this.state.last_name
    let isError = false;

    const ValidName = RegExp(
      /^[a-zA-Z0-9 ]*$/i
    )

    let tagErrors = this.state.errors

    if ('' === last_name) {
      tagErrors.last_name = 'Please enter your last name'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (last_name && ValidName.test(last_name) === false) {
      tagErrors.last_name = 'Last name not allow special characters'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (last_name.length < 2) {
      tagErrors.last_name = 'Last name should contains minimum 2 characters'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.last_name = ''
      this.setState({
        tagErrors,
        tagError: false
      })
    }

    if (isError === true) {
      return false;
    }

  }

  validateEmail = () => {
    let email = this.state.email
    let isError = false;

    let emailcheck = email.split("@");
    let emailFirst = emailcheck[0];
    let emailSecond = '';

    if (emailcheck[1]) {
      emailSecond = emailcheck[1].split(".")[0];
    }

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    )

    let tagErrors = this.state.errors

    if ('' === email) {
      tagErrors.email = 'Please enter an email address'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (email && validEmailRegex.test(email) === false) {
      tagErrors.email = 'Email is not valid'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (!emailFirst || emailFirst.length <= 1 || !emailSecond || emailSecond.length <= 1) {
      tagErrors.email = 'Email should be minimum 2 characters before and after @ sign'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.email = ''
      this.setState({
        tagErrors,
        tagError: false
      })
    }

    if (isError === true) {
      return false;
    }
  }

  validatePassword = () => {
    let password = this.state.password
    let isError = false;

    const validPasswordRegexNumber = RegExp(
      /\d+/i
    )
    const validPasswordRegexAlphabet = RegExp(
      /[a-zA-Z]/i
    )

    let tagErrors = this.state.errors

    if ('' === password) {
      tagErrors.password = 'Please enter a password'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (password && password.length <= 7) {
      tagErrors.password = 'Password should be alphanumeric and Minimum 8 characters long'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else if (password && (validPasswordRegexNumber.test(password) === false || validPasswordRegexAlphabet.test(password) === false)) {
      tagErrors.password = 'Password should be alphanumeric and Minimum 8 characters long'
      isError = true;
      this.setState({
        tagErrors,
        tagError: true
      })
    } else {
      tagErrors.password = ''
      this.setState({
        tagErrors,
        tagError: false
      })
    }

    if (isError === true) {
      return false;
    }

  }

  validateCheckbox = () => {

    let isError = false;
    let tagErrors = this.state.errors

    if (this.state.isChecked === true) {
      tagErrors.agree = ''
      this.setState({
        tagErrors,
      })
    } else {
      isError = true;
      tagErrors.agree = 'Please agree to the Terms & Conditions'
      this.setState({
        tagErrors,
      })
    }

    if (isError === true) {
      return false;
    }

  }

  ResendEmail = () => {
    if (this.state.resendEmail !== '') {
      axios({
        method: 'post',
        url: APICONST.RESENDVERIFYEMAIL,
        headers: { 'content-type': 'application/json' },
        data: { email: this.state.resendEmail }
      })
        .then(response => {
          if (
            typeof response.data !== 'undefined' &&
            typeof response.data.result.message !== 'undefined'
          ) {
            this.setState({
              success_message: response.data.result.message
            })
          }
        })
        .catch(error => {
          if (
            typeof error.response !== 'undefined' &&
            typeof error.response.data.result.error !== 'undefined'
          ) {
            this.setState({
              error: error.response.data.result.error
            })
          }
        })
    }
  }

  modalClick = () => {
    $('.signup-close').trigger('click');
    this.revertFields();
  }

  revertFields = () => {
    let tagErrors = this.state.errors;
    tagErrors.first_name = '';
    tagErrors.last_name = '';
    tagErrors.email = '';
    tagErrors.password = '';
    tagErrors.agree = '';
    this.setState({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      isChecked: false,
      news_letter: false,
      tagErrors,
      tagError: true
    })
  }

  render() {
    return (
      <Fragment>
        <div
          className='modal signModal fade'
          id='signupModal'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='container'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-body'>
                  <button
                    type='button'
                    className='close signup-close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={this.revertFields}
                  >
                  </button>
                  {/* <div className='img-block'>
                    <img
                      src={require('../assets/images/popup-img.png')}
                      alt='popup-img'
                    />
                  </div> */}
                  <div className='sign-block'>
                    {this.state.isResend === false ? (
                      <>
                        <div className='form-info signup-screen'>
                          <Link to='/' className='navbar-brand'>
                            <img src={Logo} className='logo' alt='logo' />
                          </Link>
                          <h3>
                            Create an Account{' '}
                            <span>
                              or <strong>
                                <Link
                                  className='nav-link'
                                  to='#'
                                  data-toggle='modal'
                                  data-target='#signinModal'
                                  onClick={this.modalClick}
                                >
                                  Log In
                                </Link>
                              </strong>
                            </span>
                          </h3>
                          <p>
                            Make sure to create an account and sign in to save
                            sneakers to your watchlist and have it synced with
                            your app.
                          </p>
                        </div>
                        <form
                          id='signupForm'
                          action='POST'
                          onSubmit={this.handleFormSubmit}
                        >
                          <div className='form-row'>
                            {this.state.errors.first_name ? (
                              <span className='errorMsg'>
                                <i className='alert-icon fa fa-exclamation'></i>
                                {this.state.errors.first_name}
                              </span>
                            ) : (
                              ''
                            )}
                            <div className="form-field-wrp kf-focus kf-clear">
                              <input
                                type='text'
                                name='text'
                                id='first_name'
                                placeholder='First Name'
                                value={this.state.first_name}
                                onChange={e => {
                                  this.setState({
                                    first_name: e.target.value
                                  }, () => this.validateFirstName())
                                }}
                                className={this.state.errors.first_name ? 'error-tag form-field' : 'form-field'}
                              />
                              {this.state.first_name &&
                                <span
                                  className="kf-clear-span"
                                  onClick={e => {
                                    this.setState({ first_name: '' })
                                  }}
                                >
                                </span>
                              }
                            </div>
                          </div>
                          <div className='form-row'>
                            {this.state.errors.last_name ? (
                              <span className='errorMsg'>
                                <i className='alert-icon fa fa-exclamation'></i>
                                {this.state.errors.last_name}
                              </span>
                            ) : (
                              ''
                            )}
                            <div className="form-field-wrp kf-focus kf-clear">
                              <input
                                type='text'
                                name='text'
                                id='last_name'
                                placeholder='Last Name'
                                value={this.state.last_name}
                                onChange={e => {
                                  this.setState({
                                    last_name: e.target.value
                                  }, () => this.validateLastName())
                                }}
                                className={this.state.errors.last_name ? 'error-tag form-field' : 'form-field'}
                              />
                              {this.state.last_name &&
                                <span
                                  className="kf-clear-span"
                                  onClick={e => {
                                    this.setState({ last_name: '' })
                                  }}
                                >
                                </span>
                              }
                            </div>
                          </div>
                          <div className='form-row'>
                            {this.state.errors.email ? (
                              <span className='errorMsg'>
                                <i className='alert-icon fa fa-exclamation'></i>
                                {this.state.errors.email}
                              </span>
                            ) : (
                              ''
                            )}
                            <div className={`form-field-wrp kf-focus kf-clear ${this.state.email.length <= 0 || this.state.errors.email ? 'kf-negative' : 'kf-positive'}`}>
                              <input
                                type='text'
                                name='email'
                                id='email'
                                placeholder='Email'
                                value={this.state.email}
                                onChange={e => {
                                  this.setState({
                                    email: e.target.value
                                  }, () => this.validateEmail())
                                }}
                                className={this.state.errors.email ? 'error-tag form-field' : 'form-field'}
                              />
                              {this.state.email &&
                                <span
                                  className="kf-clear-span"
                                  onClick={e => {
                                    this.setState({ email: '' })
                                  }}
                                >
                                </span>
                              }
                            </div>
                          </div>
                          <div
                            className={
                              this.state.errors.password
                                ? 'form-row error'
                                : 'form-row'
                            }
                          >
                            {this.state.errors.password ? (
                              <span className='errorMsg'>
                                <i className='alert-icon fa fa-exclamation'></i>
                                {this.state.errors.password}
                              </span>
                            ) : (
                              ''
                            )}
                            <div className={`form-field-wrp kf-focus kf-clear kf-toggle-pass ${this.state.password.length <= 0 || this.state.errors.password ? 'kf-negative' : 'kf-positive'}`}>
                              <input
                                type='password'
                                name='password'
                                id='password'
                                placeholder='Password'
                                value={this.state.password}
                                onChange={e => {
                                  this.setState({
                                    password: e.target.value
                                  }, () => this.validatePassword())
                                }}
                                className={`form-field password-field ${this.state.errors.password ? 'error-tag' : ''}`}
                              />
                              <span className="kf-toggle-pass-span"></span>
                              {this.state.password &&
                                <span
                                  className="kf-clear-span"
                                  onClick={e => {
                                    this.setState({ password: '' })
                                  }}
                                >
                                </span>
                              }
                            </div>
                          </div>
                          <div className='form-row checkbox-row'>
                            <input
                              type='checkbox'
                              className={
                                this.state.errors.agree
                                  ? 'form-check-input error-tag'
                                  : 'form-check-input'
                              }
                              id='check_agree'
                              checked={this.state.isChecked}
                              onChange={() => {
                                this.setState({
                                  isChecked: !this.state.isChecked
                                }, () => this.validateCheckbox());
                              }}
                            />
                            <label
                              className={
                                this.state.errors.agree
                                  ? 'form-check-label error-tag'
                                  : 'form-check-label'
                              }
                              htmlFor='check_agree'
                            >
                              I agree to Kicks Finder's{' '}
                              <Link to='/terms-conditions' target="_blank">Terms & Conditions</Link>
                            </label>
                            {this.state.errors.agree ? (
                              <span className='errorMsg'>
                                <i className='alert-icon fa fa-exclamation'></i>
                                {this.state.errors.agree}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className='form-row checkbox-row'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id='news_letter'
                              checked={this.state.news_letter}
                              onChange={() =>
                                this.setState({
                                  news_letter: !this.state.news_letter
                                })
                              }
                            />
                            <label
                              className='form-check-label'
                              htmlFor='news_letter'
                            >
                              Yes, I would like to receive e-mail updates on popular sneaker releases and watchlisted sneakers.
                            </label>
                          </div>
                          <button className={this.state.isLoading ? 'signup-btn is-loading' : 'signup-btn'}>Sign Up
                          <i className="fa fa-spinner loadicon"></i>
                          </button>
                          {this.state.success_message ? (
                            <div className='userMsg userSignUpMsg'>
                              {this.state.success_message}
                            </div>
                          ) : (
                            ''
                          )}
                          {this.state.errors.error_message ? (
                            <span className='errorMsg'>
                              <i className='alert-icon fa fa-exclamation'></i>
                              {this.state.errors.error_message}
                            </span>
                          ) : (
                            ''
                          )}

                          {this.state.error ? (
                            <span className='errorMsg'>
                              <i className='alert-icon fa fa-exclamation'></i>
                              {this.state.error}
                            </span>
                          ) : (
                            ''
                          )}

                          <span className='or'>or</span>
                          <div className="kf-login-buttons">
                            <GoogleLogin
                              className='google-signup'
                              clientId={this.props.gapi}
                              buttonText='Sign in with Google'
                              onSuccess={this.props.responseGoogle}
                              onFailure={this.props.responseGoogle}
                              cookiePolicy={'single_host_origin'}
                              isSignedIn={true}
                              redirectUri={DOMAIN}
                            />
                            <button className="apple-signup">
                              <span>Sign in with Apple</span>
                              <AppleLogin
                                clientId={APPLE_CLIENT_ID}
                                redirectURI={APPLE_REDIRECT_URL}
                                scope={"name email"}
                                responseType={"code"}
                                responseMode={"form_post"}
                                usePopup={false}
                                designProp={
                                  {
                                    height: 37,
                                    width: 140,
                                    color: "white",
                                    border: false,
                                    type: "sign-in",
                                    border_radius: 15,
                                    scale: 1,
                                    locale: "en_US",
                                  }
                                }
                              />
                            </button>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <div className='form-info verify-screen'>
                          <Link to='/' className='navbar-brand'>
                            <img src={Logo} className='logo' alt='logo' />
                          </Link>
                          <h3>Check Your Email! </h3>
                          <p>
                            We sent you a confirmation email. Click through the
                            link in your email to finalize your account.
                          </p>
                          <p>
                            Did receive anything? Make sure to check your junk
                            folder, Or click this{' '}
                            <Link to='#' onClick={this.ResendEmail}>
                              link
                            </Link>{' '}
                            to resend confirmation email.
                          </p>
                          {this.state.success_message ? (
                            <div className='userMsg userSignUpMsg'>
                              <i className="success-icon fa fa-check-circle"></i>
                              {this.state.success_message}{' '}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default SignUp
