import React, { Component } from "react";
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import './store-locator.css';
import * as APICONST from '../constants/Api';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import StoreLocatorInfoBox from './StoreLocatorInfoBox';
import StoreFeedback from './StoreFeedback';
import $ from 'jquery'
import * as COMMONCONST from '../constants/Common';

class StoreLocator extends Component {
	constructor(props) {
        super(props)

        this.state = {
            storeList: [],
            isLoading: false,
            isError: false,
			latitude: '',
      		longitude: '',
			postalCode: '',
			search: '',
			total: 0,
			pages: 0,
			perpage: 12,
			page: 0,
			miles: 10,
			search_result: '',
			isLoadmore: false
        };
		this.getStoreList = this.getStoreList.bind(this);
		this.getUserLocation = this.getUserLocation.bind(this);
		this.getPostalCode = this.getPostalCode.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.updateList = this.updateList.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.loadMore = this.loadMore.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.clearMyLocation = this.clearMyLocation.bind(this);
    }

	// componentDidUpdate(prevProps, prevState) {
	// 	if (prevState.longitude !== this.state.longitude) {
	// 		console.log('postalCode', this.state.postalCode);
	// 	  	this.getPostalCode();
	// 	}
	//   }
	getUserLocation() {
		if (navigator.geolocation) {
			console.log('getUserLocation');
		  this.setState({ isLoading: true });
		  navigator.geolocation.getCurrentPosition(
			(position) => {
			  const { latitude, longitude } = position.coords;
			  this.setState({ latitude, longitude, postalCode: '', search: '' }, () => {
				this.getStoreList();
				this.setState({ isLoading: false });
				console.log('latitude', latitude, 'longitude', longitude);
				});
			},
			(error) => {
			  console.error('Error fetching user location:', error);
			  this.setState({ isLoading: false });
			}
		  );
		} else {
		  console.error('Geolocation is not supported by this browser.');
		}
	  }

	loadMore = () => {
		this.getStoreList(true);
	}

	getStoreList(isLoadMore = false) {
        
        this.setState({
            isLoading: true
        })
		if(isLoadMore) {
			this.setState({ isLoadmore: true });
		}
		let page = this.state.page + 1;
		if(!isLoadMore) {
			page = 1;
		}
		let params = {
			perpage: 12,
			page: page
		};
		if(this.state.search) {
			params.search = this.state.search;
		} else if( this.state.latitude && this.state.longitude ) {
			if( this.state.latitude ) {
				params.latitude = this.state.latitude;
			}
			if( this.state.longitude ) {
				params.longitude = this.state.longitude;
			} else if( this.state.postalCode ) {
				params.search = this.state.postalCode;
			}

			if( this.state.latitude && this.state.longitude ) {
				params.radius = this.state.miles;
			}
		} else {
			params.featured = 1;
		}
        axios({
            method: "get",
            url: APICONST.StoreList,
            headers: { "content-type": "application/json" },
            params: params
        })
            .then(response => {
				let storeList = this.state.storeList;
				if(isLoadMore) {
					storeList.data = storeList.data.concat(response.data.result.data.data);
				} else {
					COMMONCONST.GAPageView();
					storeList = response.data.result.data;
				}
                this.setState({
                    isLoading: false,
                    storeList: storeList,
					total: response.data.result.data.meta.total,
					pages: response.data.result.data.meta.pages,
					page: response.data.result.data.meta.page,
					search_result: this.state.search,
					isLoadmore: false
                }, () => {
					// this.getPostalCode();
				});
				// console.log(response.data.result.data);

            })
            .catch(error => this.setState({ isError: true, isLoading: false, isLoadmore: false }));

    }

	updateList() {
		this.getStoreList();
	}

	getPostalCode() {
		const { latitude, longitude } = this.state;
		if (latitude && longitude) {
		  axios.get(`https://ipapi.co/${latitude},${longitude}/json/`)
			.then(response => {
			  const postalCode = response.data.postal;
			  this.setState({ postalCode });
			})
			.catch(error => {
			  console.error('Error fetching postal code:', error);
			});
		}
	  }

	handleInputChange(event) {
		this.setState({ search: event.target.value });
	}
	handleFormSubmit(event) {
		event.preventDefault();
		this.getStoreList();
	  }
	componentDidMount() {
		this.getStoreList();
		// this.getUserLocation();
	}

	handleSelectChange(event) {
		// console.log(event.target.value);
		this.setState({ miles: event.target.value }, () => { this.getStoreList(); });
	}

	clearMyLocation() {
		console.log('clearMyLocation');
		this.setState({ latitude: '40.729802', longitude: '-73.997970', postalCode: '10012' }, () => {
			this.getStoreList();
		});
	}

	render() {
		let title = 'Kicks Finder - Sneaker Store Locator';
		document.title = title
		$('#og-title').attr('content', title);
		let description = "Kickfinder's Sneaker Store Locator is a comprehensive database of sneaker retailers across the United States. Easily search for stores by name or location and view a list of sneakers available at each store.";
		$('#meta-description').attr('content', description);
		$('#og-description').attr('content', description);
		return (
			<div className='App'>
				<div className="store-locator">
					<div className="container padd-large">
						<div className="store-locator-form">
							<div className="store-locator-form__head">
								<h1 className="main-heading">Store Locator</h1>
								<p className="sub-heading">Find sneaker retailers near you or browse our store directory by state</p>
							</div>	
							<form className="store-locator-form__box" onSubmit={this.handleFormSubmit}>
								<div className="store-locator-form__box-head">
									<h2>SEARCH</h2>
									<p><span className="store-locator-form__user-location" onClick={this.getUserLocation}>use my current location</span></p> 
								</div>
								<div className="store-locator-form__box-search">
									<input type="text" placeholder="Enter store name, city, zip, or state"
										value={this.state.search}
										onChange={this.handleInputChange}
										/>
									<input type="button" value="SEARCH" onClick={this.updateList} />
								</div>
								<div className="store-locator-form__box-btn">
									<h3><Link to="/store-locator/store-directory">BROWSE STORE DIRECTORY</Link></h3>
								</div>
							</form>
						</div>
						<div className={this.state.isLoading && !this.state.isLoadmore ? "store-locator-listing sticky-loader-active" : "store-locator-listing"}>
							{this.state.isLoading && !this.state.isLoadmore ? (<div className="sticky-loader-wrap"><span className="sticky-loader-span"></span></div>) : ''}
							<div className="store-locator-listing__head">
								<p>
									{this.state.total} stores 
									{/* {!this.state.search_result && ( <> near <strong>{this.state.postalCode}{!this.state.postalCode && this.state.latitude && this.state.longitude ? "Current Location" : ''}</strong> within </>)} */}
									{this.state.latitude && this.state.longitude && !this.state.search_result && ( <> near <strong>{this.state.latitude && this.state.longitude ? "Current Location" : ''}</strong> within </>)}
									{this.state.search_result && ( <> for <strong>{this.state.search_result}</strong></>)}
								</p>
								{this.state.latitude && this.state.longitude && !this.state.search_result && (
									<select value={this.state.miles} onChange={this.handleSelectChange}>
										<option value={10}>10 miles</option>
										<option value={20}>20 miles</option>
										<option value={30}>30 miles</option>
									</select>
								)}
							</div>
							<div className="store-locator-listing__row">
								{this.state.storeList && this.state.storeList.data && this.state.storeList.data.map((store, key) =>{
									return <StoreLocatorInfoBox key={key} store={store} />
								})}
							</div>
							{this.state.page < this.state.pages && (<div className="loadmore-btn"><button className={this.state.isLoading ? "load-more is-loading" : "load-more"} onClick={this.loadMore}>Load More <i className="fa fa-spinner loadicon"></i></button></div>)}
							{this.state.storeList && this.state.storeList.data && this.state.storeList.data.length === 0 && <div className="text-center my-3 font-weight-bold">No stores found</div>}
						</div>
					</div>
				</div>
				<StoreFeedback />
			</div>
		)
	}
}

export default StoreLocator