
import React, { Component, Fragment } from "react";
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import he from 'he';
import $ from 'jquery'
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile
// } from "react-device-detect";

class LatestNews extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      totalLeft: 0,
      // click: 0,
      isLoading: false,
      width: window.innerWidth,
      height: window.innerHeight,
      autoplay: false,
      slidesToScroll: 1
      // width: 900,
      // height: 900
    };
    this.getNews = this.getNews.bind(this);
    // this.getUpdateNews = this.getUpdateNews.bind(this);
    this.loadMoreNews = this.loadMoreNews.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  slidePrevious = () => {
    if (this.state.autoplay === false) {
      this.setState({
        autoplay: true,
        slidesToScroll: -1
      }, () => this.slider.slickPlay());
    }
  }
  slideNext = () => {
    if (this.state.autoplay === false) {
      this.setState({
        autoplay: true,
        slidesToScroll: 1
      }, () => this.slider.slickPlay());
    }
  }
  slidePause = () => {
    if (this.state.autoplay === true) {
      this.setState({
        autoplay: false,
        slidesToScroll: 1
      }, () => this.slider.slickPause())
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.getNews();
    COMMONCONST.loadImage();
    this.handleSlides();
  }

  componentDidUpdate() {
    COMMONCONST.loadImage();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight }//, () => {
      // this.setState({ width: 900, height: 900 }, () => {
      // this.getNews();
      //}
    );
  }

  // getUpdateNews() {

  //   this.setState(prevState => ({
  //     click: prevState.click + 1
  //   }), () => {
  //     console.log(this.state.click);
  //     if (this.state.click % 25 === 0) {
  //       console.log(this.state.click % 25 === 0);
  //       this.getNews();
  //     }
  //   })
  // }

  loadMoreNews() {
    this.getNews();
  }

  getNews() {
    let page = this.state.page + 1;
    let per_page;
    let related_data;
    if (typeof (this.props.related_data !== undefined)) {
      related_data = this.props.related_data;
    }

    if (this.state.width >= 768) {
      per_page = 24;
    } else {
      per_page = 8;
    }
    this.setState({
      isLoading: true
    })
    axios({
      method: "get",
      url: APICONST.LATESTNEWS,
      headers: { "content-type": "application/json" },
      params: {
        page: page,
        per_page: per_page,
        related_data: related_data
      }
    })
      .then(response => {
        if ((response.data.data) !== undefined) {
          let data;
          data = this.state.data;
          data = data.concat(response.data.data);

          let meta = response.data.meta;
          let totalLeft = meta.total - (meta.page * meta.per_page);
          totalLeft = (totalLeft < 0) ? 0 : totalLeft;

          this.setState({
            data: data,
            page: page,
            isLoading: false,
            totalLeft: totalLeft
          });
        } else {
          this.setState({
            data: []
          });

        }

      })
      .catch(error => this.setState({ error: error.message, isLoading: false }));
  }

  handleSlides() {
    let count = 0;
    $(document).on("mouseover", ".sneakernews-blk .kf-slider-wrapper .slick-current", function () {
      count = 1;
      // $(".sneakernews-blk .kf-slider-wrapper .previous-btn").trigger("click");
    });

    $(document).on("mouseover", ".sneakernews-blk .kf-slider-wrapper .slick-current + .slick-active", function () {
      if (count === 1) {
        $(".sneakernews-blk .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });

    $(document).on("mouseover", ".sneakernews-blk .kf-slider-wrapper .slick-current + .slick-active + .slick-active", function () {
      if (count === 1) {
        $(".sneakernews-blk .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });

    $(document).on("mouseover", ".sneakernews-blk .kf-slider-wrapper .slick-current + .slick-active + .slick-active + .slick-active", function () {
      count = 1;
      // $(".sneakernews-blk .kf-slider-wrapper .next-btn").trigger("click");
    });

    $(document).on("mouseleave", ".sneakernews-blk .kf-slider-wrapper", function () {
      if (count === 1) {
        count = 0;
        $(".sneakernews-blk .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });
  }

  render() {
    if (this.state.data.length > 0) {
      const slides = this.state.data.map((item, key) =>
        <div key={key} className="latestnews-blk-list product-item">
          <div className="product-img">
            <a href={item.post_link} target="_blank" rel="noopener noreferrer">
              <img src={item.image} className="d-block w-100" alt=".." />
            </a>
          </div>
          <div className="prod-details">
            <h4 className="prod-name">
              <a href={item.post_link} target="_blank" rel="noopener noreferrer">
                {he.decode(item.title)}
              </a>
            </h4>
            <p className="post-detail">{item.time} / <a href={item.author_link} target="_blank" rel="noopener noreferrer"><span className="author-name">{item.author_name}</span></a></p>
          </div>
        </div>
      );
      if (this.state.width >= 768) {
        var settings = {
          nav: true,
          dots: false,
          infinite: this.state.data.length > 4 ? true : false,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 4,
          autoplay: this.state.autoplay,
          autoplaySpeed: 1500,
          arrows: true,
          pauseOnHover: false,
          cssEase: this.state.autoplay ? 'linear' : 'ease-in-out',

          afterChange: (slideCount) => {
            if (slideCount % 20 === 0) {
              this.getNews();
            }
          }
        };
        return (
          <Fragment>
            <div className="latestnews-wrapper">
              <div className="product-list-header">
                <div className="prod-list-title-wrap">
                  <h4 className="large-main-title">{this.props.title}</h4>
                  <span className="sub-title">{this.props.subTitle}</span>
                </div>
                {/* {this.props.related_data ? <span className="news-viewmore-btn"><a target="_blank" rel="noopener noreferrer" href={APICONST.getSNRelatedNews(this.props.related_data)}>View More</a></span> : ''} */}
              </div>
              {/* <CarouselSlider slideItems = {data} buttonSetting = {buttonSetting}  sliderBoxStyle= {sliderBoxStyle} accEle = {accEle} itemsStyle = {itemsStyle}/>  */}
              <div className="kf-slider-wrapper">
                <button className="button-slide next-btn" onClick={this.slideNext}>Next</button>
                <button className="button-slide pause-btn" onClick={this.slidePause}>pause</button>
                <button className="button-slide previous-btn" onClick={this.slidePrevious}>previous</button>
                <Slider {...settings} ref={c => (this.slider = c)} autoplay={this.state.autoplay}>{slides}</Slider>
              </div>
            </div>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <div className="latestnews-wrapper">
              <div className="prod-list-title-wrap">
                <h4 className="large-main-title">{this.props.title}</h4>
                {/* {this.props.related_data ? <span className="news-viewmore-btn"><a target="_blank" rel="noopener noreferrer" href={APICONST.getSNRelatedNews(this.props.related_data)}>View More</a></span> : ''} */}
              </div>
              <div className="latestnews-blk-cnt">
                {slides}
                {this.state.totalLeft !== 0 ?
                  <div className="product-item view-more-btn-item">
                    <button id="loadLatestNews" className={this.state.isLoading ? "fullbtn view-more is-loading" : "fullbtn view-more"} onClick={this.loadMoreNews}>
                      VIEW MORE {/* <span className="count">({this.state.totalLeft})</span> */} <i className="fa fa-spinner loadicon"></i>
                    </button>
                  </div> : ''}
              </div>
            </div>
          </Fragment>
        )
      }
    } else {
      return (
        <Fragment>
          {this.state.isLoading == true ?
            <div className={this.state.isLoading ? "latestnews-wrapper slider-placeholder" : "latestnews-wrapper"}>
              <div className="product-list-header">
                <div className="prod-list-title-wrap">
                  <h4 className="large-main-title"></h4>
                </div>
              </div>
              <div className="product-item-wrap">
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
              </div>
            </div>
            : ''}
        </Fragment>
      )
    }
  }
}

export default LatestNews