
import React, { Component, Fragment } from "react";
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import { Link } from 'react-router-dom';
import $ from 'jquery'

class AvailableNow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      totalLeft: 0,
      // click: 0,
      isLoading: false,
      width: window.innerWidth,
      height: window.innerHeight,
      autoplay: false,
      slidesToScroll: 1
    };
    this.getProducts = this.getProducts.bind(this);
    // this.getUpdateNews = this.getUpdateNews.bind(this);
    this.loadMoreNews = this.loadMoreNews.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.like = this.like.bind(this);
    this.watchlist = this.watchlist.bind(this);
  }
  slidePrevious = () => {
    if (this.state.autoplay === false) {
      this.setState({
        autoplay: true,
        slidesToScroll: -1
      }, () => this.slider.slickPlay());
    }
  }
  slideNext = () => {
    if (this.state.autoplay === false) {
      this.setState({
        autoplay: true,
        slidesToScroll: 1
      }, () => this.slider.slickPlay());
    }
  }
  slidePause = () => {
    if (this.state.autoplay === true) {
      this.setState({
        autoplay: false,
        slidesToScroll: 1
      }, () => this.slider.slickPause())
    }
  }
  componentDidMount() {
    this.updateWindowDimensions();
    this.getProducts();
    window.addEventListener('resize', this.updateWindowDimensions);
    COMMONCONST.loadImage();
    this.handleSlides();
  }
  like(id) {
    axios({
      method: "get",
      url: APICONST.LIKES(id),
      headers: { "content-type": "application/json" }
    })
      .then(response => {
        if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
          let likeCount = parseInt($('#' + id + '-like-hide').text()) + 1;
          let likeCountShow = COMMONCONST.makeFriendly(likeCount);
          $('#' + id + '-like-hide').text(likeCount);
          $('#' + id + '-like').text(likeCountShow);
          $('#' + id + '-like').parent('li').addClass('active');
        } else {
          let likeCount = parseInt($('#' + id + '-like-hide').text()) - 1;
          let likeCountShow = COMMONCONST.makeFriendly(likeCount);
          $('#' + id + '-like-hide').text(likeCount);
          $('#' + id + '-like').text(likeCountShow);
          $('#' + id + '-like').parent('li').removeClass('active');
        }
      })
      .catch(error => {
      });
  }

  watchlist(id) {
    COMMONCONST.setCookie('watchlist', true);
    axios({
      method: "get",
      url: APICONST.WATCHLIST(id),
      headers: { "content-type": "application/json" },
      params: { 'watchlist-cookie': COMMONCONST.getCookie('watchlist') }
    })
      .then(response => {
        if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
          let watchCount = COMMONCONST.makeFriendly(parseInt($('#' + id + '-watchlist-hide').text()) + response.data.result.add);
          $('#' + id + '-watchlist').text(watchCount);
          $('#' + id + '-watchlist').parent('li').addClass('active');
        } else {
          // $('#' + id + '-watchlist').text(parseInt($('#' + id + '-watchlist').text()) - 1);
          $('#' + id + '-watchlist').parent('li').removeClass('active');
        }
      })
      .catch(error => {
      });
  }
  componentDidUpdate() {
    COMMONCONST.loadImage();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight }, () => {
      // this.getProducts();
    });
  }

  // getUpdateNews() {
  //   this.setState(prevState => ({
  //     click: prevState.click + 1
  //   }), () => {
  //     if (this.state.click % 25 === 0) {
  //       this.getProducts();
  //     }
  //   })
  // }

  loadMoreNews() {
    this.getProducts();
  }

  getProducts() {
    let page = this.state.page + 1;
    let per_page;
    let related_data;
    if (typeof (this.props.related_data !== undefined)) {
      related_data = this.props.related_data;
    }

    if (this.state.width >= 768) {
      per_page = 24;
    } else {
      per_page = 8;
    }

    this.setState({
      isLoading: true
    })
    axios({
      method: "get",
      url: APICONST.AVAILABLE_NOW,
      headers: { "content-type": "application/json" },
      params: {
        page: page,
        perpage: per_page,
        cookie: COMMONCONST.getCookie('watchlist')
      }
    })
      .then(response => {
        if ((response.data.result.data.data) !== undefined) {
          let data;
          data = this.state.data;
          data = data.concat(response.data.result.data.data);

          let meta = response.data.result.data.meta;
          let totalLeft = meta.total - (meta.page * meta.perpage);
          totalLeft = (totalLeft < 0) ? 0 : totalLeft;

          this.setState({
            data: data,
            page: page,
            isLoading: false,
            totalLeft: totalLeft
          });
        } else {
          this.setState({
            data: []
          });

        }

      })
      .catch(error => this.setState({ error: error.message, isLoading: false }));
  }

  handleSlides() {
    let count = 0;
    $(document).on("mouseover", ".availablenow-blk .kf-slider-wrapper .slick-current", function () {
      count = 1;
      // $(".availablenow-blk .kf-slider-wrapper .previous-btn").trigger("click");
    });

    $(document).on("mouseover", ".availablenow-blk .kf-slider-wrapper .slick-current + .slick-active", function () {
      if (count === 1) {
        $(".availablenow-blk .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });

    $(document).on("mouseover", ".availablenow-blk .kf-slider-wrapper .slick-current + .slick-active + .slick-active", function () {
      if (count === 1) {
        $(".availablenow-blk .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });

    $(document).on("mouseover", ".availablenow-blk .kf-slider-wrapper .slick-current + .slick-active + .slick-active + .slick-active", function () {
      count = 1;
      // $(".availablenow-blk .kf-slider-wrapper .next-btn").trigger("click");
    });

    $(document).on("mouseleave", ".availablenow-blk .kf-slider-wrapper", function () {
      if (count === 1) {
        count = 0;
        $(".availablenow-blk .kf-slider-wrapper .pause-btn").trigger("click");
      }
    });
  }

  render() {

    if (this.state.data.length > 0) {
      const slides = this.state.data.map((item, key) =>
        <div key={key} className="product-item">
          <Link target="_blank" to={'/' + item.slug} className="product-img"><img src={item.product_thumbnail} alt="img" /></Link>
          <div className="prod-details">
            <h4 className="prod-name"><Link target="_blank" to={'/' + item.slug}>{item.full_name}</Link> <span className="prod-color">{item.product_nickname ? '"' + item.product_nickname + '"' : ''}</span> </h4>
            <h2 className="prod-price">{item.price}</h2>
          </div>
        </div>
      );
      if (this.state.width >= 768) {
        var settings = {
          nav: true,
          dots: false,
          infinite: this.state.data.length > 4 ? true : false,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 4,
          autoplay: this.state.autoplay,
          autoplaySpeed: 1500,
          arrows: true,
          pauseOnHover: false,
          cssEase: this.state.autoplay ? 'linear' : 'ease-in-out',
          afterChange: (slideCount) => {
            if (slideCount % 20 === 0) {
              this.getProducts();
            }
          }
        };
        return (
          <Fragment>
            <div className="latestnews-wrapper">
              <div className="product-list-header">
                <div className="prod-list-title-wrap">
                  <h4 className="large-main-title">{this.props.title}</h4>
                  {this.props.subTitle && <span className="sub-title">{this.props.subTitle}</span>}
                </div>
              </div>
              {/* <CarouselSlider slideItems = {data} buttonSetting = {buttonSetting}  sliderBoxStyle= {sliderBoxStyle} accEle = {accEle} itemsStyle = {itemsStyle}/>  */}
              <div className="kf-slider-wrapper">
                <button className="button-slide next-btn" onClick={this.slideNext}>Next</button>
                <button className="button-slide pause-btn" onClick={this.slidePause}>pause</button>
                <button className="button-slide previous-btn" onClick={this.slidePrevious}>previous</button>
                <Slider {...settings} ref={c => (this.slider = c)} autoplay={this.state.autoplay}>{slides}</Slider>
              </div>
            </div>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <div className="latestnews-wrapper">
              <div className="prod-list-title-wrap">
                <h4 className="large-main-title">{this.props.title}</h4>
              </div>
              <div className="latestnews-blk-cnt">
                {slides}
                {this.state.totalLeft !== 0 ?
                  <div className="product-item view-more-btn-item">
                    <button id="loadAvailableNow" className={this.state.isLoading ? "fullbtn view-more is-loading" : "fullbtn view-more"} onClick={this.loadMoreNews}>
                      VIEW MORE {/* <span className="count">({this.state.totalLeft})</span> */} <i className="fa fa-spinner loadicon"></i>
                    </button>
                  </div> : ''}
              </div>
            </div>
          </Fragment>
        )
      }
    } else {
      return (
        <Fragment>
          {this.state.isLoading == true ?
            <div className={this.state.isLoading ? "latestnews-wrapper slider-placeholder" : "latestnews-wrapper"}>
              <div className="product-list-header">
                <div className="prod-list-title-wrap">
                  <h4 className="large-main-title"></h4>
                </div>
              </div>
              <div className="product-item-wrap">
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
                <div className="product-item">
                  <div className="product-img"></div>
                  <div className="prod-details"></div>
                </div>
              </div>
            </div>
            : ''}
        </Fragment>
      )
    }
  }
}

export default AvailableNow