export const DOMAIN = process.env.REACT_APP_API_DOMAIN;

export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;

export const APPLE_REDIRECT_URL = process.env.REACT_APP_APPLE_REDIRECT_URL;

export const SLIDER = DOMAIN + '/api/slider';

export const PRODUCTLISTING = DOMAIN + '/api/products';

export const FORGOTPASSWORD = DOMAIN + '/api/forgot-password';

export const VERIFYEMAIL = DOMAIN + '/api/verify-email/';

export const VERIFYUPDATEDEMAIL = DOMAIN + '/api/verify-updated-email/';

export const RESETPASSWORD = DOMAIN + '/api/reset-password';

export const LOGIN = DOMAIN + '/api/login';

export const LOGOUT = DOMAIN + '/api/logout';

export const SIGNUP = DOMAIN + '/api/register';

export const RESENDVERIFYEMAIL = DOMAIN + '/api/resend-verify-email';

export const TIMEZONELIST = DOMAIN + '/api/time-zone';

export const GETPROFILEDETAILS = DOMAIN + '/api/profile';

export const DELETEACCOUNT = DOMAIN + '/api/account-delete';

export const UPDATEPROFILEDETAILS = DOMAIN + '/api/update-profile';

export const GETHEADERMENU = DOMAIN + '/api/header-menu';

export const RESENDUPDATEDEMAIL = DOMAIN + '/api/resend-update-email';

export const ISWATCHLISTRELEASESOON = DOMAIN + '/api/is-watch-list-release-soon';

export const CONTACT_US = DOMAIN + '/api/contact-us';

export const RECENTLY_ADDED = DOMAIN + '/api/recently-added';

export const AVAILABLE_NOW = DOMAIN + '/api/available-products';

export const POPULAR_LIST = DOMAIN + '/api/popular-products';

export const BRANDLISTING = DOMAIN + '/api/brand-list';

export const MODELLISTING = DOMAIN + '/api/model-list';

export const COUNTRYLIST = DOMAIN + '/api/countries';

export const TwitterList = DOMAIN + '/api/twite-list';

export const StoreList = DOMAIN + '/api/store-locations';

export const StoreDirectory = DOMAIN + '/api/store-locations-state-wise';

export const StoreFeedback = DOMAIN + '/api/store-location-feedback';

export const StoreDetail = (productId) => {
    return DOMAIN + '/api/store-locations/' + productId;
}

export const LIKES = (productId) => {
    return DOMAIN + '/api/product/' + productId + '/like';
}

export const KFRetailersCount = (id) => {
    return DOMAIN + '/api/update-kf-click-count/' + id;
}

export const WATCHLIST = (productId) => {
    return DOMAIN + '/api/product/' + productId + '/watchlist';
}

export const GETCMS = (slug) => {
    return DOMAIN + '/api/cms/' + slug;
}

export const PRODUCTDETAILS = (id) => {
    return DOMAIN + '/api/product/' + id;
}
export const IndividualModal = (slug) => {
    return DOMAIN + '/api/model/' + slug;
}

// Wordpress Constant

export const WP_DOMAIN = process.env.REACT_APP_WP_DOMAIN;

export const LATESTNEWS = WP_DOMAIN + '/wp-json/sn_api/request/news';

export const getSNRelatedNews = (releated) => {
    let q = releated.split(",").join(" ");
    return WP_DOMAIN + '?s=' + q;
}

